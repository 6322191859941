/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { pt as localePT } from 'date-fns/locale';
import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';
import { RouteWeek as RouteWeekType } from '../../../types/RouteWeek';

import { Container, TitleWeek, WeekButton, RightSideWeek } from './styles';

import week_create from '../../../assets/week_create.svg';
import week_edit from '../../../assets/week_edit.svg';
import week_history from '../../../assets/week_history.svg';
import week_settings from '../../../assets/week_settings.svg';
import week_finished from '../../../assets/week_finished.svg';
import excel from '../../../assets/excel.svg';
import print from '../../../assets/print.svg';
import route_ticket from '../../../assets/route_ticket.svg';
import pt from '../../../assets/pt.svg';
import uk from '../../../assets/uk.svg';
import RouteWeekSettingModal from './RouteWeekSettings';
import CreateRouteWeek from './CreateRouteWeek';
import EditRouteWeek from './EditRouteWeek';
import FinishRouteWeek from './FinishRouteWeek';
import HistoryRouteWeek from './HistoryRouteWeek';

const RouteWeek: React.FC = () => {
  const [routeWeekNotFinished, setRouteWeekNotFinished] = useState(
    {} as RouteWeekType,
  );

  const [visibleRouteWeekSettingsModal, setVisibleRouteWeekSettingsModal] =
    useState(false);

  const [visibleCreateRouteWeekModal, setVisibleCreateRouteWeekModal] =
    useState(false);

  const [visibleEditRouteWeekModal, setVisibleEditRouteWeekModal] =
    useState(false);

  const [visibleFinishRouteWeekModal, setVisibleFinishRouteWeekModal] =
    useState(false);

  const [visibleHistoryRouteWeekModal, setVisibleHistoryRouteWeekModal] =
    useState(false);

  const { addToast } = useToast();
  const history = useHistory();

  const handlePrintRoutesExcel = async () => {
    try {
      const response = await api.get(`routes-excel-data/`);

      const buf = Buffer.from(response.data.dataExcel.data);

      const url = window.URL.createObjectURL(new Blob([buf]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'maparotas.xlsx';
      a.click();
    } catch {
      addToast({
        type: 'error',
        title: 'Erro a obter rotas',
        description: { code: 805 },
      });
    }
  };

  const handlePrintRoutes = async () => {
    try {
      const routeIds = routeWeekNotFinished.routesIds.split(',');

      const routesDataToPrint = await Promise.all(
        routeIds.map(async (id) => {
          const response = await api.get(`routes/${id}`);
          return response.data;
        }),
      );

      history.push({
        pathname: '/pdf-rotas',
        state: {
          routes: routesDataToPrint,
          week: routeWeekNotFinished.week,
          date: format(new Date(routeWeekNotFinished.date), `dd'-'MM'-'yyyy'`, {
            locale: localePT,
          }),
        },
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Erro a obter rotas',
        description: { code: 805 },
      });
    }
  };

  const handlePrintRoutesTag = async () => {
    try {
      const routeIds = routeWeekNotFinished.routesIds.split(',');

      const routesDataToPrint = await Promise.all(
        routeIds.map(async (id) => {
          const response = await api.get(`routes/${id}`);
          return response.data;
        }),
      );

      history.push({
        pathname: '/pdf-rotas-etiquetas',
        state: {
          routes: routesDataToPrint,
        },
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Erro a obter rotas',
        description: { code: 805 },
      });
    }
  };

  const getRouteWeekNotFinished = useCallback(async () => {
    try {
      const response = await api.get('/route-week-not-finished');

      setRouteWeekNotFinished(response.data);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter rota',
        description: { code: 804 },
      });
    }
  }, [addToast]);

  useEffect(() => {
    getRouteWeekNotFinished();
  }, [getRouteWeekNotFinished]);

  return (
    <Container>
      <TitleWeek>
        {routeWeekNotFinished.type ? (
          <>
            <h1>
              Semana: {routeWeekNotFinished.week}
              {routeWeekNotFinished.type === 'pt' ? (
                <img src={pt} alt="Bandeira de Portugal" />
              ) : (
                <img src={uk} alt="Bandeira da Ucrania" />
              )}
            </h1>
            <div>
              <WeekButton
                title="Editar Semana"
                onClick={() => setVisibleEditRouteWeekModal(true)}
              >
                <img src={week_edit} alt="Calendario com um mais" />
              </WeekButton>
              <WeekButton
                title="Terminar Semana"
                onClick={() => setVisibleFinishRouteWeekModal(true)}
              >
                <img src={week_finished} alt="Calendario com certo" />
              </WeekButton>
              <WeekButton
                title="Imprimir mapa de rotas"
                onClick={handlePrintRoutes}
              >
                <img src={print} alt="Impressora" />
              </WeekButton>
              <WeekButton
                title="Imprimir etiquetas"
                onClick={handlePrintRoutesTag}
              >
                <img src={route_ticket} alt="bilhete com picotado" />
              </WeekButton>
              <WeekButton
                title="Excel mapa de rotas"
                onClick={handlePrintRoutesExcel}
              >
                <img src={excel} alt="Excel" />
              </WeekButton>
            </div>
          </>
        ) : (
          <>
            <h1>Semana: </h1>
            <WeekButton
              title="Criar Semana"
              onClick={() => setVisibleCreateRouteWeekModal(true)}
            >
              <img src={week_create} alt="Calendario" />
            </WeekButton>
          </>
        )}
      </TitleWeek>
      <RightSideWeek>
        <WeekButton
          title="Histórico de Rotas"
          onClick={() => setVisibleHistoryRouteWeekModal(true)}
        >
          <img src={week_history} alt="Calendario com data" />
        </WeekButton>
        <WeekButton
          title="Configurações da semana de rotas"
          onClick={() => setVisibleRouteWeekSettingsModal(true)}
        >
          <img src={week_settings} alt="Roda dentada" />
        </WeekButton>
      </RightSideWeek>

      {visibleRouteWeekSettingsModal && (
        <RouteWeekSettingModal
          visible={visibleRouteWeekSettingsModal}
          closeModal={() => setVisibleRouteWeekSettingsModal(false)}
        />
      )}
      {visibleCreateRouteWeekModal && (
        <CreateRouteWeek
          visible={visibleCreateRouteWeekModal}
          closeModal={() => setVisibleCreateRouteWeekModal(false)}
          setRouteWeekNotFinished={setRouteWeekNotFinished}
        />
      )}
      {visibleEditRouteWeekModal && (
        <EditRouteWeek
          visible={visibleEditRouteWeekModal}
          closeModal={() => setVisibleEditRouteWeekModal(false)}
          setRouteWeekNotFinished={setRouteWeekNotFinished}
          routeWeek={routeWeekNotFinished}
        />
      )}

      {visibleFinishRouteWeekModal && (
        <FinishRouteWeek
          visible={visibleFinishRouteWeekModal}
          closeModal={() => setVisibleFinishRouteWeekModal(false)}
          setRouteWeekNotFinished={setRouteWeekNotFinished}
          routeWeek={routeWeekNotFinished}
        />
      )}

      {visibleHistoryRouteWeekModal && (
        <HistoryRouteWeek
          visible={visibleHistoryRouteWeekModal}
          closeModal={() => setVisibleHistoryRouteWeekModal(false)}
        />
      )}
    </Container>
  );
};

export default RouteWeek;
