import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './hooks/AuthContext';
import { ToastProvider } from './hooks/ToastContext';
// import { PostProvider } from './hooks/PostContext';
import Routes from './routes';
import GlobalStyles from './styles/global';

const App: React.FC = () => {
  return (
    <Router>
      <ToastProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ToastProvider>
      <GlobalStyles />
    </Router>
  );
};

export default App;
