/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../config/api';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import Th from '../../components/Table/Th';
import Td from '../../components/Table/Td';
import Input from '../../components/Input';
import CheckBox from '../../components/CheckBox';

import {
  Container,
  Widget,
  Top,
  Title,
  Search,
  Loading,
  PageBox,
  ModalBox,
  Button,
  Close,
} from './styles';

import add from '../../assets/add.svg';
import search from '../../assets/search.svg';
import edit from '../../assets/edit.svg';
import loading from '../../assets/loading01.svg';
import page_left from '../../assets/page_left.svg';
import page_right from '../../assets/page_right.svg';
import save from '../../assets/save.svg';

import { Account } from '../../types/account';

const schemaAdd = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  username: yup.string().required('* o utilizador é obrigatório'),
  email: yup
    .string()
    .email('* formato de e-mail errado')
    .required('* o e-mail é obrigatório'),
  password: yup.string().required('* a palavra-passe é obrigatória'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'As palavras-passe não são iguais')
    .required('* a confirmação da palavra-passe é obrigatória'),
});

const schemaEdit = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  username: yup.string().required('* o utilizador é obrigatório'),
  email: yup
    .string()
    .email('* formato de e-mail errado')
    .required('* o e-mail é obrigatório'),
  password: yup.string(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'As palavras-passe não são iguais'),
});

let debounce: NodeJS.Timeout;

const Accounts: React.FC = () => {
  const ROWLIMIT = 10;
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsList, setAccountsList] = useState<Account[]>([]);
  const [displayAccounts, setDisplayAccounts] = useState<Account[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [idAccountToEdit, setIdAccountToEdit] = useState('');
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);

  const { addToast } = useToast();
  const { account } = useAuth();
  const history = useHistory();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<
    Omit<Account, 'id' | 'createdAt' | 'updatedAt' | 'deleted'> & {
      passwordConfirm: string;
    }
  >({
    resolver: yupResolver(editForm ? schemaEdit : schemaAdd),
  });

  const accountsToDisplay = useCallback((pageData, array) => {
    const arrayTemp = [];

    for (
      let index = ROWLIMIT * (pageData - 1);
      index <= pageData * ROWLIMIT - 1;
      index++
    ) {
      if (array[index]) {
        arrayTemp.push(array[index]);
      }
    }

    setDisplayAccounts(arrayTemp);
  }, []);

  const handleSeach = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(debounce);
    e.preventDefault();

    const searchText = e.currentTarget.value.toLowerCase();
    searchText
      ? (debounce = setTimeout(() => {
          const arrayTemp = accounts.filter(
            (accountFilterData) =>
              accountFilterData.name.toLowerCase().includes(searchText) ||
              accountFilterData.username.toLowerCase().includes(searchText) ||
              accountFilterData.email.toLowerCase().includes(searchText),
          );
          setAccountsList(arrayTemp);
          accountsToDisplay(1, arrayTemp);
          setPages(Math.ceil(arrayTemp.length / ROWLIMIT));
        }, 500))
      : (debounce = setTimeout(() => {
          setAccountsList(accounts);
          accountsToDisplay(1, accounts);
          setPages(Math.ceil(accounts.length / ROWLIMIT));
        }, 500));
  };

  const handlePage = (data: number) => {
    if (page + data > 0 && page + data <= pages) {
      setPage((prevState) => prevState + data);
      accountsToDisplay(page + data, accountsList);
    }
  };

  const getAccounts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('account');

      setAccounts(response.data);
      setAccountsList(response.data);
      setPages(Math.ceil(response.data.length / ROWLIMIT));
      accountsToDisplay(page, response.data);
      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter contas',
        description: { code: 108 },
      });
    }
  }, [addToast, accountsToDisplay, page]);

  const closeModal = () => {
    setDisplayModal(false);
    setEditForm(false);
    reset({
      name: '',
      username: '',
      email: '',
      admin: false,
      password: '',
      passwordConfirm: '',
    });
  };

  const handleEdit = (accountData: Account) => {
    setIdAccountToEdit(accountData.id);
    setValue('name', accountData.name);
    setValue('username', accountData.username);
    setValue('email', accountData.email);
    setValue('admin', accountData.admin);
    setEditForm(true);
    setDisplayModal(true);
  };

  const handleOnSave = async (
    data: Omit<Account, 'id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ) => {
    try {
      if (editForm) {
        const response = await api.put(`account/${idAccountToEdit}`, data);
        if (response.data) {
          setAccountsList((prevState) => {
            const newArray = prevState.map((a) => {
              return a.id === response.data.id ? response.data : a;
            });
            accountsToDisplay(page, newArray);
            return newArray;
          });
          addToast({
            type: 'success',
            title: 'Atualização de conta',
            description: { code: 107 },
          });
        }
      } else {
        const response = await api.post('account', data);
        if (response.data) {
          setAccountsList((prevState) => {
            accountsToDisplay(page, [...prevState, response.data]);
            return [...prevState, response.data];
          });
          addToast({
            type: 'success',
            title: 'Criação de conta',
            description: { code: 106 },
          });
        }
      }
      closeModal();
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a criação do agregado',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  useEffect(() => {
    if (account && !account.admin) {
      history.push('utentes');
    } else {
      getAccounts();
    }
  }, [account, history, getAccounts]);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Contas de utilizador</h1>
              <button
                type="button"
                title="Adicionar Agregado"
                onClick={() => setDisplayModal(true)}
              >
                <img src={add} alt="plus" />
              </button>
            </Title>
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={handleSeach}
              />
              <div title="Pesquisar">
                <img src={search} alt="search" />
              </div>
            </Search>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="left" width="25%">
                      Nome
                    </Th>
                    <Th talign="left" width="20%">
                      Utilizador
                    </Th>
                    <Th talign="left" width="25%">
                      E-mail
                    </Th>
                    <Th talign="center" width="10%">
                      Admin
                    </Th>
                    <Th talign="center" width="15%" />
                  </tr>
                </thead>
                <tbody>
                  {displayAccounts.map((accountData, index) => (
                    <tr key={accountData.id}>
                      <Td talign="center" width="5%">
                        {index + 1}
                      </Td>
                      <Td talign="left" width="25%">
                        {accountData.name}
                      </Td>
                      <Td talign="left" width="20%">
                        {accountData.username}
                      </Td>
                      <Td talign="left" width="25%">
                        {accountData.email}
                      </Td>
                      <Td talign="center" width="10%">
                        {accountData.admin && 'X'}
                      </Td>
                      <Td talign="center" width="15%">
                        <div>
                          <button
                            type="button"
                            title="editar"
                            onClick={() => handleEdit(accountData)}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PageBox>
                <button
                  type="button"
                  title="anterior"
                  onClick={() => handlePage(-1)}
                >
                  <img src={page_left} alt="seta para a esquerda" />
                </button>
                <p>
                  {page} - {pages}
                </p>
                <button
                  type="button"
                  title="próximo"
                  onClick={() => handlePage(1)}
                >
                  <img src={page_right} alt="seta para a direita" />
                </button>
              </PageBox>

              <Modal visible={displayModal}>
                <ModalBox>
                  <h1>{editForm ? 'Editar Conta' : 'Nova Conta'}</h1>
                  <Close>
                    <button type="button" onClick={closeModal}>
                      &#x2715;
                    </button>
                  </Close>
                  <form onSubmit={handleSubmit(handleOnSave)}>
                    <Input
                      width="100%"
                      title="Nome"
                      type="text"
                      {...register('name')}
                      error={errors.name?.message}
                    />
                    <Input
                      width="100%"
                      title="Utilizador"
                      type="text"
                      {...register('username')}
                      error={errors.username?.message}
                    />
                    <Input
                      width="100%"
                      title="E-mail"
                      type="text"
                      {...register('email')}
                      error={errors.email?.message}
                    />
                    <CheckBox
                      width="100%"
                      title="Administrador"
                      type="checkbox"
                      {...register('admin')}
                      error={errors.admin?.message}
                    />
                    <hr />

                    <Input
                      width="100%"
                      title="Palavra-passe"
                      type="password"
                      {...register('password')}
                      error={errors.password?.message}
                    />
                    <Input
                      width="100%"
                      title="Confirmar Palavra-passe"
                      type="password"
                      {...register('passwordConfirm')}
                      error={errors.passwordConfirm?.message}
                    />

                    <Button type="submit">
                      <img src={save} alt="Icon de desquete save" />
                      Guardar
                    </Button>
                  </form>
                </ModalBox>
              </Modal>
            </>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default Accounts;
