import React, { forwardRef } from 'react';

import { Container } from './styles';

interface InputProps extends React.HTMLProps<HTMLTextAreaElement> {
  title: string;
  width: string;
  error: string | undefined;
  backgoundColorStatus?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, InputProps>(
  ({ width, title, error, backgoundColorStatus, ...rest }, ref) => {
    return (
      <Container
        width={width}
        isError={!!error}
        backgoundColorStatus={backgoundColorStatus || false}
      >
        <span>{title}</span>
        <textarea ref={ref} {...rest} />
        <p>{error}</p>
      </Container>
    );
  },
);

export default TextArea;
