/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from '../../config/api';

import photo from '../../assets/photo.svg';

type AvatarProps = {
  path: string;
};

const UserAvatar: React.FC<AvatarProps> = ({ path }) => {
  const [photoUser, setPhotoUser] = useState<string | undefined>();

  const getAvatar = async () => {
    try {
      const response = await api.get(path, {
        responseType: 'blob',
      });

      setPhotoUser(URL.createObjectURL(response.data));
    } catch (e) {
      setPhotoUser(undefined);
    }
  };

  useEffect(() => {
    if (path) {
      getAvatar();
    }
  }, []);

  return <img src={photoUser || photo} alt="Imagem de membro" />;
};

export default UserAvatar;
