import styled from 'styled-components';

type LineProps = {
  available: boolean;
};

export const Line = styled.tr<LineProps>`
  ${(props) => !props.available && 'background-color: #ffdfdf;'}
`;

export const ProductImg = styled.img`
  width: 30px;
  height: 30px;
`;

export const ProductData = styled.p`
  width: 100%;
  display: flex;
  //justify-content: center;
  align-items: center;
`;

export const ProductIndex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    animation: alert 2s linear infinite;
  }

  @keyframes alert {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
