import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div`
  margin-top: 50px;
  position: relative;
  background: #fff;
  width: 1000px;
  max-width: 1000px;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem;

  h1 {
    width: 100%;
    text-align: left;
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
`;

export const Widget = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const Icon = styled.div`
  position: relative;
  width: 200px;
  height: 200px;

  > img {
    width: 200px;
    height: 200px;
    border: 2px solid #007970;
  }

  label {
    position: absolute;
    right: 0;
    bottom: 0;

    input {
      display: none;
    }

    div {
      background: #007970;
      width: 6rem;
      height: 6rem;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      img {
        width: 4rem;
        height: 4rem;
      }
    }

    &:hover {
      cursor: pointer;
      div {
        background: #00665f;
      }
    }
  }

  p {
    color: #ff5050;
    font-size: 12px;
    z-index: 5;
    position: absolute;
  }
`;

export const Form = styled.form`
  flex: 1;
  padding-left: 2rem;
`;

export const FormLine = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & + & {
    margin-top: 3rem;
  }
`;

export const FormLineBottom = styled.div`
  width: calc(100% + 220px);
  margin-left: -220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  margin-top: 3rem;
`;

export const ButtonBox = styled.div`
  margin-top: 4rem;
  width: 140rem;
  max-width: 140rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 20rem;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;
