/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import loading from '../../assets/loading01.svg';

import { Container, Widget, Loading, TableOrder, Title, Top } from './styles';
import Th from '../../components/Table/Th';
import CreateDefaultOrder from '../../components/DefaultOrder/Create';
import { DefaultOrder } from '../../types/DefaultOrder';
import DefaultOrdertLine from '../../components/DefaultOrder/DefaultOrderLine';
import EditDefaultOrder from '../../components/DefaultOrder/Edit';
import CreateDefaultOrderHousehold from '../../components/DefaultOrder/CreateHousehold';
import GenerateDefaultOrder from '../../components/DefaultOrder/Generate';
import GenerateOneDefaultOrder from '../../components/DefaultOrder/GenerateOne';

const DefaultOrderTable: React.FC = () => {
  const [defaultOrders, setDefaultOrders] = useState<DefaultOrder[]>([]);
  const [selectDefaultOrder, setSelectDefaulOrder] =
    useState<DefaultOrder | null>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createHouseholderModalVisible, setCreateHouseholderModalVisible] =
    useState(false);
  const [generateModalVisible, setGenerateModalVisible] = useState(false);
  const [generateOneModalVisible, setGenerateOneModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const getDefaultOrders = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/market/default-order`);

      setDefaultOrders(response.data);

      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter encomendas tipo',
        description: { code: 751 },
      });
    }
  }, [addToast]);

  useEffect(() => {
    getDefaultOrders();
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Encomendas Tipo</h1>
              <CreateDefaultOrder
                handleAddDefaultOrder={(data: DefaultOrder) =>
                  setDefaultOrders((prevState) => [...prevState, data])
                }
              />
            </Title>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <TableOrder>
              <table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="left" width="33%">
                      Nome
                    </Th>
                    <Th talign="center" width="13%">
                      Nº de produtos
                    </Th>
                    <Th talign="center" width="13%">
                      Nº de agregados
                    </Th>
                    <Th talign="center" width="13%">
                      Valor
                      <p>
                        <small>(Souma Créditos)</small>
                      </p>
                    </Th>
                    <Th talign="center" width="10%">
                      Data
                    </Th>
                    <Th talign="center" width="13%" />
                  </tr>
                </thead>
                <tbody>
                  {defaultOrders.length > 0 &&
                    defaultOrders.map((defaultOrder, index) => (
                      <DefaultOrdertLine
                        key={defaultOrder.id}
                        defaultOrder={defaultOrder}
                        index={defaultOrders.length - index}
                        editAction={() => {
                          setEditModalVisible(true);
                          setSelectDefaulOrder(defaultOrder);
                        }}
                        householderAction={() => {
                          setCreateHouseholderModalVisible(true);
                          setSelectDefaulOrder(defaultOrder);
                        }}
                        generateAction={() => {
                          setGenerateModalVisible(true);
                          setSelectDefaulOrder(defaultOrder);
                        }}
                        generateOneAction={() => {
                          setGenerateOneModalVisible(true);
                          setSelectDefaulOrder(defaultOrder);
                        }}
                      />
                    ))}
                </tbody>
              </table>
            </TableOrder>
          )}
        </Widget>

        {selectDefaultOrder && editModalVisible && (
          <EditDefaultOrder
            isOpen={editModalVisible}
            onClose={() => {
              setEditModalVisible(false);
              setSelectDefaulOrder(null);
            }}
            defaultOrder={selectDefaultOrder}
            handleEditOrder={(data: DefaultOrder) =>
              setDefaultOrders((prevState) =>
                prevState.map((pv) => (pv.id === data.id ? data : pv)),
              )
            }
          />
        )}

        {selectDefaultOrder && createHouseholderModalVisible && (
          <CreateDefaultOrderHousehold
            isOpen={createHouseholderModalVisible}
            onClose={() => {
              setCreateHouseholderModalVisible(false);
              setSelectDefaulOrder(null);
            }}
            defaultOrder={selectDefaultOrder}
            handleEditOrder={(data: DefaultOrder) =>
              setDefaultOrders((prevState) =>
                prevState.map((pv) => (pv.id === data.id ? data : pv)),
              )
            }
          />
        )}

        {selectDefaultOrder && generateModalVisible && (
          <GenerateDefaultOrder
            isOpen={generateModalVisible}
            onClose={() => {
              setGenerateModalVisible(false);
              setSelectDefaulOrder(null);
            }}
            defaultOrder={selectDefaultOrder}
          />
        )}

        {selectDefaultOrder && generateOneModalVisible && (
          <GenerateOneDefaultOrder
            isOpen={generateOneModalVisible}
            onClose={() => {
              setGenerateOneModalVisible(false);
              setSelectDefaulOrder(null);
            }}
            defaultOrder={selectDefaultOrder}
          />
        )}
      </Container>
    </>
  );
};

export default DefaultOrderTable;
