/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { Container } from './styles';

import edit from '../../../assets/edit.svg';
import save from '../../../assets/save_dark.svg';

import { MarketSession } from '../../../types/Household';
import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

type CoinValueMarketProps = {
  setMarketSession: React.Dispatch<React.SetStateAction<MarketSession>>;
  coinValueReset: number;
  id_market_session: string;
};

const CoinValueMarket: React.FC<CoinValueMarketProps> = ({
  setMarketSession,
  coinValueReset,
  id_market_session,
}) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(coinValueReset);

  const { addToast } = useToast();

  const handleCoinValueReset = async () => {
    try {
      const response = await api.put('market/session/coin/value', {
        id_market_session,
        value,
      });

      setMarketSession((PrevState) => ({
        ...PrevState,
        coinInfo: response.data,
      }));

      setEditable(false);
      addToast({
        type: 'success',
        title: 'Alteração!',
        description: { code: 703 },
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação de credencias!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  if (editable) {
    return (
      <Container>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
        />
        <button
          type="button"
          title="Guardar Alteração"
          onClick={handleCoinValueReset}
        >
          <img src={save} alt="caneta" />
        </button>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <span>{value.toFixed(2)}</span>
        <button
          type="button"
          title="Alterar valor de renovação"
          onClick={() => setEditable(true)}
        >
          <img src={edit} alt="caneta" />
        </button>
      </Container>
    </>
  );
};

export default CoinValueMarket;
