/* eslint-disable import/no-duplicates */
import React, { forwardRef } from 'react';
import Select, { StylesConfig, OptionTypeBase } from 'react-select';

import { SelectDiv } from './styles';

type Option = {
  value: string | boolean | number;
  label: string;
};

type Options = {
  title: string;
  options: Option[];
  error: string | undefined;
  borderType?: boolean;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  value: string;
  width?: string;
};

type IsMulti = false;

const SelectStyle: StylesConfig<OptionTypeBase, IsMulti> = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    '&:hover': {
      cursor: 'text',
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#007970',
    '&:hover': {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1.8rem',
      color: '#007970',
    },
  }),
  input: (base) => {
    return {
      ...base,
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1.8rem',
      color: '#007970',
    };
  },
  menuList: (base) => {
    return {
      ...base,
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1.8rem',
      color: '#007970',
    };
  },
  singleValue: (base) => {
    return {
      ...base,
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1.8rem',
      color: '#007970',
    };
  },
  option: (base, state) => ({
    ...base,
    color: '#007970',
    backgroundColor: state.isSelected ? '#3CF0DE' : '',
    '&:hover': {
      backgroundColor: '#3CF0DE',
    },
  }),
};

const SelectInput = forwardRef<HTMLInputElement, Options>(
  ({ title, options, onChange, error, value, width, borderType }, ref) => {
    return (
      <SelectDiv
        isError={!!error}
        width={width}
        borderType={borderType || false}
      >
        <span>{title}</span>
        <Select
          inputRef={ref}
          options={options}
          styles={SelectStyle}
          placeholder=""
          value={value ? options.find((opt) => opt.value === value) : null}
          onChange={(e) => onChange(e?.value)}
        />
        <p>{error}</p>
      </SelectDiv>
    );
  },
);

export default SelectInput;
