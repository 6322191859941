import styled from 'styled-components';

export const Body = styled.div`
  position: relative;
  width: 500px;
  background-color: #fff;
  height: 650px;
  margin-top: 80px;
  padding: 10px;
  border-radius: 10px;

  h1 {
    font-size: 24px;
  }

  div {
    margin-top: 10px;

    max-height: 58rem;
    overflow-y: auto;
  }
`;

export const MemberItem = styled.button`
  width: 100%;
  font-size: 16px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 179, 167, 0.5);
  background-color: transparent;
  text-align: left;
  color: #007970;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  margin-top: 1rem;
  margin-right: 1rem;

  > button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;
