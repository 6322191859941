import styled from 'styled-components';

type ModalProps = {
  visible: boolean;
};

export const Container = styled.div<ModalProps>`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
`;
