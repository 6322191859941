/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '../../../hooks/ToastContext';
import api from '../../../config/api';

import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';

import {
  Container,
  Widget,
  Form,
  FormLine,
  ButtonBox,
  Button,
  ErrorMsg,
  FormLineNew,
} from './styles';

import Modal from '../../Modal';
import { DefaultOrder } from '../../../types/DefaultOrder';
import { Household } from '../../../types/Household';
import SelectInput from '../../Select';

type GenerateOneDefaultOrderProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultOrder: DefaultOrder;
};

type ResponseDataProps = {
  householdName: string;
  orderRef: string | null;
  orderStatus: boolean;
  orderErrorMessage: string;
};

const schema = yup.object().shape({
  householder: yup.string().required('* agregado obrigatório'),
});

const GenerateOneDefaultOrder: React.FC<GenerateOneDefaultOrderProps> = ({
  isOpen,
  onClose,
  defaultOrder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] =
    useState<ResponseDataProps | null>(null);
  const [householders, setHouseholders] = useState<Household[]>([]);
  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm<{ householder: string }>({
    resolver: yupResolver(schema),
  });

  const handleResetForm = useCallback(() => {
    onClose();
    reset();
    setResponseData(null);
  }, []);

  const handleOnSubmit = async (data: { householder: string }) => {
    setIsLoading(true);

    try {
      const response = await api.post(`/market/default-order/generate/one`, {
        id: defaultOrder.id,
        householder: data.householder,
      });

      setResponseData(response.data);

      addToast({
        type: 'success',
        title: 'Encomendas tipo geradas com sucesso.',
        description: { code: 734 },
      });
      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro ao gerar as encomendas tipo.',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  const getHouseholders = useCallback(async () => {
    try {
      const response = await api.get('household');
      setHouseholders(
        response.data.filter((h: Household) =>
          defaultOrder.defaultOrderHousehold.find(
            (doh) => doh.householdId === h.id,
          ),
        ),
      );
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter do agregados',
        description: { code: 300 },
      });
    }
  }, [addToast]);

  useEffect(() => {
    getHouseholders();
  }, [getHouseholders]);

  return (
    <Modal visible={isOpen}>
      {responseData ? (
        <Container>
          <h1>Relatório de encomenda tipo</h1>
          <Widget>
            <Form>
              <FormLineNew>
                <div>
                  <p>{responseData.householdName}</p>
                  {responseData.orderStatus ? (
                    <span style={{ color: '#58d68d' }}>OK</span>
                  ) : (
                    <span style={{ color: '#f1948a' }}>ERRO</span>
                  )}
                </div>
                <ErrorMsg>{responseData.orderErrorMessage}</ErrorMsg>
              </FormLineNew>
            </Form>
          </Widget>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              Fechar
            </Button>
          </ButtonBox>
        </Container>
      ) : (
        <Container>
          <h1>Gerar encomenda tipo</h1>
          <Widget>
            <Form
              id="generate_defaultOrderHousehold"
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <FormLine>
                <p>
                  Gerar encomenda tipo{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {defaultOrder.name} para:
                  </span>
                </p>
              </FormLine>
              <FormLine>
                <Controller
                  control={control}
                  name="householder"
                  render={({ field: { onChange, value } }) => (
                    <SelectInput
                      title="Agregado"
                      {...register(`householder`)}
                      onChange={onChange}
                      value={value}
                      width="100%"
                      error={errors.householder?.message}
                      options={householders.map((h) => ({
                        label: h.name,
                        value: h.id,
                      }))}
                    />
                  )}
                />
              </FormLine>
            </Form>
          </Widget>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              <img src={chevron} alt="Icon de seta back" />
              Voltar
            </Button>
            {isLoading ? (
              <Button
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
                form="generate_defaultOrderHousehold"
              >
                <img src={save} alt="Icon de desquete save" />
                Gerar
              </Button>
            )}
          </ButtonBox>
        </Container>
      )}
    </Modal>
  );
};

export default GenerateOneDefaultOrder;
