import styled from 'styled-components';

type ButtonColors = {
  bgColor: string;
  bgHoverColor: string;
};

export const BoxDates = styled.div`
  background-color: transparent;
  color: #007970;
  align-self: self-start;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  border-radius: 0.5rem;

  h1 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  > div {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 20px;
    }

    > p {
      font-size: 1.6rem;
    }
  }
`;

export const ButtonDate = styled.button`
  border: none;
  font-size: 1.2rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 4px;
  margin-left: 10px;
  background-color: #007970;
  color: rgb(60, 240, 222);
`;

export const ModalBoxConfirm = styled.div`
  position: relative;
  background: #fff;
  width: 40rem;
  height: 24rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  h1 {
    width: 100%;
    text-align: left;
    font-size: 2rem;
    font-weight: normal;
    margin-bottom: 10px;

    span {
      font-weight: bold;
    }
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Button = styled.button<ButtonColors>`
  color: #3cf0de;
  font-size: 2rem;
  padding: 0.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 2px;
  margin-top: 20px;
  border: none;
  background: ${(props) => props.bgColor};

  &:hover {
    background: ${(props) => props.bgHoverColor};
  }
`;

export const InputText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    margin-left: 2px;
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: #ff6666;
  }

  h4 {
    font-size: 10px;
    color: #ff6666;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const Input = styled.input`
  width: 50%;
  text-align: center;
  padding-top: 0;
  min-height: 30px;
  color: #007970;
  font-size: 1.8rem;
  border: 1px solid #007970;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &::-webkit-input-placeholder {
    color: rgba(0, 121, 112, 0.8);
  }
`;
