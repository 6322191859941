/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';

import { format, parseISO, startOfWeek } from 'date-fns';
import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import meal from '../../../assets/meal.svg';

import {
  DashCard,
  Card,
  ImageCard,
  Info,
  InfoCard,
  InfoCardCircle,
  InfoCardTitle,
  InputDate,
} from './styles';

type DashboardInfo = {
  numMeals: number;
  startWeek: Date;
  endWeek: Date;
};

const MealDashboard: React.FC = () => {
  const [dashboardInfo, setDashboardInfo] = useState({} as DashboardInfo);
  const [date, setDate] = useState(
    format(startOfWeek(new Date()), 'yyyy-MM-dd'),
  );

  const { addToast } = useToast();

  const getDashboardInfo = useCallback(
    async (d: string) => {
      try {
        const response = await api.get(`/dashboard/meal?d=${d}`);
        setDashboardInfo(response.data);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter dados',
          description: { code: 750 },
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    getDashboardInfo(date);
  }, [date]);

  return (
    <DashCard>
      <Card>
        <InputDate
          type="date"
          onChange={(e) => {
            if (e.currentTarget.value) {
              const newDate = format(
                startOfWeek(parseISO(e.currentTarget.value)),
                'yyyy-MM-dd',
              );
              newDate === date ? null : setDate(newDate);
            }
          }}
        />
        <ImageCard>
          <p>
            De:{' '}
            {dashboardInfo.startWeek &&
              format(
                parseISO(dashboardInfo.startWeek.toString()),
                'dd/MM/yyyy',
              )}{' '}
            Até:{' '}
            {dashboardInfo.endWeek &&
              format(parseISO(dashboardInfo.endWeek.toString()), 'dd/MM/yyyy')}
          </p>
          <img src={meal} alt="Refeições" />
        </ImageCard>
        <Info>
          <InfoCard>
            <InfoCardCircle>{dashboardInfo.numMeals}</InfoCardCircle>
            <InfoCardTitle>Nº De Refeições Entregues</InfoCardTitle>
          </InfoCard>
        </Info>
      </Card>
    </DashCard>
  );
};

export default MealDashboard;
