/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import Td from '../../Table/Td';

import { ProductImg, ProductData, Line, ProductIndex } from './styles';

import api from '../../../config/api';

import { Product, ProductIcon } from '../../../types/Product';

import stock from '../../../assets/stock.svg';
import alertIcon from '../../../assets/alertStock.svg';
import edit from '../../../assets/edit.svg';
import available from '../../../assets/availableOnMarket.svg';
import unavailable from '../../../assets/unavailableOnMarket.svg';
import productImageAlt from '../../../assets/productImageAlt.svg';

type ProductLinePropsReq = {
  product: Product;
  index: number;
  handleEdit(product: Product | null, icon: string | null): void;
  handleStock(product: Product | null, icon: string | null): void;
  handleStatus(product: Product | null): void;
};

const ProductLine: React.FC<ProductLinePropsReq> = ({
  product,
  index,
  handleEdit,
  handleStock,
  handleStatus,
}) => {
  const [iconSrc, setIconSrc] = useState(productImageAlt);
  const [alertStock, setAlertStock] = useState(false);

  const getProductIcon = async (icon: ProductIcon) => {
    try {
      const responseAvatar = await api.get(icon.path, {
        responseType: 'blob',
      });

      setIconSrc(URL.createObjectURL(responseAvatar.data));
    } catch (error) {
      setIconSrc(productImageAlt);
    }
  };

  useEffect(() => {
    if (product.icon) {
      getProductIcon(product.icon);
    }
    if (product.stock <= product.stockAlert) {
      setAlertStock(true);
    } else {
      setAlertStock(false);
    }
  }, [product]);

  return (
    <Line available={product.availableOnMarket}>
      <Td talign="center" width="5%">
        <ProductIndex>
          {alertStock && <img src={alertIcon} alt="ponto de exclamação" />}
          {index}
        </ProductIndex>
      </Td>
      <Td talign="left" width="32%">
        <ProductData title={product.description}>
          <ProductImg src={iconSrc} alt="Imagem do produto" />
          {product.name}
        </ProductData>
      </Td>
      <Td talign="left" width="13%">
        {product.category.name}
      </Td>
      <Td talign="center" width="10%">
        {product.stock}
        <small>/un</small>
      </Td>
      <Td talign="center" width="10%">
        {product.stockAlert}
        <small>/un</small>
      </Td>
      <Td talign="center" width="10%">
        {product.value.toFixed(2)}
        <small>sm</small>
      </Td>
      <Td talign="center" width="10%">
        <div>
          <button
            type="button"
            title="Adicionar stock"
            onClick={() => handleStock(product, product.icon ? iconSrc : null)}
          >
            <img src={stock} alt="icon de uma caixa" />
          </button>
          <button
            type="button"
            title="Editar produto"
            onClick={() => handleEdit(product, product.icon ? iconSrc : null)}
          >
            <img src={edit} alt="icon de um lápis" />
          </button>
          {product.availableOnMarket ? (
            <button
              type="button"
              title="Remove do Marcado"
              onClick={() => handleStatus(product)}
            >
              <img src={unavailable} alt="icon de um carrinho de compras" />
            </button>
          ) : (
            <button
              type="button"
              title="Adicionar ao Mercado"
              onClick={() => handleStatus(product)}
            >
              <img src={available} alt="icon de um carrinho de compras" />
            </button>
          )}
        </div>
      </Td>
    </Line>
  );
};

export default ProductLine;
