/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseISO, format, differenceInYears } from 'date-fns';
import { pt } from 'date-fns/locale';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Menu from '../../../components/Menu';

import BackButton from '../../../components/BackButton';

import {
  Container,
  Widget,
  UserForm,
  Photo,
  Form,
  Line,
  Field,
  EditButton,
  ButtonPrivate,
  ActiveLabel,
  Supports,
} from './styles';

import photo from '../../../assets/photo.svg';
import edit from '../../../assets/edit.svg';
import directSupportIcon from '../../../assets/directsupport.svg';

import { User } from '../../../types/Users';
import NotesCards from '../../../components/Notes';

type LocationProps = {
  id: string;
};

const View: React.FC = () => {
  const [user, setUser] = useState({} as User);
  const [avatar, setAvatar] = useState(photo);
  const history = useHistory();
  const location = useLocation<LocationProps>();

  const { addToast } = useToast();

  const id_user = location.state ? location.state.id : null;

  const getUserPrivateData = async () => {
    try {
      const response = await api.get(`user/private/${id_user}`);

      setUser((presState) => ({
        ...presState,
        ...response.data,
      }));
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados privados do utente',
        description: { code: 207 },
      });
    }
  };

  const getUserData = useCallback(async () => {
    try {
      const response = await api.get(`user/${id_user}`);

      setUser({
        ...response.data,
        birth_date: `${format(
          parseISO(response.data.birth_date),
          "dd'/'MM'/'yyyy",
          {
            locale: pt,
          },
        )} -
         ${differenceInYears(
           new Date(),
           parseISO(response.data.birth_date),
         )} anos`,
      });
      if (response.data.avatar) {
        const responseAvatar = await api.get(response.data.avatar.path, {
          responseType: 'blob',
        });

        setAvatar(URL.createObjectURL(responseAvatar.data));
      }
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados do utente',
        description: { code: 207 },
      });
    }
  }, [id_user, addToast]);

  useEffect(() => {
    if (id_user) {
      getUserData();
    } else {
      history.push('utentes');
    }
  }, [getUserData, id_user, history]);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <BackButton url="/utentes" />
          <UserForm>
            <Photo>
              <img src={avatar} alt="foto do utente" />
              <EditButton
                type="button"
                onClick={() =>
                  history.push({
                    pathname: '../utentes/editar',
                    state: {
                      id: id_user,
                    },
                  })
                }
              >
                <img src={edit} alt="Imagem de um lapis" />
              </EditButton>
              {!user.nif && !user.ss_num && !user.cc_num && (
                <ButtonPrivate
                  type="button"
                  title="Sujeito a log no sistema"
                  onClick={getUserPrivateData}
                >
                  Ver Informação Privada
                </ButtonPrivate>
              )}
            </Photo>
            <Form>
              <Line>
                <Field width="54%;">
                  <h1>Nome</h1>
                  <p>{user.name}</p>
                </Field>
                <Field width="20%;" align="center">
                  <h1>Data de Nascimento</h1>
                  <p>{user.birth_date}</p>
                </Field>
                <Field width="20%;" align="center">
                  <h1>Número Interno</h1>
                  <p>{user.int_num}</p>
                </Field>
              </Line>
              <Line>
                <Field width="22%;" align="center">
                  <h1>Núm. Cartão Cidadão</h1>
                  <p>{user.cc_num || '*********'}</p>
                </Field>
                <Field width="22%;" align="center">
                  <h1>Núm. Seg. Social</h1>
                  <p>{user.ss_num || '*********'}</p>
                </Field>
                <Field width="22%;" align="center">
                  <h1>Núm. Contribuinte</h1>
                  <p>{user.nif || '*********'}</p>
                </Field>
                <Field width="22%;" align="center">
                  <h1>Núm. Passaporte</h1>
                  <p>{user.passport_num || '*********'}</p>
                </Field>
              </Line>
              <Line>
                <Field width="60%;">
                  <h1>Morada</h1>
                  <p>{user.address}</p>
                </Field>
                <Field width="15%;" align="center">
                  <h1>Código Postal</h1>
                  <p>{user.zip}</p>
                </Field>
                <Field width="20%;">
                  <h1>Localidade</h1>
                  <p>{user.location}</p>
                </Field>
              </Line>
              <Line>
                <Field width="45%;">
                  <h1>Email</h1>
                  <p>{user.email}</p>
                </Field>
                <Field width="32%;" align="center">
                  <h1>Contacto</h1>
                  <p>{user.contact}</p>
                </Field>
                <Field width="18%;" align="center">
                  <h1>Nacionalidade</h1>
                  <p>{user.nationality ? user.nationality : 'Não definido'}</p>
                </Field>
              </Line>
              {user.id && !user.active && (
                <ActiveLabel>Utente Inativo</ActiveLabel>
              )}
            </Form>
          </UserForm>

          <Supports
            onClick={() =>
              history.push({
                pathname: '/apoios',
                state: {
                  id: user.id,
                  active: user.active,
                },
              })
            }
          >
            <div>
              <img src={directSupportIcon} alt="icons apoios" />
              <p>Apoios </p>
            </div>
          </Supports>

          <NotesCards
            id_user={id_user || ''}
            notes={user.notes}
            active={user.active}
          />
        </Widget>
      </Container>
    </>
  );
};

export default View;
