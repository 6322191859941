import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import CreateUsers from '../pages/Users/Create';
import EditUsers from '../pages/Users/Edit';
import ViewUsers from '../pages/Users/View';
import Household from '../pages/Household';
import ViewHousehold from '../pages/Household/View';
import Account from '../pages/Account';
import Accounts from '../pages/Accounts';
import Log from '../pages/Log';
import Support from '../pages/Supports/Support';
// import DirectSupport from '../pages/Supports/DirectSupport';
import Products from '../pages/Products';
import Orders from '../pages/Orders';
import DefaultOrders from '../pages/DefaultOrders';
import MarketStatus from '../pages/MarketStatus';
import RoutesPage from '../pages/Routes';
import PDFRoutes from '../pages/PDFRoutes';
import PDFRoutesTag from '../pages/PDFRoutesTag';
import PDFOrder from '../pages/PDFOrder';
import PDFHousehold from '../pages/PDFHousehold';
import CountSupport from '../pages/CountSupport';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/dashboard" exact component={Dashboard} isPrivate />
    <Route path="/utentes" exact component={Users} isPrivate />
    <Route path="/utentes/criar" exact component={CreateUsers} isPrivate />
    <Route path="/utentes/editar" exact component={EditUsers} isPrivate />
    <Route path="/utentes/ver" exact component={ViewUsers} isPrivate />
    <Route path="/agregados" exact component={Household} isPrivate />
    <Route path="/agregados/ver" exact component={ViewHousehold} isPrivate />
    <Route path="/apoios" exact component={Support} isPrivate />
    <Route path="/produtos" exact component={Products} isPrivate />
    <Route path="/encomendas" exact component={Orders} isPrivate />
    <Route path="/encomendas-tipo" exact component={DefaultOrders} isPrivate />
    <Route path="/estado-mercearia" exact component={MarketStatus} isPrivate />
    <Route path="/rotas" exact component={RoutesPage} isPrivate />
    <Route path="/count-support" exact component={CountSupport} isPrivate />
    <Route path="/pdf-rotas" exact component={PDFRoutes} isPrivate />
    <Route path="/pdf-agregados" exact component={PDFHousehold} isPrivate />
    <Route
      path="/pdf-rotas-etiquetas"
      exact
      component={PDFRoutesTag}
      isPrivate
    />
    <Route path="/pdf-encomenda" exact component={PDFOrder} isPrivate />
    <Route path="/conta" exact component={Account} isPrivate />
    <Route path="/contas" exact component={Accounts} isPrivate />
    <Route path="/logs" exact component={Log} isPrivate />
    <Route component={Login} />
  </Switch>
);

export default Routes;
