import styled from 'styled-components';

export const Container = styled.button`
  position: fixed;
  top: 8rem;
  left: 2rem;
  color: #007970;
  background: #3cf0de;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 50%;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: #10d5c2;
  }
`;
