import styled from 'styled-components';

type ThProps = {
  width: string;
  talign: string;
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 1200px;
  height: 700px;
  background-color: #fff;
  padding: 1rem;
  margin-top: 4rem;
  border-radius: 5px;

  > h1 {
    width: 100%;
    text-align: left;
    font-size: 25px;
  }

  > div {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;

    > table {
      width: 100%;
      border-collapse: collapse;

      > thead {
        z-index: 2;
      }
    }
  }
`;

export const Th = styled.th<ThProps>`
  width: ${(props) => props.width};
  font-size: 1.5rem;
  font-weight: bold;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: rgba(60, 240, 222, 1);
  position: sticky;

  top: 0;
  z-index: 2;
  small {
    font-size: 16px;
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }

  p {
    font-size: 3rem;
    color: #007970;
  }
`;
