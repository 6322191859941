import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.div`
  width: 140rem;
  max-width: 140rem;
`;

export const Top = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 3rem;
    font-weight: bold;
  }

  button {
    margin-left: 1.5rem;
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;

  input {
    border: 2px solid #007970;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 30rem;
    color: #007970;
    font-size: 1.6rem;
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  div {
    margin-left: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const ModalBox = styled.div`
  position: relative;
  width: 50rem;
  max-width: 50rem;
  padding: 2rem;
  padding-top: 4rem;
  background: #fff;
  max-height: 45rem;
  margin-top: 5rem;
  border-radius: 1rem;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
    color: #007970;
    margin-bottom: 4rem;
  }

  form {
    > div + div {
      margin-top: 2rem;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 3rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const Button = styled.button`
  color: #3cf0de;
  background: #007970;
  width: 100%;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: #00665f;
  }
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;

export const PageBox = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #007970;
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    font-size: 2.4rem;
    border-radius: 50%;
    border: none;
    transition: background 0.2s;

    img {
      width: 2.8rem;
    }
  }

  p {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const Tdid = styled.div`
  display: flex;
  width: 100%;

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
`;
