/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../../config/api';
import { useToast } from '../../../../hooks/ToastContext';

import save from '../../../../assets/save.svg';
import loading from '../../../../assets/loading03.svg';
import pt from '../../../../assets/pt.svg';
import uk from '../../../../assets/uk.svg';

import {
  Container,
  Modal,
  Close,
  Button,
  ButtonBox,
  Form,
  FormLine,
} from './styles';

import { RouteWeekSettings } from '../../../../types/RouteWeek';
import Input from '../../../Input';

type DataFormSubmit = {
  weekPT: number;
  weekUK: number;
};

type ModalProps = {
  visible: boolean;
  closeModal(): void;
};

const schema = yup.object().shape({
  weekPT: yup
    .number()
    .integer('* o número da semana tem de ser um número inteiro')
    .moreThan(-1)
    .required('* o número da semana é obrigatório'),
  weekUK: yup
    .number()
    .integer('* o número da semana tem de ser um número inteiro')
    .moreThan(-1)
    .required('* o número da semana é obrigatório'),
});

const RouteWeekSettingModal: React.FC<ModalProps> = ({
  closeModal,
  visible,
}) => {
  const [routeWeekSettings, setRouteWeekSettings] = useState(
    {} as RouteWeekSettings,
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<DataFormSubmit>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const getRouteWeekSettings = useCallback(async () => {
    try {
      const response = await api.get(`route-week-settings`);

      setRouteWeekSettings(response.data);
      setValue('weekPT', response.data.weekPT);
      setValue('weekUK', response.data.weekUK);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter rota',
        description: { code: 820 },
      });
    }
  }, [addToast, setValue]);

  const handleOnSubmit = async (data: DataFormSubmit) => {
    try {
      setIsLoading(true);
      await api.put(`route-week-settings/${routeWeekSettings.id}`, data);

      addToast({
        type: 'success',
        title: 'Edição das configurações da semana de rotas',
        description: { code: 821 },
      });

      setIsLoading(false);
      closeModal();
    } catch (e: any) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na edição das configurações da semana de rotas',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  useEffect(() => {
    getRouteWeekSettings();
  }, [getRouteWeekSettings]);

  return (
    <Container visible={visible}>
      <Modal>
        <Close>
          <button type="button" onClick={closeModal}>
            &#x2715;
          </button>
        </Close>
        <Form id="add_turn" onSubmit={handleSubmit(handleOnSubmit)}>
          <FormLine>
            <img src={pt} alt="Bandeira de Portugal" />
            <Input
              width="20%"
              title=""
              textAlign="center"
              type="number"
              borderType
              {...register('weekPT')}
              error={errors.weekPT?.message}
            />
          </FormLine>
          <FormLine>
            <img src={uk} alt="Bandeira da Ucrania" />
            <Input
              width="20%"
              title=""
              textAlign="center"
              type="number"
              borderType
              {...register('weekUK')}
              error={errors.weekUK?.message}
            />
          </FormLine>

          <ButtonBox>
            {isLoading ? (
              <Button
                form="add_turn"
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                form="add_turn"
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
              >
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            )}
          </ButtonBox>
        </Form>
      </Modal>
    </Container>
  );
};

export default RouteWeekSettingModal;
