import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #007970;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogDiv = styled.div`
  width: 30rem;
  height: 45rem;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-top: 2rem;
    width: 80%;
  }
`;

export const FormLogin = styled.form`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Submit = styled.button`
  margin-top: 4rem;
  width: 60%;
  background: #007970;
  color: #3cf0de;
  padding: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  border: 1px solid #007970;
  border-radius: 0.2rem;
  transition: background 0.2s;

  &:hover {
    cursor: pointer;
    background: #00665f;
  }
`;
