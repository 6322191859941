import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: '10px',
  },
  tag_box: {
    minWidth: '100%',
    height: '205px',
    padding: '5px',
    maxHeight: '205px',
    borderBottom: '2px dashed #aaa',
    position: 'relative',
  },
  tag_route_text: {
    fontSize: '18px',
    width: '100%',
    textAlign: 'right',
  },
  tag_route_householder: {
    fontSize: '18px',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tag_route_householder_num_box: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tag_route_householder_num_text: {
    width: '80px',
    height: '80px',
    minWidth: '80px',
    minHeight: '80px',
    maxWidth: '80px',
    maxHeight: '80px',
    padding: '10px',
    border: '1px solid #000',
    borderRadius: '50%',
    fontSize: '50px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tag_turn_tags: {
    position: 'absolute',
    minWidth: '100px',
    maxWidth: '250px',
    display: 'flex',
    right: '10px',
    flexDirection: 'column',
    top: '50px',
  },
  tag_turn_tags_left: {
    position: 'absolute',
    minWidth: '100px',
    maxWidth: '250px',
    display: 'flex',
    left: '10px',
    flexDirection: 'column',
    top: '50px',
  },

  tag_turn_tags_text: {
    marginTop: '2px',
    padding: '2px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  tag_route_info: {
    marginTop: '12px',
  },
  tag_route_info_text: {
    fontSize: '14px',
    marginTop: '4px',
  },
});
