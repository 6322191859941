import styled from 'styled-components';

export const Container = styled.header`
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 7rem;
  padding: 1rem;
  background: #007970;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuBox = styled.div`
  width: 1400px;
  max-width: 1400px;
  height: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;

  img {
    width: 70px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export const MenuList = styled.div`
  position: relative;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: #3cf0de;
    font-size: 2.5rem;
    border: none;
    background-color: transparent;
  }

  &:hover {
    div {
      display: block;
    }

    button {
      text-decoration: underline;
    }
  }

  div {
    display: none;
    position: absolute;
    background-color: #3cf0de;
    min-width: 180px;
    width: auto;
    left: calc(50% - 90px);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 20;

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 20px);
      top: -20px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #3cf0de;
    }

    a {
      width: auto;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 1.8rem;
      color: #007970;

      &:hover {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .button-accounts {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    margin-right: 1.5rem;
    .img-accounts {
      width: 4rem;
    }
  }

  .button-account {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    margin-right: 1rem;
    .img-account {
      width: 4rem;
    }
  }

  .button-log {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    margin-right: 1rem;
    .img-log {
      width: 4rem;
    }
  }
`;

export const Logout = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;

  img {
    width: 5rem;
  }
`;
