import React from 'react';

import { Container } from './styles';

type TdProps = {
  width: string;
  talign: string;
};

const Td: React.FC<TdProps> = ({ children, width, talign }) => {
  return (
    <Container width={width} talign={talign}>
      {children}
    </Container>
  );
};

export default Td;
