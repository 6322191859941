/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../../Input';
import Select from '../../../Select';
import api from '../../../../config/api';
import { Household } from '../../../../types/Household';
import { useToast } from '../../../../hooks/ToastContext';
import TextArea from '../../../TextArea';

import {
  Container,
  Modal,
  Form,
  FormLine,
  ButtonBox,
  Button,
  Close,
  CenterCheckBox,
} from './styles';

import save from '../../../../assets/save.svg';
import loading from '../../../../assets/loading03.svg';
import CheckBox from '../../../CheckBox';

import { Routes, Turn } from '../../../../types/Routes';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
  idRoute: string;
  turn: Turn;
  refreshData: () => Promise<void>;
  setRoutes: React.Dispatch<React.SetStateAction<Routes[]>>;
};

type DataFormSubmit = {
  idHousehold: string;
  note?: string;
  canCook: boolean;
  numMeal: number;
  onceMonth: boolean;
};

type HouseholdOption = {
  value: string;
  label: string;
};

const schema = yup.object().shape({
  idHousehold: yup.string().required('* o agregado é obrigatório'),
  note: yup.string(),
  canCook: yup.boolean(),
  numMeal: yup
    .number()
    .integer('* o número de refeições tem de ser um número inteiro')
    .moreThan(0)
    .required('* o número de refeições é obrigatório'),
  onceMonth: yup.boolean(),
});

const EditTurnModal: React.FC<ModalProps> = ({
  visible,
  closeModal,
  idRoute,
  turn,
  refreshData,
  setRoutes,
}) => {
  const [optionHousehold, setOptionHousehold] = useState(
    [] as HouseholdOption[],
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<DataFormSubmit>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const getHouseholdOptions = useCallback(async () => {
    try {
      const response = await api.get('household');

      const householdOption = response.data.map((household: Household) => {
        return {
          value: household.id,
          label: household.name,
        };
      });

      setOptionHousehold(householdOption);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter agregados',
        description: { code: 306 },
      });
    }
  }, [addToast]);

  const handleOnSubmit = async (data: DataFormSubmit) => {
    try {
      setIsLoading(true);
      const response = await api.put(`turns/${turn.id}`, { ...data, idRoute });

      addToast({
        type: 'success',
        title: 'Edição de Volta',
        description: { code: 803 },
      });

      setRoutes((prevState) =>
        prevState.map((route) =>
          route.id === idRoute
            ? {
                ...route,
                turns: route.turns
                  ? [...route.turns, response.data]
                  : [response.data],
              }
            : route,
        ),
      );

      refreshData();
      setIsLoading(false);
      closeModal();
    } catch (e: any) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na edição da volta',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  useEffect(() => {
    getHouseholdOptions();
    setValue('idHousehold', turn.idHousehold);
    setValue('numMeal', turn.numMeal);
    setValue('canCook', turn.canCook);
    setValue('note', turn.note);
  }, [getHouseholdOptions, setValue, turn]);

  return (
    <Container visible={visible}>
      <Modal>
        <Close>
          <button type="button" onClick={closeModal}>
            &#x2715;
          </button>
        </Close>
        <Form id="add_turn" onSubmit={handleSubmit(handleOnSubmit)}>
          <FormLine>
            <Controller
              control={control}
              name="idHousehold"
              render={({ field: { onChange, value } }) => (
                <Select
                  title="Agregado Familiar"
                  {...register('idHousehold')}
                  onChange={onChange}
                  borderType
                  value={value}
                  error={errors.idHousehold?.message}
                  options={optionHousehold}
                />
              )}
            />
          </FormLine>
          <FormLine>
            <Input
              width="30%"
              title="Nº Refeições"
              type="number"
              borderType
              {...register('numMeal')}
              error={errors.numMeal?.message}
            />
            <CenterCheckBox>
              <CheckBox
                width="auto"
                title="Cozinha?"
                type="checkbox"
                {...register('canCook')}
                error={errors.canCook?.message}
              />
            </CenterCheckBox>
            <CenterCheckBox>
              <CheckBox
                width="auto"
                title="Uma vez por mês?"
                type="checkbox"
                {...register('onceMonth')}
                error={errors.onceMonth?.message}
              />
            </CenterCheckBox>
          </FormLine>
          <FormLine>
            <TextArea
              width="100%"
              title="Notas"
              backgoundColorStatus
              {...register('note')}
              error={errors.note?.message}
            />
          </FormLine>
          <ButtonBox>
            {isLoading ? (
              <Button
                form="add_turn"
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                form="add_turn"
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
              >
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            )}
          </ButtonBox>
        </Form>
      </Modal>
    </Container>
  );
};

export default EditTurnModal;
