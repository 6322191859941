/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Modal, ButtonBox, Button, Title } from './styles';

type ModalProps = {
  close(): void;
  confirm(): void;
  title: string;
  isLoading: boolean;
};

const OrderModalConfirm: React.FC<ModalProps> = ({
  close,
  confirm,
  title,
  isLoading,
}) => {
  return (
    <Modal>
      <Title> {title}</Title>
      <ButtonBox>
        <Button
          type="button"
          color="#3cf0de"
          bgcolor="#ff9999"
          bghover="#ff6666"
          onClick={close}
          disabled={isLoading}
        >
          Não
        </Button>
        <Button
          type="button"
          color="#3cf0de"
          bgcolor="#007970"
          bghover="#00665f"
          onClick={confirm}
          disabled={isLoading}
        >
          Sim
        </Button>
      </ButtonBox>
    </Modal>
  );
};

export default OrderModalConfirm;
