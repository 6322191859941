import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.div`
  width: 140rem;
  max-width: 140rem;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;

export const MarketStatusCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  background-color: #3cf0de;
  height: auto;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0px 8px 8px 0px rgba(11, 142, 129, 0.7);
  -moz-box-shadow: 0px 8px 8px 0px rgba(11, 142, 129, 0.7);
  -webkit-box-shadow: 0px 8px 8px 0px rgba(11, 142, 129, 0.7);

  > h1 {
    font-size: 26px;
    font-weight: bold;
    color: #007970;
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
`;

export const MarketStatusCardFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid #007970;
  border-radius: 10px;
  padding: 10px;
  width: 30%;
`;

export const MarketStatusTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #007970;
`;

export const MarketStatusCircle = styled.div<{ color: string }>`
  margin-top: 20px;
  display: flex;
  align-items: center;

  h4 {
    font-size: 18px;
    font-weight: bold;
    color: #007970;
    margin-right: 5px;
  }

  p {
    font-size: 18 px;
    font-weight: bold;
    color: ${(props) => props.color};
  }
`;

export const OrderButton = styled.button`
  border: none;
  width: 150px;
  background: #007970;
  color: #3cf0de;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 1.4rem;
  box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  margin-top: 2rem;
  align-self: center;

  img {
    width: 2rem;
  }

  &:hover {
    cursor: pointer;
    background: #00665f;
  }
`;

export const ViewMembersGroupButton = styled.button`
  position: absolute;
  top: 0;
  margin-top: 10px;
  right: 0;
  margin-right: 10px;
  border: none;
  width: 120px;
  background: #007970;
  color: #3cf0de;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 1.4rem;
  box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  align-self: center;

  img {
    width: 2rem;
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    background: #00665f;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  width: 60rem;
  max-width: 60rem;
  max-height: 22rem;
  margin-top: 10rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  padding: 5rem;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
`;

export const ButtonBox = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 15rem;
  padding: 0.7rem;
  font-size: 2rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const DistributionText = styled.div`
  width: 100%;
  text-align: left;
  padding-top: 10px;
  display: flex;
  align-items: center;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: #007970;
    margin-right: 5px;
  }

  span {
    font-size: 18px;
    color: ${(props) => props.color};
  }
`;

export const DistributionInputText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  span {
    margin-left: 2px;
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: #ff6666;
  }
`;

export const DistributionInput = styled.input`
  width: 50%;
  text-align: center;
  padding-top: 0;
  min-height: 30px;
  color: #007970;
  font-size: 1.8rem;
  border: 1px solid #007970;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &::-webkit-input-placeholder {
    color: rgba(0, 121, 112, 0.8);
  }
`;

export const OrderButtonsTittle = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  font-weight: bold;
`;

export const OrderButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;
