/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import {
  BoxDates,
  ButtonDate,
  ModalBoxConfirm,
  Button,
  InputText,
  Input,
} from './styles';

import inDate from '../../assets/in.svg';
import { useToast } from '../../hooks/ToastContext';
import api from '../../config/api';
import Modal from '../Modal';
import { Household } from '../../types/Household';

type DatesProps = {
  id: string;
  in_date: string | null;
  out_date: string | null;
  handleUpdateDataHousehold: (data: Household) => void;
};

const HouseholdDates: React.FC<DatesProps> = ({
  id,
  in_date,
  out_date,
  handleUpdateDataHousehold,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setMoldaIsOpen] = useState(false);
  const [dateType, setDateType] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState(false);

  const { addToast } = useToast();

  const handleAddDateIn = async () => {
    setIsLoading(true);
    try {
      if (!date) {
        setError(true);
        setIsLoading(false);
        return;
      }

      const response = await api.post(`/household/update/in-date/${id}`, {
        inDate: date,
      });

      handleUpdateDataHousehold(response.data);

      addToast({
        type: 'success',
        title: 'Data de entrada!',
        description: { code: 312 },
      });
      setMoldaIsOpen(false);
      setIsLoading(false);
      setDate('');
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Data de entrada!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
      setDate('');
    }
  };

  const handleAddDateOut = async () => {
    try {
      if (!date) {
        setError(true);
        setIsLoading(false);
        return;
      }

      const response = await api.post(`/household/update/out-date/${id}`, {
        outDate: date,
      });

      handleUpdateDataHousehold(response.data);

      addToast({
        type: 'success',
        title: 'Data de saída!',
        description: { code: 312 },
      });
      setMoldaIsOpen(false);
      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Data de saída!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <BoxDates>
        <h1>Datas:</h1>
        <div>
          <img src={inDate} alt="homem a correr" />:{' '}
          {in_date && (
            <p>
              {format(parseISO(in_date), `dd 'de' MMMM 'de' yyyy `, {
                locale: pt,
              })}
            </p>
          )}
        </div>
        <div>
          <img
            src={inDate}
            style={{ transform: 'rotateY(180deg)' }}
            alt="homem a correr"
          />
          :{' '}
          {out_date && (
            <p>
              {format(parseISO(out_date), `dd 'de' MMMM 'de' yyyy`, {
                locale: pt,
              })}
            </p>
          )}
        </div>

        {!in_date && !out_date && (
          <ButtonDate
            type="button"
            onClick={() => {
              setDateType('inDate');
              setMoldaIsOpen(true);
            }}
          >
            definir data de entrada
          </ButtonDate>
        )}

        {in_date && !out_date && (
          <ButtonDate
            type="button"
            onClick={() => {
              setDateType('outDate');
              setMoldaIsOpen(true);
            }}
          >
            definir data de saída
          </ButtonDate>
        )}
      </BoxDates>
      {modalIsOpen && (
        <Modal visible={modalIsOpen}>
          <ModalBoxConfirm>
            {dateType === 'inDate' && <h1>Definir data de entrada</h1>}
            {dateType === 'outDate' && <h1>Definir data de saída</h1>}
            <div>
              <InputText>
                {dateType === 'inDate' && <span>Data de entrada</span>}
                {dateType === 'outDate' && <span>Data de saída</span>}
                {dateType === 'outDate' && (
                  <h4>
                    Nota: atenção que todos os membros deste agregado serão
                    desativados caso confirme a data de saída
                  </h4>
                )}

                <Input
                  type="date"
                  defaultValue={date}
                  onChange={(e) => setDate(e.currentTarget.value)}
                />
                {error && <p>* é obrigatório definir a data</p>}
              </InputText>
            </div>
            <div>
              {dateType === 'inDate' && (
                <Button
                  bgColor="#007970"
                  bgHoverColor="#00665f"
                  type="button"
                  onClick={handleAddDateIn}
                  disabled={isLoading}
                >
                  Sim
                </Button>
              )}
              {dateType === 'outDate' && (
                <Button
                  bgColor="#007970"
                  bgHoverColor="#00665f"
                  type="button"
                  onClick={handleAddDateOut}
                  disabled={isLoading}
                >
                  Sim
                </Button>
              )}

              <Button
                bgColor="#ff8080"
                bgHoverColor="#ff4d4d"
                type="button"
                onClick={() => {
                  setMoldaIsOpen(false);
                  setDate('');
                }}
                disabled={isLoading}
              >
                Não
              </Button>
            </div>
          </ModalBoxConfirm>
        </Modal>
      )}
    </>
  );
};

export default HouseholdDates;
