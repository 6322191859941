/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../../config/api';
import { useToast } from '../../../../hooks/ToastContext';

import { Container, Button } from './styles';
import { MarketSession } from '../../../../types/Household';
import { Order } from '../../../../types/Order';

type CreditingValueProps = {
  household_id: string;
  updateMartekSession(data: MarketSession): void;
};

const schema = yup.object().shape({
  creditingProduct: yup.string().required('* produto é obrigatório'),
  creditingAmount: yup
    .number()
    .moreThan(0, 'a quantidade a creditar tem de ser superior a 0')
    .required('* a quantidade é obrigatória'),
});

const CreditingProduct: React.FC<CreditingValueProps> = ({
  household_id,
  updateMartekSession,
}) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    creditingProduct: string;
    creditingAmount: number;
  }>({
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (data: {
    creditingProduct: string;
    creditingAmount: number;
  }) => {
    setIsLoading(true);

    try {
      const response = await api.put(`market/crediting/${household_id}`, {
        ...data,
        creditingDescription: null,
        creditingValue: null,
      });

      addToast({
        type: 'success',
        title: 'Creditação',
        description: { code: 770 },
      });

      updateMartekSession(response.data.market_session);

      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na creditação',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  const getHouseHoldOrders = useCallback(
    async (id: string) => {
      try {
        const response = await api.get(`order/household-accepted-orders/${id}`);

        setOrders(response.data);
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Listar Encomendas',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    getHouseHoldOrders(household_id);
  }, [household_id, getHouseHoldOrders]);

  return (
    <Container>
      {orders.length <= 0 ? (
        <p>Não há encomendas disponíveis para creditar produtos.</p>
      ) : (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div>
            <span>Encomenda:</span>

            <select onChange={(e) => setSelectedOrder(e.currentTarget.value)}>
              <option value="" />
              {orders.map((order) => (
                <option key={order.id} value={order.id}>
                  {order.intRef}
                </option>
              ))}
            </select>
          </div>
          {selectedOrder && (
            <>
              <div>
                <span>Produto:</span>
                <select
                  {...register('creditingProduct', {
                    onChange: (e) => setSelectedProduct(e.currentTarget.value),
                  })}
                >
                  <option value="" />
                  {orders
                    .find((order) => order.id === selectedOrder)
                    ?.orderItems.map(
                      (oi) =>
                        !oi.creditaionStatus && (
                          <option key={oi.id} value={oi.id}>
                            {oi.name}
                          </option>
                        ),
                    )}
                </select>
              </div>
              {selectedProduct && (
                <div>
                  <div>
                    <span>Quantidade </span>
                    <div style={{ marginLeft: '10px' }}>
                      <input
                        type="number"
                        max={
                          orders
                            .find((order) => order.id === selectedOrder)
                            ?.orderItems.find((oi) => oi.id === selectedProduct)
                            ?.amount
                        }
                        defaultValue="0"
                        {...register('creditingAmount')}
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {errors && errors.creditingProduct ? (
            <p>{errors.creditingProduct?.message}</p>
          ) : (
            <p>{errors.creditingAmount?.message}</p>
          )}
          <Button
            type="submit"
            color="#3cf0de"
            bgcolor="#007970"
            bghover="#00665f"
            disabled={isLoading}
          >
            Creditar Valor
          </Button>
        </form>
      )}
    </Container>
  );
};

export default CreditingProduct;
