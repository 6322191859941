import React from 'react';

import { Container } from './styles';

type ModalProps = {
  visible: boolean;
};

const Modal: React.FC<ModalProps> = ({ children, visible }) => {
  return <Container visible={visible}>{children}</Container>;
};

export default Modal;
