/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../../config/api';
import { useToast } from '../../../../hooks/ToastContext';

import { Container, Button } from './styles';
import { MarketSession } from '../../../../types/Household';

type CreditingValueProps = {
  household_id: string;
  updateMartekSession(data: MarketSession): void;
};

const schema = yup.object().shape({
  creditingDescription: yup.string().required('* a descrição é obrigatória'),
  creditingValue: yup
    .number()
    .moreThan(0, 'O valor a creditar tem de ser superior a 0sc')
    .required('* o valor é obrigatório'),
});

const CreditingValue: React.FC<CreditingValueProps> = ({
  household_id,
  updateMartekSession,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    creditingDescription: string;
    creditingValue: number;
  }>({
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (data: {
    creditingDescription: string;
    creditingValue: number;
  }) => {
    setIsLoading(true);

    try {
      const response = await api.put(`market/crediting/${household_id}`, {
        ...data,
        creditingProduct: null,
        creditingAmount: null,
      });

      addToast({
        type: 'success',
        title: 'Creditação',
        description: { code: 770 },
      });

      updateMartekSession(response.data.market_session);

      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na creditação',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div>
          <span>Descrição:</span>
          <textarea
            rows={5}
            {...register('creditingDescription')}
            placeholder="Descrição..."
          />
        </div>
        <div>
          <div>
            <span>Valor a Creditar:</span>
            <div>
              <input
                type="number"
                step="0.01"
                defaultValue="0"
                {...register('creditingValue')}
                placeholder="0"
              />
              <span>sm</span>
            </div>
          </div>
        </div>
        {errors && errors.creditingDescription ? (
          <p>{errors.creditingDescription?.message}</p>
        ) : (
          <p>{errors.creditingValue?.message}</p>
        )}
        <Button
          type="submit"
          color="#3cf0de"
          bgcolor="#007970"
          bghover="#00665f"
          disabled={isLoading}
        >
          Creditar Valor
        </Button>
      </form>
    </Container>
  );
};

export default CreditingValue;
