/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Menu from '../../../components/Menu';
import Input from '../../../components/Input';

import photo from '../../../assets/photo.svg';
import add_photo from '../../../assets/add_photo.svg';
import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';

import {
  Container,
  Widget,
  Photo,
  Form,
  FormLine,
  ButtonBox,
  Button,
} from './styles';
import { User } from '../../../types/Users';

const schema = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  birth_date: yup.string().required('* a data de nascimento é obrigatória'),
  cc_num: yup.string(),
  // .matches(/^[0-9]+$/, '* o número do cartão cidadão tem de ser só dígitos')
  // .min(8, '* o número do cartão cidadão tem no minimo 8 dígitos')
  // .max(8, '* o número do cartão cidadão tem no máximo 8 dígitos'),
  ss_num: yup.string(),
  // .matches(/^[0-9]+$/, '* o número do cartão cidadão tem de ser só dígitos')
  // .min(9, '* o número da seg social tem no minimo 9 dígitos')
  // .max(11, '* o número da seg social tem no máximo 11 dígitos'),
  nif: yup.string(),
  passport_num: yup.string(),
  // .matches(/^[0-9]+$/, '* o número do cartão cidadão tem de ser só dígitos')
  // .min(9, '* o número de contribuinte tem no minimo 9 dígitos')
  // .max(9, '* o número de contribuinte tem no máximo 9 dígitos'),
  address: yup.string(),
  zip: yup.string(),
  // matches(/^[0-9]{4}-[0-9]{3}$/, '* formato 0000-000'),
  location: yup.string(),
  contact: yup.string(),
  // .matches(/^[0-9]+$/, '* o número do cartão cidadão tem de ser só dígitos'),
  email: yup.string(),
  nationality: yup.string(),
});

const Create: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState<File>();
  const [avatarError, setAvatarError] = useState('');
  const [avatarDisplay, setAvatarDisplay] = useState(photo);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Omit<User, 'id' | 'createdAt' | 'updatedAt' | 'deleted'>>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const handleChangeAvatar = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.files) {
      const imageSelected = e.currentTarget.files[0];

      if (imageSelected.size > 2097152) {
        setAvatarError('* image maior que 2MB');
        return;
      }

      if (
        imageSelected.type !== 'image/jpeg' &&
        imageSelected.type !== 'image/png'
      ) {
        setAvatarError('* formato da imagem não suportado (.jpeg / .png)');
        return;
      }
      setAvatarError('');
      setAvatar(imageSelected);
      setAvatarDisplay(URL.createObjectURL(imageSelected));
    }
  };

  const handleOnSubmit = async (
    data: Omit<User, 'id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ) => {
    setIsLoading(true);

    try {
      const response = await api.post('user', data);

      if (response.data.id && avatar) {
        const avatarFormData = new FormData();
        avatarFormData.append('avatar', avatar);
        await api.post(`avatar/${response.data.id}`, avatarFormData);
      }

      addToast({
        type: 'success',
        title: 'Criação de utente',
        description: { code: 208 },
      });
      history.push('utentes');
      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação do utente',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Photo>
            <img src={avatarDisplay} alt="Avatar do Utente" />

            <label htmlFor="avatar">
              <input
                type="file"
                name="avatar"
                id="avatar"
                accept="image/png, image/jpeg"
                onChange={handleChangeAvatar}
              />
              <div>
                <img src={add_photo} alt="Icon adicionar avatar" />
              </div>
            </label>
            <p>{avatarError}</p>
          </Photo>
          <Form id="add_user" onSubmit={handleSubmit(handleOnSubmit)}>
            <FormLine>
              <Input
                width="75%"
                title="Nome"
                type="text"
                {...register('name')}
                error={errors.name?.message}
              />
              <Input
                width="20%"
                title="Data de Nascimento"
                type="date"
                {...register('birth_date')}
                error={errors.birth_date?.message}
              />
            </FormLine>
            <FormLine>
              <Input
                width="23%"
                title="Núm. Cartão Cidadão"
                type="text"
                {...register('cc_num')}
                error={errors.cc_num?.message}
              />
              <Input
                width="23%"
                title="Núm. Seg Social"
                type="text"
                {...register('ss_num')}
                error={errors.ss_num?.message}
              />
              <Input
                width="23%"
                title="Núm. Contribuinte"
                type="text"
                {...register('nif')}
                error={errors.nif?.message}
              />
              <Input
                width="23%"
                title="Núm. Passaporte"
                type="text"
                {...register('passport_num')}
                error={errors.passport_num?.message}
              />
            </FormLine>
            <FormLine>
              <Input
                width="60%"
                title="Morada"
                type="text"
                {...register('address')}
                error={errors.address?.message}
              />
              <Input
                width="15%"
                title="Código Postal"
                type="text"
                {...register('zip')}
                error={errors.zip?.message}
              />
              <Input
                width="20%"
                title="Localidade"
                type="text"
                {...register('location')}
                error={errors.location?.message}
              />
            </FormLine>
            <FormLine>
              <Input
                width="40%"
                title="E-mail"
                type="text"
                {...register('email')}
                error={errors.email?.message}
              />
              <Input
                width="32%"
                title="Contacto"
                type="text"
                {...register('contact')}
                error={errors.contact?.message}
              />
              <Input
                width="18%"
                title="Nacionalidade"
                textAlign="center"
                type="text"
                {...register('nationality')}
                error={errors.nationality?.message}
              />
              {/* <Controller
                control={control}
                name="nationality"
                render={({ field: { onChange, value } }) => {
                  return (
                    <SelectInput
                      title="Nacionalidade"
                      {...register('nationality')}
                      onChange={onChange}
                      value={value || 'pt'}
                      width="30%"
                      error={errors.nationality?.message}
                      options={[
                        { value: 'pt', label: 'Portuguesa' },
                        { value: 'uk', label: 'Ucraniana' },
                      ]}
                    />
                  );
                }}
              /> */}
            </FormLine>
          </Form>
        </Widget>
        <ButtonBox>
          <Button
            type="button"
            color="#007970"
            bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
            bghover="#10d5c2"
            onClick={handleBack}
            disabled={isLoading}
          >
            <img src={chevron} alt="Icon de seta back" />
            Voltar
          </Button>
          {isLoading ? (
            <Button
              form="add_user"
              color="#3cf0de"
              bgcolor={isLoading ? '#00665f' : '#007970'}
              bghover="#00665f"
              disabled={isLoading}
            >
              <img src={loading} alt="Icon de desquete save" />a carregar...
            </Button>
          ) : (
            <Button
              form="add_user"
              type="submit"
              color="#3cf0de"
              bgcolor="#007970"
              bghover="#00665f"
            >
              <img src={save} alt="Icon de desquete save" />
              Guardar
            </Button>
          )}
        </ButtonBox>
      </Container>
    </>
  );
};

export default Create;
