/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { ModalBoxConfirm, ButtonConfirm } from './styles';

type ModalRemoveMemberProps = {
  memberName: string;
  handleDeleteMember(data: boolean): void;
};

const HouseholdModalRemoveMember: React.FC<ModalRemoveMemberProps> = ({
  memberName,
  handleDeleteMember,
}) => {
  return (
    <ModalBoxConfirm>
      <h1>
        Quer remover o utente <span>{memberName}</span> deste agregado?
      </h1>
      <div>
        <ButtonConfirm
          bgColor="#007970"
          bgHoverColor="#00665f"
          type="button"
          onClick={() => handleDeleteMember(true)}
        >
          Sim
        </ButtonConfirm>
        <ButtonConfirm
          bgColor="#ff8080"
          bgHoverColor="#ff4d4d"
          type="button"
          onClick={() => handleDeleteMember(false)}
        >
          Não
        </ButtonConfirm>
      </div>
    </ModalBoxConfirm>
  );
};

export default HouseholdModalRemoveMember;
