/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import Menu from '../../components/Menu';
import Input from '../../components/Input';

import api from '../../config/api';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import { Container, Widget, Confirm } from './styles';

const schema = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  username: yup.string().required('* o utilizador é obrigatório'),
  email: yup
    .string()
    .email('* formato de e-mail errado')
    .required('* o e-mail é obrigatório'),
  password: yup.string(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'As palavras-passe não são iguais'),
});

type AccountType = {
  name: string;
  email: string;
  username: string;
  password?: string;
  passwordConfirm?: string;
};

const Account: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AccountType>({
    resolver: yupResolver(schema),
  });

  const { account, updateAccountData } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const handleOnSubmit = async (data: AccountType) => {
    setIsLoading(true);
    try {
      let response: AxiosResponse<any>;

      if (data.password && data.passwordConfirm) {
        response = await api.put(`account/${account?.id}`, data);
      } else {
        response = await api.put(`account/${account?.id}`, {
          name: data.name,
          username: data.username,
          email: data.email,
        });
      }

      updateAccountData(response.data);

      addToast({
        type: 'success',
        title: 'Atualização de dados',
        description: { code: 107 },
      });
      setIsLoading(false);
      history.push('utentes');
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na atualização de dados',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Menu />
      <Container>
        <Widget onSubmit={handleSubmit(handleOnSubmit)}>
          <h1>Atualizar dados da conta</h1>
          <div>
            <Input
              width="100%"
              title="Nome"
              defaultValue={account?.name}
              type="text"
              {...register('name')}
              error={errors.name?.message}
            />
          </div>
          <div>
            <Input
              width="100%"
              title="Utilizador"
              defaultValue={account?.username}
              type="text"
              {...register('username')}
              error={errors.username?.message}
            />
          </div>
          <div>
            <Input
              width="100%"
              title="E-mail"
              defaultValue={account?.email}
              type="text"
              {...register('email')}
              error={errors.email?.message}
            />
          </div>
          <hr />
          <div>
            <Input
              width="100%"
              title="Palavra-passe"
              type="password"
              {...register('password')}
              error={errors.password?.message}
            />
          </div>
          <div>
            <Input
              width="100%"
              title="Confirmar Palavra-passe"
              type="password"
              {...register('passwordConfirm')}
              error={errors.passwordConfirm?.message}
            />
          </div>
          {isLoading ? (
            <Confirm type="submit" disabled={isLoading}>
              A carregar...
            </Confirm>
          ) : (
            <Confirm type="submit">Guardar</Confirm>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default Account;
