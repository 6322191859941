import { useEffect } from 'react';

import { ToastMessage, useToast } from '../../../hooks/ToastContext';

import { Container, Head, Body } from './styles';

// import info from '../../../assets/icons/info.svg';
// import error from '../../../assets/icons/error.svg';
// import success from '../../../assets/icons/success.svg';

interface ToastProps {
  message: ToastMessage;
}

const Toast: React.FC<ToastProps> = ({ message }) => {
  const { removeToast } = useToast();

  // const icons = {
  //   info,
  //   error,
  //   success,
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <Container type={message.type}>
      <Head>
        {/* <img
          src={icons[message.type || 'info']}
          alt="Imagem com icon da mensagem"
        /> */}
        <span>{message.title}</span>
      </Head>
      <Body>
        <p>{message.description}</p>
      </Body>
    </Container>
  );
};

export default Toast;
