import styled from 'styled-components';

type ButtonColors = {
  bgColor: string;
  bgHoverColor: string;
};

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 3rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const Button = styled.button`
  color: #3cf0de;
  background: #007970;
  width: 100%;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: #00665f;
  }
`;

export const Notes = styled.div`
  padding-top: 3rem;

  > div {
    display: flex;

    button {
      margin-left: 1rem;
      border: 0px;
      background-color: #007970;
      padding: 1rem;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #00665f;
      }

      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007970;
    padding-bottom: 1rem;
  }

  ul,
  li {
    list-style: none;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li div {
    position: relative;
    text-decoration: none;
    color: #007970;
    background: #3cf0de;
    display: block;
    min-height: 10em;
    width: 10em;
    padding: 1em;
    overflow-wrap: break-word;
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    transform: rotate(-6deg);
    transition: transform 0.15s linear;

    button {
      position: absolute;
      background: transparent;
      border: none;
      font-size: 2rem;
      color: #00b3a7;
      top: 0;
      right: 0;
      margin-right: 0.2rem;

      &:hover {
        color: #007970;
      }
    }
  }

  ul li:nth-child(even) div {
    transform: rotate(4deg);
    position: relative;
    top: 5px;
  }
  ul li:nth-child(3n) div {
    transform: rotate(-3deg);
    position: relative;
    top: -5px;
  }
  ul li:nth-child(5n) div {
    transform: rotate(5deg);
    position: relative;
    top: -10px;
  }

  ul li div:hover,
  ul li div:focus {
    box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
    transform: scale(1.25);
    position: relative;
    z-index: 5;
  }

  ul li {
    margin: 1em;
  }
`;

export const ModalAddNote = styled.div`
  margin-top: 10rem;
  position: relative;
  text-decoration: none;
  color: #007970;
  background: #3cf0de;
  height: 25em;
  width: 25em;
  padding: 1em;
  overflow-wrap: break-word;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);

  > form {
    margin-top: 4rem;
    width: 100%;
    height: 100%;

    > div {
      > textarea {
        min-height: 20rem;
        background: #3cf0de;
      }
    }
  }
`;

export const ModalBoxConfirm = styled.div`
  position: relative;
  background: #fff;
  width: 30rem;
  height: 18rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  h1 {
    width: 100%;
    text-align: left;
    font-size: 2rem;
    font-weight: normal;

    span {
      font-weight: bold;
    }
  }

  div {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ButtonConfirm = styled.button<ButtonColors>`
  color: #3cf0de;
  font-size: 2.2rem;
  padding: 0.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 5px;
  border: none;
  background: ${(props) => props.bgColor};

  &:hover {
    background: ${(props) => props.bgHoverColor};
  }
`;
