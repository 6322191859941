import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 9rem;
  max-height: 9rem;
  border-radius: 1rem;
  background-color: rgb(60, 240, 222);
  margin-bottom: 2rem;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);

  h1 {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  > div {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
`;

export const Squad = styled.div`
  width: auto;
  height: 6rem;
  max-height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
`;

export const Box = styled.div`
  max-height: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
  }

  > button {
    margin-left: 5px;
    background-color: #3cf0de;
    border: 0px;
    opacity: 0.6;
    transition: 0.2s opacity;

    &:hover {
      opacity: 1;
    }

    img {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
`;
