import styled, { css } from 'styled-components';

interface ToastProps {
  type?: 'info' | 'success' | 'error';
}

const toastTypeVariations = {
  info: css`
    background: #ff9999;
  `,
  success: css`
    background: #009973;
  `,
  error: css`
    background: #ff5050;
  `,
};

export const Container = styled.div<ToastProps>`
  width: 60rem;
  max-width: 60rem;
  ${(props) => toastTypeVariations[props.type || 'info']};
  color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 5px 5px 5px 0px rgba(15, 15, 15, 0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(15, 15, 15, 0.75);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(15, 15, 15, 0.75);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;

  span {
    font-size: 1.6rem;
    font-weight: bold;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;

  p {
    font-size: 1.4rem;
  }
`;
