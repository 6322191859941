/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';

import { Modal, ButtonBox, Button, Title, MainButton } from './styles';
import ModalContainer from '../../Modal';
import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import acceptall from '../../../assets/acceptall.svg';

const ButtonAcceptAll: React.FC<{
  sessionType: number;
}> = ({ sessionType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleConfirm = useCallback(
    async (st: number) => {
      setIsLoading(true);
      try {
        await api.put(`orders/accept-all/${st}`);

        addToast({
          type: 'success',
          title: 'Encomendas Aceites',
          description: { code: 761 },
        });
        setIsLoading(false);
        handleClose();
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Erro ao processar encomenda',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
        setIsLoading(false);
      }
    },
    [addToast, handleClose],
  );

  return (
    <>
      <MainButton
        type="button"
        color="#007970"
        bgcolor={isLoading ? '#ffffc6' : '#ffffe6'}
        bghover="#ffffc6"
        onClick={() => setIsOpen(true)}
        disabled={isLoading}
      >
        <img src={acceptall} alt="certo" />
        Aceitar pendentes
      </MainButton>
      {isOpen && (
        <ModalContainer visible={isOpen}>
          <Modal>
            <Title> Quer aceitar todas as encomendas?</Title>
            <p>
              Nota: Uma vez aceites, as encomendas não podem ser canceladas.
              Pedimos que esteja atento(a) caso exista alguma irregularidade com
              as encomendas pendentes.
            </p>
            <ButtonBox>
              <Button
                type="button"
                color="#3cf0de"
                bgcolor="#ff9999"
                bghover="#ff6666"
                onClick={handleClose}
                disabled={isLoading}
              >
                Não
              </Button>
              <Button
                type="button"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
                onClick={() => handleConfirm(sessionType)}
                disabled={isLoading}
              >
                Sim
              </Button>
            </ButtonBox>
          </Modal>
        </ModalContainer>
      )}
    </>
  );
};

export default ButtonAcceptAll;
