import styled from 'styled-components';

type ModalProps = {
  visible: boolean;
};

type ThProps = {
  width: string;
  talign: string;
};

export const Container = styled.div<ModalProps>`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 140rem;
  max-width: 140rem;
  height: 70rem;
  margin-top: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  padding: 2rem;
  flex-direction: column;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TopTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  img {
    width: 2.8rem;
    height: 1.8rem;
    margin-left: 1rem;
  }
`;

export const TopInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const TopInfoBox = styled.div`
  padding: 1rem;
  background: #88f6eb;
  border-radius: 1rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TopInfoBoxTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export const TopInfoBoxText = styled.p`
  font-size: 18px;
`;

export const TableTurns = styled.div`
  width: 100%;
`;

export const TableTurnsTitle = styled.h1`
  font-size: 24px;
  margin-top: 2rem;
  display: flex;
  align-items: center;

  button {
    margin-left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 2.2rem;
      height: 2.2rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const TableTurnsBody = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 47rem;

  box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);
  -moz-box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);
  -webkit-box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);

  thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const TableBodyTh = styled.th<ThProps>`
  width: ${(props) => props.width};
  font-size: 1.4rem;
  font-weight: bold;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

export const TableBodyTd = styled.td<ThProps>`
  width: ${(props) => props.width};
  font-size: 1.4rem;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #3cf0de;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;

      img {
        width: 20px;
        height: 20px;
      }

      & + button {
        margin-left: 0.5rem;
      }
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const ImgGroup = styled.div``;
