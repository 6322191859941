/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Select from '../../Select';

import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';

import {
  Container,
  Widget,
  Form,
  FormLine,
  ButtonBox,
  Button,
  ItemsBox,
  FlexBox,
  RowButton,
  RowButtonsBox,
} from './styles';

import Modal from '../../Modal';
import { DefaultOrder } from '../../../types/DefaultOrder';
import { Household } from '../../../types/Household';

const schema = yup.object().shape({
  householders: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required('* agregado obrigatório'),
      }),
    )
    .required('* agregados obrigatórios'),
});

type CreateDefaultOrderHouseholdProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultOrder: DefaultOrder;
  handleEditOrder: (data: DefaultOrder) => void;
};

type HouseholdForm = {
  householders: {
    id: string;
  }[];
};

const CreateDefaultOrderHousehold: React.FC<CreateDefaultOrderHouseholdProps> =
  ({ isOpen, onClose, defaultOrder, handleEditOrder }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [householders, setHouseholders] = useState<Household[]>([]);

    const [rows, setRows] = useState([1]);

    const {
      handleSubmit,
      register,
      setValue,
      getValues,
      reset,
      formState: { errors },
      control,
    } = useForm<HouseholdForm>({
      resolver: yupResolver(schema),
    });

    const { addToast } = useToast();

    const handleResetForm = useCallback(() => {
      reset();
      onClose();
      setRows([1]);
    }, [reset]);

    const handleOnSubmit = async (data: HouseholdForm) => {
      setIsLoading(true);

      try {
        const response = await api.post(`/market/default-order/household`, {
          id: defaultOrder.id,
          ...data,
        });

        handleEditOrder(response.data);

        addToast({
          type: 'success',
          title: 'Encomenda tipo atualizada com sucesso.',
          description: { code: 731 },
        });
        setIsLoading(false);
        handleResetForm();
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar a encomenda tipo.',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
        setIsLoading(false);
      }
    };

    const getHouseholders = useCallback(async () => {
      try {
        const response = await api.get('household');
        setHouseholders(
          response.data.filter((h: Household) => h.market_session),
        );
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter do agregados',
          description: { code: 300 },
        });
      }
    }, [addToast]);

    const handleRow = useCallback(
      (value: number, r: number) => {
        const calc = value + r;

        if (calc > 0) {
          setRows((prevState) =>
            value > 0
              ? [...prevState, calc]
              : prevState.filter((v) => v <= calc),
          );

          const items = getValues('householders');
          setValue(
            'householders',
            items.filter((v, index) => index + 1 <= calc),
          );
        }
      },
      [getValues, setValue],
    );

    useEffect(() => {
      getHouseholders();
    }, [getHouseholders]);

    useEffect(() => {
      if (
        defaultOrder &&
        defaultOrder.defaultOrderHousehold &&
        defaultOrder.defaultOrderHousehold.length > 0 &&
        householders.length > 0
      ) {
        const r: number[] = [];
        defaultOrder.defaultOrderHousehold.forEach((h, index) => {
          setValue(`householders.${index}.id`, h.householdId);
          r.push(index + 1);
        });
        setRows(r);
      }
    }, [defaultOrder, householders]);

    return (
      <Modal visible={isOpen}>
        <Container>
          <h1>Agregado da encomenda tipo</h1>
          <Widget>
            <Form
              id="add_defaultOrderHousehold"
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <FormLine>
                <ItemsBox>
                  {rows.length > 0 &&
                    rows.map((v) => (
                      <FlexBox key={`householders-${v}`}>
                        <Controller
                          control={control}
                          name={`householders.${v - 1}.id`}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              title="Agregado"
                              {...register(`householders.${v - 1}.id`)}
                              onChange={onChange}
                              value={value}
                              width="100%"
                              error={errors.householders?.[v - 1]?.id?.message}
                              options={householders.map((h) => ({
                                label: h.name,
                                value: h.id,
                              }))}
                            />
                          )}
                        />
                      </FlexBox>
                    ))}
                </ItemsBox>
              </FormLine>
              <FormLine>
                <RowButtonsBox>
                  <RowButton
                    type="button"
                    title="Adicionar Linha"
                    onClick={() => handleRow(+1, rows.length)}
                  >
                    <p>+</p>
                  </RowButton>
                  <RowButton
                    type="button"
                    title="Remover Linha"
                    onClick={() => handleRow(-1, rows.length)}
                  >
                    <p>-</p>
                  </RowButton>
                </RowButtonsBox>
              </FormLine>
            </Form>
          </Widget>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              <img src={chevron} alt="Icon de seta back" />
              Voltar
            </Button>
            {isLoading ? (
              <Button
                form="add_defaultOrderHousehold"
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                form="add_defaultOrderHousehold"
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
              >
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            )}
          </ButtonBox>
        </Container>
      </Modal>
    );
  };

export default CreateDefaultOrderHousehold;
