/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect } from 'react';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import { Container, Widget, ButtonBox, Button } from './styles';

type ProductStatusProps = {
  id: string;
  close(): void;
  handleUpdateProduct(id: string, status: 'update' | 'add'): void;
  productName: string;
  availableOnMarket: boolean;
};

const ProductStatus: React.FC<ProductStatusProps> = ({
  id,
  close,
  handleUpdateProduct,
  productName,
  availableOnMarket,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productNameToDisplay, setProductNameToDisplay] = useState('');
  const [status, setStatus] = useState(false);

  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    setProductNameToDisplay('');
    close();
  }, []);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      if (id) {
        const response = await api.put(`market/product/status/${id}`, {
          availableOnMarket: !status,
        });

        if (response.data) {
          addToast({
            type: 'success',
            title: 'Estado do Produto no Mercado',
            description: { code: 502 },
          });
          setIsLoading(false);
          handleUpdateProduct(response.data.id, 'update');
          handleClose();
        }
      }
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro Estado do Produto no Mercado',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setProductNameToDisplay(productName);
      setStatus(availableOnMarket);
    }
  }, [productName, id, availableOnMarket]);

  return (
    <Container>
      <h1>
        {availableOnMarket ? 'Remover do mercado!' : 'Adicionar ao Mercado!'}
      </h1>
      <Widget>
        {availableOnMarket ? (
          <p>
            Quer remover o produto <strong>{productNameToDisplay}</strong> do
            mercado?
          </p>
        ) : (
          <p>
            Quer adicionar o produto <strong>{productNameToDisplay}</strong> ao
            mercado?
          </p>
        )}
      </Widget>
      <ButtonBox>
        <Button
          type="button"
          color="#007970"
          bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
          bghover="#10d5c2"
          onClick={close}
          disabled={isLoading}
        >
          Voltar
        </Button>
        {isLoading ? (
          <Button
            form="stock_product"
            color="#3cf0de"
            type="button"
            bgcolor={isLoading ? '#00665f' : '#007970'}
            bghover="#00665f"
            disabled={isLoading}
          >
            A carregar...
          </Button>
        ) : (
          <Button
            form="stock_product"
            type="button"
            color="#3cf0de"
            bgcolor="#007970"
            bghover="#00665f"
            onClick={handleOnSubmit}
          >
            {availableOnMarket ? 'Remover' : 'Adicionar'}
          </Button>
        )}
      </ButtonBox>
    </Container>
  );
};

export default ProductStatus;
