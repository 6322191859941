import styled from 'styled-components';

type SelectProps = {
  isError: boolean;
  width?: string;
  borderType?: boolean;
};

export const SelectDiv = styled.div<SelectProps>`
  position: relative;
  width: ${(props) => (props.width ? props.width : '100%')};
  ${(props) =>
    props.borderType
      ? `border-bottom: 2px solid ${props.isError ? '#ff5050' : '#007970'};`
      : `border: 2px solid ${props.isError ? '#ff5050' : '#007970'};`}
  border-radius: 2px;
  background: transparent;

  span {
    z-index: 2;
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    top: -1.3rem;
    left: 0.2rem;
    background: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  div:nth-child(3) {
    z-index: 3;
  }

  p {
    position: absolute;
    color: #ff5050;
    font-size: 9px;
    bottom: 0;
    left: 1rem;
  }
`;
