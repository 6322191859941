import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.div`
  width: 140rem;
  max-width: 140rem;
`;

export const Top = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 3rem;
    font-weight: bold;
  }

  button {
    margin-left: 1.5rem;
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;

  input {
    border: 2px solid #007970;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 30rem;
    color: #007970;
    font-size: 1.6rem;
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  div {
    margin-left: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;

export const TableProducts = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 62rem;

  box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);
  -moz-box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);
  -webkit-box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);

  thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
`;
