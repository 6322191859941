/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { Container } from './styles';

import reset_password from '../../../assets/reset_password.svg';
import { MarketSession } from '../../../types/Household';
import api from '../../../config/api';
import Modal from '../../Modal';
import ModalMessage from '../ModalMessage';
import { useToast } from '../../../hooks/ToastContext';

type PasswordMarketProps = {
  setMarketSession: React.Dispatch<React.SetStateAction<MarketSession>>;
  passwordProp?: string | null;
  household_id: string;
};

const PasswordMarket: React.FC<PasswordMarketProps> = ({
  setMarketSession,
  passwordProp = null,
  household_id,
}) => {
  const [display, setDisplay] = useState(false);

  const { addToast } = useToast();

  const handleResetPassword = async () => {
    try {
      const response = await api.put('/market/session/password', {
        household_id,
      });

      setMarketSession((PrevState) => ({
        ...PrevState,
        password: response.data.password,
      }));

      setDisplay(false);

      addToast({
        type: 'success',
        title: 'Alteração!',
        description: { code: 703 },
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação de credencias!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  return (
    <>
      <Container>
        <span>{passwordProp}</span>
        <button
          type="button"
          title="gerar nova palavra-passe"
          onClick={() => setDisplay(true)}
        >
          <img src={reset_password} alt="reset_password" />
        </button>
      </Container>

      <Modal visible={display}>
        <ModalMessage
          closeModal={() => setDisplay(false)}
          handleYes={handleResetPassword}
          message="Quer gerar nova palavra-passe de acesso ao mercado?"
        />
      </Modal>
    </>
  );
};

export default PasswordMarket;
