/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Menu from '../../../components/Menu';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

import {
  Container,
  Widget,
  Top,
  Title,
  Grid,
  ModalBox,
  Close,
  Button,
} from './styles';

import photo from '../../../assets/photo.svg';
import add from '../../../assets/add.svg';
import save from '../../../assets/save.svg';
import add_session from '../../../assets/add_session.svg';
import food_support from '../../../assets/food_support.svg';

import { Household } from '../../../types/Household';

import { User } from '../../../types/Users';
import BackButton from '../../../components/BackButton';
import HouseHoldCard from '../../../components/HouseHolderCard';
import HouseholdModalRemoveMember from '../../../components/HouseholdModalRemoveMember';
import HouseholdModalCredential from '../../../components/HouseholdModalCredential';
import PanelMarkerSession from '../../../components/PanelMarketSession';
import HouseholdDates from '../../../components/HouseholdDates';

const schema = yup.object().shape({
  user: yup.string().required('* o membro é obrigatório'),
  kinship: yup.string().required('* o parentesco é obrigatório'),
});

type LocationProps = {
  id: string;
};

type MemberFormData = {
  user: string;
  kinship: string;
};

const ViewHousehold: React.FC = () => {
  const [householdData, setHouseholdData] = useState({} as Household);
  const [optionUsers, setOptionUsers] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalConfirm, setDisplayModalConfirm] = useState(false);
  const [displayModalCredential, setDisplayModalCredential] = useState(false);
  const [idMemberToDelete, setIdMemberToDelete] = useState('');
  const [NameMemberToDelete, setNameMemberToDelete] = useState('');
  const [avatar, setAvatar] = useState(photo);
  const location = useLocation<LocationProps>();
  const { addToast } = useToast();

  const id_household = location.state ? location.state.id : null;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm<MemberFormData>({
    resolver: yupResolver(schema),
  });

  const getHouseholdData = useCallback(async () => {
    try {
      const response = await api.get(`household/${id_household}`);

      setHouseholdData(response.data);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter agregados',
        description: { code: 300 },
      });
    }
  }, [id_household, addToast]);

  const getUserAvatar = async (id: string) => {
    try {
      const response = await api.get(`user/${id}`);

      if (response.data.avatar) {
        const responseAvatar = await api.get(response.data.avatar.path, {
          responseType: 'blob',
        });

        setAvatar(URL.createObjectURL(responseAvatar.data));
      } else {
        setAvatar(photo);
      }
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados do utente',
        description: { code: 207 },
      });
    }
  };

  const getUserOptions = useCallback(async () => {
    try {
      const response = await api.get('user');

      const userOption = response.data.map((user: User) => {
        return {
          value: user.id,
          label: user.name,
        };
      });

      setOptionUsers(userOption);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados do utente',
        description: { code: 206 },
      });
    }
  }, [addToast]);

  const closeModal = useCallback(() => {
    reset({ user: '', kinship: '' });
    setAvatar(photo);
    setDisplayModal(false);
  }, [reset]);

  const handleOnSave = async (data: MemberFormData) => {
    try {
      const response = await api.post(
        `household-members/${id_household}`,
        data,
      );
      if (response.data) {
        closeModal();
        getHouseholdData();
        addToast({
          type: 'success',
          title: 'Adição de membro ao agregado',
          description: { code: 310 },
        });
      }
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na adição do utente',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  const handleModalConfirm = (id: string, name: string) => {
    setIdMemberToDelete(id);
    setNameMemberToDelete(name);
    setDisplayModalConfirm(true);
  };

  const handleDeleteMember = async (resp: boolean) => {
    if (resp) {
      try {
        const response = await api.delete(
          `household-members/${idMemberToDelete}`,
        );
        if (response.data) {
          setIdMemberToDelete('');
          setNameMemberToDelete('');
          setDisplayModalConfirm(false);
          setHouseholdData((prevState) => ({
            ...prevState,
            household_members: prevState.household_members?.filter((member) => {
              return member.id !== idMemberToDelete;
            }),
          }));
          addToast({
            type: 'success',
            title: 'Remoção de membro ao agregado',
            description: { code: 311 },
          });
        }
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Erro na remoção do utente',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
      }
    } else {
      setIdMemberToDelete('');
      setNameMemberToDelete('');
      setDisplayModalConfirm(false);
    }
  };

  const handleCreateCredentials = async () => {
    try {
      const response = await api.post('market/session', {
        household_id: householdData.id,
      });

      setHouseholdData((prevState) => ({
        ...prevState,
        market_session: response.data,
      }));

      addToast({
        type: 'success',
        title: 'Acesso criado!',
        description: { code: 700 },
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação de credencias!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  const handleUpdateDataHousehold = useCallback((data: Household) => {
    setHouseholdData((prevState) => ({ ...prevState, ...data }));
  }, []);

  useEffect(() => {
    getUserOptions();
    getHouseholdData();
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <BackButton />
        <Widget>
          <Top>
            <Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h1>
                  Família {householdData.name} - {householdData.int_num}{' '}
                </h1>
                {householdData.food_support && (
                  <img
                    width="30px"
                    height="30px"
                    src={food_support}
                    alt="Apoio Alimentar"
                  />
                )}
              </div>
              <HouseholdDates
                handleUpdateDataHousehold={handleUpdateDataHousehold}
                id={householdData.id}
                in_date={householdData.in_date}
                out_date={householdData.out_date}
              />
            </Title>
            {!householdData.market_session && (
              <button
                type="button"
                onClick={() => setDisplayModalCredential(true)}
              >
                <img src={add_session} alt="Imagem de uma loja com um mais" />
                Criar acesso mercearia
              </button>
            )}
            <button type="button" onClick={() => setDisplayModal(true)}>
              <img src={add} alt="Imagem de um mais" />
              Adicionar membro
            </button>
          </Top>
          {householdData.market_session && (
            <PanelMarkerSession
              marketSession={householdData.market_session}
              householdName={householdData.name}
            />
          )}
          <Grid>
            {householdData.user && (
              <HouseHoldCard
                user={householdData.user}
                kinship={householdData.kinship}
                owner
              />
            )}
            {householdData.household_members &&
              householdData.household_members.length > 0 &&
              householdData.household_members.map((member) => (
                <HouseHoldCard
                  key={member.id}
                  user={member.user}
                  kinship={member.kinship}
                  handleModalConfirm={() =>
                    handleModalConfirm(member.id, member.user.name)
                  }
                />
              ))}
          </Grid>
        </Widget>
        <Modal visible={displayModal}>
          <ModalBox>
            <Close>
              <button type="button" onClick={closeModal}>
                &#x2715;
              </button>
            </Close>
            <img src={avatar} alt="Imagem de membro" />
            <form onSubmit={handleSubmit(handleOnSave)}>
              <Controller
                control={control}
                name="user"
                render={({ field: { onChange, value } }) => {
                  if (value) {
                    getUserAvatar(value);
                  }
                  return (
                    <Select
                      title="Membro"
                      {...register('user')}
                      onChange={onChange}
                      value={value}
                      error={errors.user?.message}
                      options={optionUsers}
                    />
                  );
                }}
              />
              <Input
                width="100%"
                title="Parentesco"
                type="text"
                {...register('kinship')}
                error={errors.kinship?.message}
              />
              <Button type="submit">
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            </form>
          </ModalBox>
        </Modal>
        <Modal visible={displayModalConfirm}>
          <HouseholdModalRemoveMember
            handleDeleteMember={handleDeleteMember}
            memberName={NameMemberToDelete}
          />
        </Modal>
        <Modal visible={displayModalCredential}>
          <HouseholdModalCredential
            handleCreateCredentials={handleCreateCredentials}
            closeModal={() => setDisplayModalCredential(false)}
          />
        </Modal>
      </Container>
    </>
  );
};

export default ViewHousehold;
