import styled from 'styled-components';

type Input = {
  width: string;
  isError: boolean;
  backgoundColorStatus?: boolean;
};

export const Container = styled.div<Input>`
  position: relative;
  width: ${(props) => props.width};
  border-radius: 2px;
  background: #007970;

  span {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    ${(props) => props.backgoundColorStatus && `background-color: #fff;`}
    top: -1.3rem;
    left: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  textarea {
    width: 100%;
    min-height: 15rem;
    padding: 0.9rem;
    color: #007970;
    font-size: 1.8rem;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    resize: none;
    -webkit-box-shadow: inset 0px 0px 2px 2px rgba(0, 121, 112, 0.51);
    box-shadow: inset 0px 0px 2px 2px rgba(0, 121, 112, 0.51);
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  p {
    position: absolute;
    color: #ff5050;
    font-size: 9px;
    bottom: 0;
    left: 1rem;
  }
`;
