import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Modal = styled.div`
  position: relative;
  width: 60rem;
  max-width: 60rem;
  max-height: 22rem;
  margin-top: 10rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  padding: 5rem;
  flex-direction: column;
  p {
    margin-top: 1rem;
    font-size: 1.4rem;
    width: 96%;
    margin-left: 2%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
`;

export const ButtonBox = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainButton = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 15rem;
  padding: 0.7rem;
  font-size: 1.2rem;
  margin-left: 20px;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 15rem;
  padding: 0.7rem;
  font-size: 2rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;
