/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Input from '../../Input';
import Select from '../../Select';

import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';
import add from '../../../assets/add.svg';

import {
  Container,
  Widget,
  Form,
  FormLine,
  ButtonBox,
  Button,
  AddButton,
  ItemsBox,
  FlexBox,
  RowButton,
  RowButtonsBox,
} from './styles';

import Modal from '../../Modal';
import { Product } from '../../../types/Product';
import { DefaultOrder } from '../../../types/DefaultOrder';

const schema = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  items: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required('* produto obrigatório'),
        amount: yup
          .number()
          .integer()
          .moreThan(0)
          .required('* quantidade obrigatória'),
      }),
    )
    .required('* produtos obrigatórios'),
});

type CreateDefaultOrderProps = {
  handleAddDefaultOrder: (data: DefaultOrder) => void;
};

type OrderForm = {
  name: string;
  items: {
    id: string;
    amount: number;
  }[];
};

const CreateDefaultOrder: React.FC<CreateDefaultOrderProps> = ({
  handleAddDefaultOrder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  const [rows, setRows] = useState([1]);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
    control,
  } = useForm<OrderForm>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const handleResetForm = useCallback(() => {
    reset();
    setIsOpen(false);
    setRows([1]);
  }, [reset]);

  const handleOnSubmit = async (data: OrderForm) => {
    setIsLoading(true);

    try {
      const response = await api.post('/market/default-order', data);

      handleAddDefaultOrder(response.data);

      addToast({
        type: 'success',
        title: 'Encomenda tipo criada com sucesso.',
        description: { code: 730 },
      });
      setIsLoading(false);
      handleResetForm();
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação da encomenda tipo.',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  const getProducts = useCallback(async () => {
    try {
      const response = await api.get('/market/product');
      setProducts(response.data.filter((p: Product) => p.availableOnMarket));
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter do produtos',
        description: { code: 506 },
      });
    }
  }, [addToast]);

  const handleRow = useCallback(
    (value: number, r: number) => {
      const calc = value + r;

      if (calc > 0) {
        setRows((prevState) =>
          value > 0 ? [...prevState, calc] : prevState.filter((v) => v <= calc),
        );

        const items = getValues('items');
        setValue(
          'items',
          items.filter((v, index) => index + 1 <= calc),
        );
      }
    },
    [getValues, setValue],
  );

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <>
      <AddButton
        type="button"
        title="Adicionar Produto"
        onClick={() => setIsOpen(true)}
      >
        <img src={add} alt="plus" />
      </AddButton>
      <Modal visible={isOpen}>
        <Container>
          <h1>Nova encomenda tipo</h1>
          <Widget>
            <Form id="add_defaultOrder" onSubmit={handleSubmit(handleOnSubmit)}>
              <FormLine>
                <Input
                  width="100%"
                  title="Nome da encomenda tipo"
                  type="text"
                  {...register('name')}
                  error={errors.name?.message}
                />
              </FormLine>
              <FormLine>
                <ItemsBox>
                  {rows.length > 0 &&
                    rows.map((v) => (
                      <FlexBox>
                        <Controller
                          control={control}
                          name={`items.${v - 1}.id`}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              title="Produto"
                              {...register(`items.${v - 1}.id`)}
                              onChange={onChange}
                              value={value}
                              width="83%"
                              error={errors.items?.[v - 1]?.id?.message}
                              options={products.map((p) => ({
                                label: p.name,
                                value: p.id,
                              }))}
                            />
                          )}
                        />
                        <Input
                          width="15%"
                          title="Qnt"
                          type="number"
                          placeholder="0"
                          defaultValue={1}
                          textAlign="center"
                          {...register(`items.${v - 1}.amount`)}
                          error={errors.items?.[v - 1]?.amount?.message}
                        />
                      </FlexBox>
                    ))}
                </ItemsBox>
              </FormLine>
              <FormLine>
                <RowButtonsBox>
                  <RowButton
                    type="button"
                    title="Adicionar Linha"
                    onClick={() => handleRow(+1, rows.length)}
                  >
                    <p>+</p>
                  </RowButton>
                  <RowButton
                    type="button"
                    title="Remover Linha"
                    onClick={() => handleRow(-1, rows.length)}
                  >
                    <p>-</p>
                  </RowButton>
                </RowButtonsBox>
              </FormLine>
            </Form>
          </Widget>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              <img src={chevron} alt="Icon de seta back" />
              Voltar
            </Button>
            {isLoading ? (
              <Button
                form="add_defaultOrder"
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                form="add_defaultOrder"
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
              >
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            )}
          </ButtonBox>
        </Container>
      </Modal>
    </>
  );
};

export default CreateDefaultOrder;
