/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { ModalBoxConfirm, ButtonConfirm } from './styles';

type ModalCredentialProps = {
  handleCreateCredentials(): void;
  closeModal(): void;
};

const HouseholdModalCredential: React.FC<ModalCredentialProps> = ({
  handleCreateCredentials,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCreate = () => {
    setLoading(true);
    handleCreateCredentials();
    closeModal();
    setLoading(false);
  };

  return (
    <ModalBoxConfirm>
      <h1>Quer criar o acesso para a mercearia a este agregado?</h1>
      <div>
        <ButtonConfirm
          bgColor="#007970"
          bgHoverColor="#00665f"
          type="button"
          onClick={handleCreate}
          disabled={loading}
        >
          Sim
        </ButtonConfirm>
        <ButtonConfirm
          bgColor="#ff8080"
          bgHoverColor="#ff4d4d"
          type="button"
          onClick={closeModal}
          disabled={loading}
        >
          Não
        </ButtonConfirm>
      </div>
    </ModalBoxConfirm>
  );
};

export default HouseholdModalCredential;
