import styled from 'styled-components';

type ModalProps = {
  visible: boolean;
};

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

type ThProps = {
  width: string;
  talign: string;
};

type TdProps = {
  width: string;
  talign: string;
};

export const Container = styled.div<ModalProps>`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 140rem;
  max-width: 140rem;
  height: 70rem;
  margin-top: 10rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  > h1 {
    font-size: 24px;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlignFormLines = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const FormLine = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    align-self: flex-start;
    margin-left: 10px;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  & + & {
    margin-top: -2rem;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  margin-bottom: 20px;
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 15rem;
  padding: 0.7rem;
  font-size: 2rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: #ff5050;
  font-size: 9px;
  bottom: 0;
  left: 1rem;
`;

export const RadioBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const RadioTypeRoute = styled.div`
  display: flex;

  img {
    width: 3rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const HistoryRouteWeekTable = styled.div`
  width: 100%;

  > h1 {
    width: 100%;
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
`;

export const HistoryHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h1 {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      margin-left: 5px;
      width: 2.5rem;
      height: 1.8rem;
      margin-right: 1rem;
    }
  }
`;

export const HistoryRouteWeekBox = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 48rem;
  margin-top: 1rem;
`;

export const HistoryRouteWeekRoute = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 10px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #007970;

    > h1 {
      font-size: 16px;
    }
  }

  table {
    width: 100%;
  }
`;

export const Th = styled.th<ThProps>`
  width: ${(props) => props.width};
  font-size: 1.4rem;
  font-weight: bold;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
`;

export const Td = styled.td<TdProps>`
  width: ${(props) => props.width};
  font-size: 1.4rem;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #3cf0de;
`;

export const HistoryRouteWeekRouteTurn = styled.tr`
  width: 100%;
  max-width: 100%;
  padding: 10px;
`;

export const HistoryNotFound = styled.div`
  width: 100%;

  > h1 {
    width: 100%;
    font-size: 16px;
  }
`;

export const WeekButton = styled.button`
  margin-left: 5px;
  border: none;
  width: 3rem;
  height: 3rem;
  background-color: #007970;
  color: #3cf0de;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  img {
    width: 2.2rem;
    height: 2.2rem;
  }

  &:hover {
    background-color: #00665f;
  }
`;
