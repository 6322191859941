import styled from 'styled-components';

type ButtonColors = {
  bgColor: string;
  bgHoverColor: string;
};

export const ModalBoxConfirm = styled.div`
  position: relative;
  background: #fff;
  width: 40rem;
  height: 20rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 1rem;

  h1 {
    width: 100%;
    text-align: left;
    font-size: 2rem;
    font-weight: normal;

    span {
      font-weight: bold;
    }
  }

  div {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ButtonConfirm = styled.button<ButtonColors>`
  color: #3cf0de;
  font-size: 2.2rem;
  padding: 0.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 5px;
  border: none;
  background: ${(props) => props.bgColor};

  &:hover {
    background: ${(props) => props.bgHoverColor};
  }
`;
