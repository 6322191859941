import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Back = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 450px;
  height: 700px;
  background-color: #fff;
  padding: 1rem;
  margin-top: 4rem;
`;

export const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > h1 {
    color: #8c8c8c;
    font-size: 2.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    > button {
      margin-left: 1rem;
      width: 3rem;
      height: 3rem;
      border: none;
      border-radius: 50%;
      background: #007970;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s;

      img {
        width: 2.5rem;
        height: 2.5rem;
      }

      &:hover {
        cursor: pointer;
        background: #00665f;
      }
    }
  }
`;

export const TopText = styled.p`
  color: #8c8c8c;
  font-size: 1.6rem;
  margin-top: 5px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  h1 {
    color: #8c8c8c;
    font-size: 2rem;
    font-weight: bold;
  }
`;

export const ListProduct = styled.div`
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
`;

export const Product = styled.div`
  display: flex;
  padding: 5px;
  margin-top: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(230, 230, 230, 0.8);
  justify-content: space-between;
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductText = styled.span`
  color: #8c8c8c;
  font-size: 1.6rem;
  max-width: 320px;
  & + & {
    margin-left: 1px;
  }
`;

export const ProductCreditation = styled.p`
  width: 100%;
  color: #f4d03f;
  font-size: 1.2rem;
`;

export const ProductMult = styled.span`
  color: #8c8c8c;
  font-size: 1.4rem;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const Count = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 20px;
`;

export const CountData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CountText = styled.p`
  font-size: 1.8rem;
  width: 300px;
  text-align: right;
`;

export const CountLine = styled.div`
  display: flex;
  width: 300px;
  justify-content: end;
`;

export const Line = styled.div`
  width: 60px;
  height: 1px;
  background-color: #007970;
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
  margin-top: 5px;
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }

  p {
    font-size: 3rem;
    color: #007970;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  width: 50%;
  padding: 0.7rem;
  font-size: 1.6rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const NoteButton = styled.button`
  position: absolute;
  top: 5rem;
  right: 5rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  background-color: #3cf0de;
  transition: 0.2s background-color;

  &:hover {
    background-color: #12edd7;
  }
`;

export const Note = styled.div`
  position: absolute;
  top: 7.5rem;
  right: calc(6rem - 400px);
  width: 400px;
  max-width: 400px;
  padding: 2rem;
  height: auto;
  max-height: 400px;
  background-color: #fff;
  border: 1px solid rgba(0, 121, 112, 0.2);
  box-shadow: 5px 5px 15px -8px rgba(0, 121, 112, 0.5);

  border-radius: 5px;

  > h1 {
    font-size: 1.8rem;
  }

  > p {
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }

  > span {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1rem;
  }
`;
