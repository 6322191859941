/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';
import productImageAlt from '../../../assets/productImageAlt.svg';

import {
  Container,
  Widget,
  Form,
  FormLine,
  ButtonBox,
  Button,
  ErrorMsg,
  ImageAlt,
} from './styles';

import Modal from '../../Modal';
import { DefaultOrder } from '../../../types/DefaultOrder';

type GenerateDefaultOrderProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultOrder: DefaultOrder;
};

type ResponseDataProps = {
  householdName: string;
  orderRef: string | null;
  orderStatus: boolean;
  orderErrorMessage: string;
};

const GenerateDefaultOrder: React.FC<GenerateDefaultOrderProps> = ({
  isOpen,
  onClose,
  defaultOrder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState<ResponseDataProps[]>([]);

  const { addToast } = useToast();

  const handleResetForm = useCallback(() => {
    onClose();
    setResponseData([]);
  }, []);

  const handleOnSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await api.post(`/market/default-order/generate`, {
        id: defaultOrder.id,
      });

      setResponseData(response.data);

      addToast({
        type: 'success',
        title: 'Encomendas tipo geradas com sucesso.',
        description: { code: 734 },
      });
      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro ao gerar as encomendas tipo.',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal visible={isOpen}>
      {responseData.length > 0 ? (
        <Container>
          <h1>Relatório de encomendas tipo</h1>
          <Widget>
            <Form>
              {responseData.map((rd) => (
                <FormLine key={rd.householdName}>
                  <div>
                    <p>{rd.householdName}</p>
                    {rd.orderStatus ? (
                      <span style={{ color: '#58d68d' }}>OK</span>
                    ) : (
                      <span style={{ color: '#f1948a' }}>ERRO</span>
                    )}
                  </div>
                  <ErrorMsg>{rd.orderErrorMessage}</ErrorMsg>
                </FormLine>
              ))}
            </Form>
          </Widget>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              Fechar
            </Button>
          </ButtonBox>
        </Container>
      ) : (
        <Container>
          <h1>Gerar encomenda tipo</h1>
          <Widget>
            <Form>
              <FormLine>
                <p>
                  Gerar todas as encomendas tipo{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {defaultOrder.name}
                  </span>
                  ?
                </p>
              </FormLine>
              <ImageAlt>
                <img
                  src={productImageAlt}
                  alt="Imagem de um saco com compras"
                />
              </ImageAlt>
            </Form>
          </Widget>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={handleResetForm}
              disabled={isLoading}
            >
              <img src={chevron} alt="Icon de seta back" />
              Voltar
            </Button>
            {isLoading ? (
              <Button
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                type="button"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
                onClick={handleOnSubmit}
              >
                <img src={save} alt="Icon de desquete save" />
                Gerar
              </Button>
            )}
          </ButtonBox>
        </Container>
      )}
    </Modal>
  );
};

export default GenerateDefaultOrder;
