/* eslint-disable import/no-duplicates */
import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Order } from '../../../types/Order';

import { styles } from './styles';

type FileProps = {
  order: Order;
};

const File: React.FC<FileProps> = ({ order }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="portrait">
        <View>
          <Text style={styles.order_title}>
            Encomenda{' '}
            {order.status === 'refused' && (
              <Text style={styles.order_title_canceled}>- Cancelada</Text>
            )}
            {order.status === 'not delivered' && (
              <Text style={styles.order_title_canceled}>- Não entregue</Text>
            )}
          </Text>
          <Text style={styles.order_text}>Referência: {order.intRef}</Text>
          <Text style={styles.order_text}>
            Agregado: {order.household.name}{' '}
            {order.household_num && `| Nº de Agregados: ${order.household_num}`}{' '}
            {order.route && `| Rota: ${order.route}`}
          </Text>
          <Text style={styles.order_text}>
            Data de distribuição:{' '}
            {order.distributionDate
              ? format(
                  parseISO(order.distributionDate),
                  `dd 'de' MMMM 'de' yyyy `,
                  { locale: pt },
                )
              : 'Sem data'}
          </Text>
          <Text style={styles.order_text_executed}>Executada por:</Text>
        </View>
        <View style={styles.order_line} />
        <View>
          <Text style={styles.order_product}>Produtos:</Text>
          <View style={styles.order_grid}>
            {order.orderItems.length > 0 &&
              order.orderItems.map((item) => (
                <View style={styles.order_product_line} key={item.id}>
                  <View style={styles.order_product_info}>
                    <Text style={styles.order_product_info_text}>
                      {item.amount}
                    </Text>
                    <Text style={styles.order_product_info_multi}>X</Text>
                    <Text style={styles.order_product_info_text}>
                      {item.name}
                    </Text>
                  </View>
                  <View style={styles.order_product_info}>
                    <Text style={styles.order_product_info_text}>
                      {(item.amount * item.value).toFixed(2)}
                    </Text>
                    <Text style={styles.order_product_info_sc}>sm</Text>
                  </View>
                </View>
              ))}
          </View>
          <Text style={styles.order_total}>
            Total: {order.value.toFixed(2)}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default File;
