/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import Td from '../../Table/Td';

import { Line, Index } from './styles';

import edit from '../../../assets/edit.svg';
import household from '../../../assets/household.svg';
import reset_coin from '../../../assets/reset_coin.svg';
import reset_one from '../../../assets/reset_one.svg';

import { DefaultOrder } from '../../../types/DefaultOrder';

type DefaultOrderLinePropsReq = {
  defaultOrder: DefaultOrder;
  index: number;
  editAction: () => void;
  householderAction: () => void;
  generateAction: () => void;
  generateOneAction: () => void;
};

const DefaultOrdertLine: React.FC<DefaultOrderLinePropsReq> = ({
  defaultOrder,
  index,
  editAction,
  householderAction,
  generateAction,
  generateOneAction,
}) => {
  return (
    <Line>
      <Td talign="center" width="5%">
        <Index>{index}</Index>
      </Td>
      <Td talign="left" width="33%">
        {defaultOrder.name}
      </Td>
      <Td talign="center" width="13%">
        {defaultOrder.defaultOrderItems.length}
      </Td>
      <Td talign="center" width="13%">
        {defaultOrder.defaultOrderHousehold
          ? defaultOrder.defaultOrderHousehold.length
          : 0}
      </Td>
      <Td talign="center" width="13%">
        {defaultOrder.defaultOrderItems
          .reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.amount * currentValue.products.value,
            0,
          )
          .toFixed(2)}
      </Td>
      <Td talign="center" width="10%">
        {format(parseISO(defaultOrder.createdAt), `dd'/'MM'/'yyyy`, {
          locale: pt,
        })}
      </Td>

      <Td talign="center" width="13%">
        <div>
          <button
            type="button"
            title="Editar Encomenda Tipo"
            onClick={editAction}
          >
            <img src={edit} alt="icon de um lápis" />
          </button>
          <button
            type="button"
            title="Adicionar Agregados"
            onClick={householderAction}
          >
            <img src={household} alt="grupo de pessoas" />
          </button>
          <button
            type="button"
            title="Gerar encomendas tipo"
            onClick={generateAction}
          >
            <img src={reset_coin} alt="seta circular" />
          </button>
          <button
            type="button"
            title="Gerar encomendas tipo individual"
            onClick={generateOneAction}
          >
            <img src={reset_one} alt="utilizador" />
          </button>
        </div>
      </Td>
    </Line>
  );
};

export default DefaultOrdertLine;
