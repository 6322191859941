import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 } from 'uuid';

import { codes, CodesOpt } from '../config/error';
import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description: string;
}

export interface ToastRequest {
  type?: 'success' | 'error' | 'info';
  title: string;
  description: CodesOpt;
}

interface ToastContextData {
  addToast(message: ToastRequest): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(({ type, title, description }: ToastRequest) => {
    const id = v4();

    const msg = codes[description.code]
      ? codes[description.code]
      : 'Erro desconhecido, tente novamente mais tarde ou entre em contacto com administrador do sistema!';

    const toast = {
      id,
      type,
      title,
      description: msg,
    };

    setMessages((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
