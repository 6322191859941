/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import Modal from '../Modal';

import { ModalBoxConfirm, ButtonConfirm } from './styles';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

type ResetAllCoinsProps = {
  group: '1' | '2' | '3' | '4' | '100';
  isOpen: boolean;
  onClose: () => void;
};

const ResetAllCoins: React.FC<ResetAllCoinsProps> = ({
  group,
  isOpen,
  onClose,
}) => {
  const { addToast } = useToast();

  const handleYes = async () => {
    try {
      await api.put(`/market/session/coin/reset-all`, { group });

      addToast({
        type: 'success',
        title: 'Renovação Soumas!',
        description: { code: 704 },
      });
      onClose();
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro na renovação Soumas!',
        description: { code: 723 },
      });
    }
  };

  const handleGroup = (g: '1' | '2' | '3' | '4' | '100') => {
    switch (g) {
      case '1':
        return ` do grupo das letras`;
      case '2':
        return ` do grupo dos números`;
      case '3':
        return ` do grupo da porta mensal`;
      default:
        return '';
    }
  };

  return (
    <Modal visible={isOpen}>
      <ModalBoxConfirm>
        <h1>
          Quer renovar os Soumas de todas a contas ativas da mercearia
          {handleGroup(group)}?
        </h1>
        <div>
          <ButtonConfirm
            bgColor="#ff8080"
            bgHoverColor="#ff4d4d"
            type="button"
            onClick={onClose}
          >
            Não
          </ButtonConfirm>
          <ButtonConfirm
            bgColor="#007970"
            bgHoverColor="#00665f"
            type="button"
            onClick={handleYes}
          >
            Sim
          </ButtonConfirm>
        </div>
      </ModalBoxConfirm>
    </Modal>
  );
};

export default ResetAllCoins;
