/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import loading from '../../assets/loading01.svg';
import user from '../../assets/user.svg';
import household from '../../assets/household.svg';
import order from '../../assets/order.svg';
import products from '../../assets/products.svg';
import routes from '../../assets/routes.svg';
import marketSession from '../../assets/marketSession.svg';
import pt from '../../assets/pt.svg';
import uk from '../../assets/uk.svg';
import children from '../../assets/children.svg';

import {
  Container,
  Widget,
  Loading,
  Grid,
  DashCard,
  Card,
  ImageCard,
  Info,
  InfoCard,
  InfoCardCircle,
  InfoCardTitle,
} from './styles';
import { RouteWeek } from '../../types/RouteWeek';
import MealDashboard from './Meal';
import FoodSuppDashboard from './FoodSupp';

type DashboardInfo = {
  numUsers: number;
  numActiveUsers: number;
  numInactiveUsers: number;
  childrens: number;
  childrensActive: number;
  childrensInactive: number;
  numHouseHolder: number;
  numActiveHouseHolder: number;
  numInactiveHouseHolder: number;
  numMarketSessions: number;
  numActiveMarketSessions: number;
  numInactiveMarketSessions: number;
  numOfPendingOrders: number;
  productsInfo: {
    numOfProducts: number;
    numOfProductsAvailableOnMarket: number;
    numOfProductsStockAlert: number;
  };
  routeWeek: RouteWeek | undefined;
};

const Dashboard: React.FC = () => {
  const [dashboardInfo, setDashboardInfo] = useState({} as DashboardInfo);

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { addToast } = useToast();

  const getDashboardInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/dashboard');
      setDashboardInfo(response.data);
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados',
        description: { code: 750 },
      });
    }
  }, [addToast]);

  useEffect(() => {
    getDashboardInfo();
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <Grid>
              <DashCard onClick={() => history.push('/utentes')}>
                <Card>
                  <ImageCard>
                    <img src={user} alt="Utentes" />
                    <p>Utentes</p>
                  </ImageCard>
                  <Info>
                    <InfoCard>
                      <InfoCardCircle>{dashboardInfo.numUsers}</InfoCardCircle>
                      <InfoCardTitle>Nº Total</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numActiveUsers}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Ativos</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numInactiveUsers}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Inativos</InfoCardTitle>
                    </InfoCard>
                  </Info>
                </Card>
              </DashCard>
              <DashCard onClick={() => history.push('/utentes')}>
                <Card>
                  <ImageCard>
                    <img src={children} alt="Crianças" />
                    <p>Crianças</p>
                  </ImageCard>
                  <Info>
                    <InfoCard>
                      <InfoCardCircle>{dashboardInfo.childrens}</InfoCardCircle>
                      <InfoCardTitle>Nº Total</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.childrensActive}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Ativos</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.childrensInactive}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Inativos</InfoCardTitle>
                    </InfoCard>
                  </Info>
                </Card>
              </DashCard>
              <DashCard onClick={() => history.push('/agregados')}>
                <Card>
                  <ImageCard>
                    <img src={household} alt="Agregados" />
                    <p>Agregados Familiares</p>
                  </ImageCard>
                  <Info>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numHouseHolder}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Total</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numActiveHouseHolder}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Ativos</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numInactiveHouseHolder}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Inativos</InfoCardTitle>
                    </InfoCard>
                  </Info>
                </Card>
              </DashCard>
              <DashCard onClick={() => history.push('/agregados')}>
                <Card>
                  <ImageCard>
                    <img src={marketSession} alt="Mercearia Sessões" />
                    <p>Sessões Mercearia</p>
                  </ImageCard>
                  <Info>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numMarketSessions}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Total</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numActiveMarketSessions}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Ativos</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numInactiveMarketSessions}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Inativos</InfoCardTitle>
                    </InfoCard>
                  </Info>
                </Card>
              </DashCard>
              <DashCard onClick={() => history.push('/rotas')}>
                <Card>
                  <ImageCard>
                    <img src={routes} alt="Rotas da Semana" />
                    <p>Rotas Semanais</p>
                  </ImageCard>
                  <Info>
                    {dashboardInfo.routeWeek ? (
                      <>
                        <InfoCard>
                          <InfoCardCircle>
                            {dashboardInfo.routeWeek.type === 'pt' ? (
                              <img src={pt} alt="Bandeira de Portugal" />
                            ) : (
                              <img src={uk} alt="Bandeira da Ucrania" />
                            )}
                          </InfoCardCircle>
                          <InfoCardTitle>Tipo da Rota</InfoCardTitle>
                        </InfoCard>
                        <InfoCard>
                          <InfoCardCircle>
                            {dashboardInfo.routeWeek.week}
                          </InfoCardCircle>
                          <InfoCardTitle>Nº Semana</InfoCardTitle>
                        </InfoCard>
                      </>
                    ) : (
                      <InfoCard>
                        <InfoCardTitle>
                          Não existem rotas a decorrer.
                        </InfoCardTitle>
                      </InfoCard>
                    )}
                  </Info>
                </Card>
              </DashCard>
              <DashCard onClick={() => history.push('/produtos')}>
                <Card>
                  <ImageCard>
                    <img src={products} alt="Produtos do Mercado" />
                    <p>Produtos</p>
                  </ImageCard>
                  <Info>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.productsInfo.numOfProducts}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Total</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {
                          dashboardInfo.productsInfo
                            .numOfProductsAvailableOnMarket
                        }
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Disponíveis</InfoCardTitle>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.productsInfo.numOfProductsStockAlert}
                      </InfoCardCircle>
                      <InfoCardTitle>Alertas Stock</InfoCardTitle>
                    </InfoCard>
                  </Info>
                </Card>
              </DashCard>
              <DashCard onClick={() => history.push('/encomendas')}>
                <Card>
                  <ImageCard>
                    <img src={order} alt="Encomendas" />
                    <p>Encomendas</p>
                  </ImageCard>
                  <Info>
                    <InfoCard>
                      <InfoCardCircle>
                        {dashboardInfo.numOfPendingOrders}
                      </InfoCardCircle>
                      <InfoCardTitle>Nº Pendentes</InfoCardTitle>
                    </InfoCard>
                  </Info>
                </Card>
              </DashCard>
              <MealDashboard />
              <FoodSuppDashboard />
            </Grid>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default Dashboard;
