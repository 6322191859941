import React from 'react';

import { Container } from './styles';

type ThProps = {
  width: string;
  talign: string;
  colSpan?: number | undefined;
  fontSize?: string | undefined;
  pt?: string | undefined;
  pb?: string | undefined;
};

const Th: React.FC<ThProps> = ({
  children,
  width,
  talign,
  colSpan,
  fontSize = '2',
  pt = '1.5',
  pb = '1.5',
}) => {
  return (
    <Container
      width={width}
      talign={talign}
      colSpan={colSpan}
      fontSize={fontSize}
      pt={pt}
      pb={pb}
    >
      {children}
    </Container>
  );
};

export default Th;
