import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import PdfOrderPage from '../../components/PdfFiles/PdfOrderPage';
import { Order } from '../../types/Order';

const styles = StyleSheet.create({
  view: {
    width: '100vw',
    height: '100vh',
  },
});

type PDFOrderProps = {
  order: Order;
};

const PDFOrder: React.FC = () => {
  const location = useLocation<PDFOrderProps>();

  return (
    <PDFViewer style={styles.view}>
      <PdfOrderPage order={location.state.order} />
    </PDFViewer>
  );
};

export default PDFOrder;
