/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { Container } from './styles';

import reset_coin from '../../../assets/reset_coin.svg';

import { MarketSession } from '../../../types/Household';
import api from '../../../config/api';
import Modal from '../../Modal';
import ModalMessage from '../ModalMessage';
import { useToast } from '../../../hooks/ToastContext';

type CoinMarketProps = {
  setMarketSession: React.Dispatch<React.SetStateAction<MarketSession>>;
  coin: number;
  id_market_session: string;
};

const CoinMarket: React.FC<CoinMarketProps> = ({
  setMarketSession,
  coin,
  id_market_session,
}) => {
  const [display, setDisplay] = useState(false);

  const { addToast } = useToast();

  const handleCoinReset = async () => {
    try {
      const response = await api.put('/market/session/coin/reset-value', {
        id_market_session,
      });

      setMarketSession((PrevState) => ({
        ...PrevState,
        coinInfo: response.data,
      }));

      setDisplay(false);

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: { code: 705 },
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  return (
    <>
      <Container>
        <span>{coin.toFixed(2)}</span>
        <button
          type="button"
          title="renovar souma coins"
          onClick={() => setDisplay(true)}
        >
          <img src={reset_coin} alt="spinner" />
        </button>
      </Container>
      <Modal visible={display}>
        <ModalMessage
          closeModal={() => setDisplay(false)}
          handleYes={handleCoinReset}
          message="Quer renovar o valor do saldo disponivel?"
        />
      </Modal>
    </>
  );
};

export default CoinMarket;
