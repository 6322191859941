import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  max-width: 500px;
  height: 35px;
  background-color: #3cf0de;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  justify-content: space-between;
`;

export const TitleWeek = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 20px;
    > img {
      margin-left: 1rem;
      width: 2.2rem;
      height: 1.4rem;
    }
  }

  div {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 1px solid #007970;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RightSideWeek = styled.div`
  margin-right: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #007970;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WeekButton = styled.button`
  margin-left: 5px;
  border: none;
  width: 3rem;
  height: 3rem;
  background-color: #007970;
  color: #3cf0de;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 2.2rem;
    height: 2.2rem;
  }

  &:hover {
    background-color: #00665f;
  }
`;
