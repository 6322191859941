/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

import { useToast } from '../../../hooks/ToastContext';
import api from '../../../config/api';

import TextArea from '../../TextArea';
import Input from '../../Input';

import { Support } from '../../../types/Support';

import editIcon from '../../../assets/edit.svg';
import saveIcon from '../../../assets/save_dark.svg';

import { Line, Column, Card, Field, FieldArea, EditButton } from './styles';
import SelectInput from '../../Select';

const schema = yup.object().shape({
  support_resp: yup.string().required('* é obrigatório preencher este campo!'),
  description: yup.string().required('* é obrigatório preencher este campo!'),
  type: yup.string().required('* é obrigatório preencher este campo!'),
  value: yup.string().required('* é obrigatório preencher este campo!'),
  date: yup.string().required('* é obrigatório preencher este campo!'),
});

type SupportEditProps = {
  support_resp: string;
  description: string;
  type: string;
  value: string;
  date: string;
};

type SupProps = {
  support: Support;
  active: boolean;
};

const SupportCard: React.FC<SupProps> = ({ support, active }) => {
  const [supportData, setSupportData] = useState(support);

  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<SupportEditProps>({
    resolver: yupResolver(schema),
  });

  const handleEdit = async (data: SupportEditProps) => {
    try {
      setIsLoading(true);
      const response = await api.put(
        `/support/${supportData.id}?uid=${supportData.userId}`,
        {
          ...data,
          type: Number(data.type),
        },
      );
      if (response) {
        addToast({
          type: 'success',
          title: 'Apoio!',
          description: { code: 403 },
        });
        setSupportData(response.data);
      }
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na edição de apoio!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    } finally {
      setEdit(false);
      setIsLoading(false);
    }
  };

  if (edit) {
    return (
      <Card>
        <form onSubmit={handleSubmit(handleEdit)}>
          <Line>
            <Column>
              <Line>
                <Input
                  width="72%"
                  title="Responsavél"
                  borderType
                  type="text"
                  {...register('support_resp')}
                  error={errors.support_resp?.message}
                />
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SelectInput
                        title="Tipo de Apoio"
                        {...register('type')}
                        onChange={onChange}
                        value={value || '1'}
                        width="25%"
                        borderType
                        error={errors.type?.message}
                        options={[
                          { value: '1', label: 'Jurídico' },
                          { value: '2', label: 'Emprego' },
                          { value: '3', label: 'Bens' },
                          { value: '4', label: 'Saúde' },
                          { value: '5', label: 'Psicólogo' },
                          { value: '6', label: 'Serviços' },
                          { value: '7', label: 'Subsídios' },
                        ]}
                      />
                    );
                  }}
                />
              </Line>
              <Line>
                <Input
                  width="40%"
                  title="Data do apoio"
                  borderType
                  type="date"
                  {...register('date')}
                  error={errors.date?.message}
                />
                <Input
                  width="15%"
                  title="Valor (€)"
                  borderType
                  type="number"
                  step=".01"
                  textAlign="right"
                  {...register('value')}
                  error={errors.value?.message}
                />
              </Line>
            </Column>
            <TextArea
              width="50%"
              title="Descrição"
              {...register('description')}
              error={errors.description?.message}
            />
          </Line>
          <EditButton type="submit" title="Guardar" disabled={isLoading}>
            <img src={saveIcon} alt="Imagem de uma desquete" />
          </EditButton>
        </form>
      </Card>
    );
  }

  return (
    <Card>
      <Line>
        <Column>
          <Line>
            <Field width="72%">
              <h1>Responsável</h1>
              <p>{support.support_resp}</p>
            </Field>
            <Field width="25%" align="center">
              <h1>Tipo de Apoio</h1>
              <p>
                {support.type === 1 && `Jurídico`}
                {support.type === 2 && `Emprego`}
                {support.type === 3 && `Bens`}
                {support.type === 4 && `Saúde`}
                {support.type === 5 && `Psicólogo`}
                {support.type === 6 && `Serviços`}
                {support.type === 7 && `Subsídios`}
              </p>
            </Field>
          </Line>
          <Line>
            <Field width="30%">
              <h1>Data do Apoio</h1>
              <p>
                {format(new Date(support.date), `dd'/'MM'/'yyyy`, {
                  locale: pt,
                })}
              </p>
            </Field>
            <Field width="20%" align="right">
              <h1>Valor (€)</h1>
              <p>{support.value}€</p>
            </Field>
          </Line>
        </Column>
        <FieldArea>
          <h1>Descrição</h1>
          <p>{support.description}</p>
        </FieldArea>
      </Line>
      {active && (
        <EditButton
          type="button"
          title="Editar Apoio"
          onClick={() => {
            setEdit(true);
            setValue('support_resp', support.support_resp);
            setValue(
              'date',
              format(new Date(support.date), `yyyy'-'MM'-'dd`, {
                locale: pt,
              }),
            );
            setValue('value', support.value);
            setValue('description', support.description);
            setValue('type', String(support.type));
          }}
        >
          <img src={editIcon} alt="Imagem de um lapis" />
        </EditButton>
      )}
    </Card>
  );
};

export default SupportCard;
