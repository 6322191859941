/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef } from 'react';

import { Container } from './styles';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  title: string;
  width: string;
  error: string | undefined;
}

const CheckBox = forwardRef<HTMLInputElement, InputProps>(
  ({ width, title, error, ...rest }, ref) => {
    return (
      <Container width={width} isError={!!error}>
        <label>
          {title}
          <input ref={ref} {...rest} />
          <span />
        </label>
        <p>{error}</p>
      </Container>
    );
  },
);

export default CheckBox;
