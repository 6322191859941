/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { Container } from './styles';

import lock from '../../../assets/lock.svg';
import unlock from '../../../assets/unlock.svg';

import { MarketSession } from '../../../types/Household';
import api from '../../../config/api';
import Modal from '../../Modal';
import ModalMessage from '../ModalMessage';
import { useToast } from '../../../hooks/ToastContext';

type StateMarketProps = {
  setMarketSession: React.Dispatch<React.SetStateAction<MarketSession>>;
  available: boolean;
  household_id: string;
};

const StateMarket: React.FC<StateMarketProps> = ({
  setMarketSession,
  available,
  household_id,
}) => {
  const [display, setDisplay] = useState(false);
  const { addToast } = useToast();

  const handleChangeStatus = async () => {
    try {
      const response = await api.put('/market/session/status', {
        household_id,
      });

      setMarketSession((PrevState) => ({
        ...PrevState,
        available: response.data.available,
      }));
      setDisplay(false);

      addToast({
        type: 'success',
        title: 'Alteração!',
        description: { code: 703 },
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação de credencias!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  if (available) {
    return (
      <>
        <Container>
          <span>Ativo</span>
          <button
            type="button"
            title="desativar acesso"
            onClick={() => setDisplay(true)}
          >
            <img src={unlock} alt="cadeado fechado" />
          </button>
        </Container>

        <Modal visible={display}>
          <ModalMessage
            closeModal={() => setDisplay(false)}
            handleYes={handleChangeStatus}
            message="Quer desativar o acesso ao mercado?"
          />
        </Modal>
      </>
    );
  }

  return (
    <>
      <Container>
        <span>Inativo</span>
        <button
          type="button"
          title="ativar acesso"
          onClick={() => setDisplay(true)}
        >
          <img src={lock} alt="cadeado fechado" />
        </button>
      </Container>

      <Modal visible={display}>
        <ModalMessage
          closeModal={() => setDisplay(false)}
          handleYes={handleChangeStatus}
          message="Quer ativar o acesso ao mercado?"
        />
      </Modal>
    </>
  );
};

export default StateMarket;
