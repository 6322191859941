/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useCallback } from 'react';

import {
  endOfMonth,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import food_support from '../../../assets/food_support.svg';

import {
  DashCard,
  Card,
  ImageCard,
  Info,
  InfoCard,
  InfoCardCircle,
  InfoCardTitle,
  Dates,
  ErrorDate,
} from './styles';

const FoodSuppDashboard: React.FC = () => {
  const [dashboardInfo, setDashboardInfo] = useState(0);
  const [dates, setDates] = useState({
    ds: startOfMonth(new Date()),
    de: endOfMonth(new Date()),
  });

  const [dateError, setDateError] = useState('');

  const { addToast } = useToast();

  const handleDateChange = (type: 'ds' | 'de', d: string) => {
    if (type === 'ds') {
      const date = startOfDay(parseISO(d));

      if (isAfter(date, dates.de)) {
        setDateError('Data de inicio não pode ser maior de a data de fim');
        return;
      }

      setDates((prevState) => ({ ...prevState, ds: date }));
    } else if (type === 'de') {
      const date = startOfDay(parseISO(d));

      if (isBefore(date, dates.ds)) {
        setDateError('Data de fim não pode ser menor de a data de inicio');
        return;
      }

      setDates((prevState) => ({ ...prevState, de: date }));
    }

    setDateError('');
  };

  const getDashboardInfo = useCallback(
    async (dStart: string, dEnd: string) => {
      try {
        const response = await api.get(
          `/household/get/food-supp-num?ds=${dStart}&de=${dEnd}`,
        );
        setDashboardInfo(response.data);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter dados',
          description: { code: 750 },
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    getDashboardInfo(
      format(dates.ds, 'yyyy-MM-dd'),
      format(dates.de, 'yyyy-MM-dd'),
    );
  }, [dates]);

  return (
    <DashCard>
      <Card>
        <Dates>
          <div>
            <span> Inicio:</span>
            <input
              defaultValue={format(dates.ds, 'yyyy-MM-dd')}
              type="date"
              onChange={(e) =>
                e.currentTarget.value &&
                handleDateChange('ds', e.currentTarget.value)
              }
            />
          </div>
          <div>
            <span> Fim:</span>
            <input
              defaultValue={format(dates.de, 'yyyy-MM-dd')}
              type="date"
              onChange={(e) =>
                e.currentTarget.value &&
                handleDateChange('de', e.currentTarget.value)
              }
            />
          </div>
        </Dates>
        {dateError && <ErrorDate>{dateError}</ErrorDate>}
        <ImageCard>
          <img src={food_support} alt="Apoio alimentar" />
        </ImageCard>
        <Info>
          <InfoCard>
            <InfoCardCircle>{dashboardInfo}</InfoCardCircle>
            <InfoCardTitle>Nº de Utentes com apoio alimentar</InfoCardTitle>
          </InfoCard>
        </Info>
      </Card>
    </DashCard>
  );
};

export default FoodSuppDashboard;
