/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../../Input';
import api from '../../../../config/api';
import { useToast } from '../../../../hooks/ToastContext';

import CheckBox from '../../../CheckBox';
import { Routes } from '../../../../types/Routes';
import { RouteWeek } from '../../../../types/RouteWeek';

import {
  Container,
  Modal,
  Form,
  FormLine,
  ButtonBox,
  Button,
  Close,
  ErrorMessage,
  RadioBox,
  RadioTypeRoute,
  RouteCircle,
  RoutesBox,
  AlignFormLines,
} from './styles';

import save from '../../../../assets/save.svg';
import loading from '../../../../assets/loading03.svg';
import pt from '../../../../assets/pt.svg';
import uk from '../../../../assets/uk.svg';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
  setRouteWeekNotFinished: React.Dispatch<React.SetStateAction<RouteWeek>>;
};

type DataFormSubmit = {
  date: string;
  type: string;
  routesIds: string[];
};

type RoutesData = {
  id: string;
  routeNum: string;
  routeType: string;
};

const schema = yup.object().shape({
  date: yup.string().required('* a data é obrigatório'),
  type: yup.string().nullable().required('* o tipo de rota é obrigatório'),
  routesIds: yup
    .array()
    .nullable()
    .min(1, '* tem de escolher no minimo uma rota')
    .required('* tem de escolher no minimo uma rota'),
});

const CreateRouteWeek: React.FC<ModalProps> = ({
  visible,
  closeModal,
  setRouteWeekNotFinished,
}) => {
  const [routesToSelect, setRoutesToSelect] = useState([] as RoutesData[]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<DataFormSubmit>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const type = register('type');

  const getRoutes = useCallback(async () => {
    try {
      const response = await api.get('routes');

      setRoutesToSelect(
        response.data.filter((route: Routes) => {
          return (
            route.status && {
              id: route.id,
              routeNum: route.routeNum,
              routeType: route.routeType,
            }
          );
        }),
      );
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter rotas',
        description: { code: 805 },
      });
    }
  }, [addToast]);

  const handleTypeChange = (e: React.FormEvent<HTMLInputElement>) => {
    resetField('routesIds');
    // setRoutesToSelect(
    //   routes.filter((route) => route.routeType === e.currentTarget.value),
    // );
    setValue('type', e.currentTarget.value);
  };

  const handleOnSubmit = async (data: DataFormSubmit) => {
    try {
      setIsLoading(true);
      const response = await api.post(`route-week`, {
        type: data.type,
        date: data.date,
        routesIds: data.routesIds.join(','),
      });

      addToast({
        type: 'success',
        title: 'Criação da Semana',
        description: { code: 830 },
      });

      setRouteWeekNotFinished(response.data);
      setIsLoading(false);
      closeModal();
    } catch (e: any) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na criação da semana',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  return (
    <Container visible={visible}>
      <Modal>
        <Close>
          <button type="button" onClick={closeModal}>
            &#x2715;
          </button>
        </Close>
        <h1>Criar Semana</h1>
        <Form id="create_week" onSubmit={handleSubmit(handleOnSubmit)}>
          <AlignFormLines>
            <FormLine>
              <Input
                width="100%"
                title="Data da Semana"
                type="date"
                textAlign="center"
                borderType
                {...register('date')}
                error={errors.date?.message}
              />
            </FormLine>
            <FormLine>
              <h1>Tipo de Rota</h1>
              <RadioBox>
                <RadioTypeRoute>
                  <img src={pt} alt="Bandeira de Portugal" />
                  <CheckBox
                    width="auto"
                    title=""
                    type="radio"
                    value="pt"
                    onChange={(e) => handleTypeChange(e)}
                    ref={type.ref}
                    error={errors.type?.message}
                  />
                </RadioTypeRoute>
                <RadioTypeRoute>
                  <img src={uk} alt="Bandeira da Ucrania" />
                  <CheckBox
                    width="auto"
                    title=""
                    type="radio"
                    value="uk"
                    onChange={(e) => handleTypeChange(e)}
                    ref={type.ref}
                    error={errors.type?.message}
                  />
                </RadioTypeRoute>
              </RadioBox>
              <ErrorMessage>{errors.type?.message}</ErrorMessage>
            </FormLine>
          </AlignFormLines>
          <RoutesBox>
            <h1>
              Rotas da semana
              <ErrorMessage>{errors.routesIds?.message}</ErrorMessage>
            </h1>
            <div>
              {routesToSelect.map((route) => (
                <RouteCircle key={route.id}>
                  <label htmlFor={route.id}>
                    <input
                      {...register('routesIds')}
                      type="checkbox"
                      id={route.id}
                      value={route.id}
                    />
                    <span>{route.routeNum}</span>
                  </label>
                </RouteCircle>
              ))}
            </div>
          </RoutesBox>
          <ButtonBox>
            {isLoading ? (
              <Button
                form="create_week"
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                form="create_week"
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
              >
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            )}
          </ButtonBox>
        </Form>
      </Modal>
    </Container>
  );
};

export default CreateRouteWeek;
