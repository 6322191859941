/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import Table from '../../components/Table';
import Th from '../../components/Table/Th';
import Td from '../../components/Table/Td';

import add from '../../assets/add.svg';
import search from '../../assets/search.svg';
import view from '../../assets/view.svg';
import edit from '../../assets/edit.svg';
import loading from '../../assets/loading01.svg';
import page_left from '../../assets/page_left.svg';
import page_right from '../../assets/page_right.svg';

import {
  Container,
  Widget,
  Top,
  Title,
  Search,
  Loading,
  PageBox,
  Active,
} from './styles';
import { User } from '../../types/Users';

let debounce: NodeJS.Timeout;

const Users: React.FC = () => {
  const ROWLIMIT = 10;
  const [users, setUsers] = useState([] as User[]);
  const [usersList, setUsersList] = useState([] as User[]);
  const [displayUsers, setDisplayUsers] = useState([] as User[]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { addToast } = useToast();

  const usersToDisplay = useCallback((pageData, array) => {
    const arrayTemp = [];

    for (
      let index = ROWLIMIT * (pageData - 1);
      index <= pageData * ROWLIMIT - 1;
      index++
    ) {
      if (array[index]) {
        arrayTemp.push(array[index]);
      }
    }

    setDisplayUsers(arrayTemp);
  }, []);

  const handlePage = (data: number) => {
    if (page + data > 0 && page + data <= pages) {
      setPage((prevState) => prevState + data);
      usersToDisplay(page + data, usersList);
    }
  };

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/user');
      setUsers(response.data);
      setUsersList(response.data);
      setPages(Math.ceil(response.data.length / ROWLIMIT));
      usersToDisplay(page, response.data);
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter utentes',
        description: { code: 206 },
      });
    }
  }, [addToast, page, usersToDisplay]);

  useEffect(() => {
    getUsers();
  }, []);

  const handleAddUser = () => {
    history.push('utentes/criar');
  };

  const handleSeach = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(debounce);
    e.preventDefault();

    const searchText = e.currentTarget.value.toLowerCase();
    searchText
      ? (debounce = setTimeout(() => {
          const arrayTemp = users.filter((user) => {
            const birth_date = format(
              parseISO(user.birth_date),
              `dd'/'MM'/'yyyy`,
              {
                locale: pt,
              },
            );
            return (
              user.name.toLowerCase().includes(searchText) ||
              user.int_num.toLowerCase().includes(searchText) ||
              user.email.toLowerCase().includes(searchText) ||
              user.contact.toLowerCase().includes(searchText) ||
              birth_date.includes(searchText)
            );
          });
          setUsersList(arrayTemp);
          usersToDisplay(1, arrayTemp);
          setPages(Math.ceil(arrayTemp.length / ROWLIMIT));
        }, 500))
      : (debounce = setTimeout(() => {
          setUsersList(users);
          usersToDisplay(1, users);
          setPages(Math.ceil(users.length / ROWLIMIT));
        }, 500));
  };

  const handleEdit = (id: string) => {
    history.push({
      pathname: 'utentes/editar',
      state: {
        id,
      },
    });
  };

  const handleView = (id: string) => {
    history.push({
      pathname: 'utentes/ver',
      state: {
        id,
      },
    });
  };

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Utentes</h1>
              <button
                type="button"
                title="Adicionar Utente"
                onClick={handleAddUser}
              >
                <img src={add} alt="plus" />
              </button>
            </Title>
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={handleSeach}
              />
              <div title="Pesquisar">
                <img src={search} alt="search" />
              </div>
            </Search>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="left" width="25%">
                      Nome
                    </Th>
                    <Th talign="left" width="24%">
                      E-mail
                    </Th>
                    <Th talign="center" width="12%">
                      Núm. Int
                    </Th>
                    <Th talign="center" width="12%">
                      Contacto
                    </Th>
                    <Th talign="center" width="12%">
                      Dt. Nasc
                    </Th>
                    <Th talign="center" width="10%" />
                  </tr>
                </thead>
                <tbody>
                  {displayUsers.map((user, index) => (
                    <tr
                      key={user.id}
                      style={{
                        background: user.active ? '#fff' : '#ffe6e6',
                      }}
                    >
                      <Td talign="center" width="5%">
                        <Active active={user.active}>
                          <div />

                          {index + 1}
                        </Active>
                      </Td>
                      <Td talign="left" width="25%">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                          }}
                        >
                          {user.name}
                        </div>
                      </Td>
                      <Td talign="left" width="24%">
                        {user.email}
                      </Td>
                      <Td talign="center" width="12%">
                        {user.int_num}
                      </Td>
                      <Td talign="center" width="12%">
                        {user.contact}
                      </Td>
                      <Td talign="center" width="12%">
                        {format(parseISO(user.birth_date), `dd'/'MM'/'yyyy`, {
                          locale: pt,
                        })}
                      </Td>
                      <Td talign="center" width="10%">
                        <div>
                          <button
                            type="button"
                            title="ver"
                            onClick={() => handleView(user.id)}
                          >
                            <img src={view} alt="view" />
                          </button>
                          <button
                            type="button"
                            title="editar"
                            onClick={() => handleEdit(user.id)}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PageBox>
                <button
                  type="button"
                  title="anterior"
                  onClick={() => handlePage(-1)}
                >
                  <img src={page_left} alt="seta para a esquerda" />
                </button>
                <p>
                  {page} - {pages}
                </p>
                <button
                  type="button"
                  title="próximo"
                  onClick={() => handlePage(1)}
                >
                  <img src={page_right} alt="seta para a direita" />
                </button>
              </PageBox>
            </>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default Users;
