import styled from 'styled-components';

type Input = {
  width: string;
  isError: boolean;
};

export const Container = styled.div<Input>`
  width: ${(props) => props.width};
  border: none;
  background: transparent;
  display: flex;
  align-items: center;

  span {
    background: #ccc !important;
  }

  label {
    position: relative;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    padding-right: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      span {
        background-color: #aaa !important;
      }
    }
  }

  input:checked ~ span:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: #007970 !important;
  }

  span {
    margin-left: 0.5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      margin-top: -2px;
      display: none;
    }
  }

  span::after {
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  p {
    position: absolute;
    color: #ff5050;
    font-size: 9px;
    bottom: 0;
    left: 1rem;
  }
`;
