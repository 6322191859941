import styled from 'styled-components';

type FieldProps = {
  width: string;
  align?: string;
};

export const Card = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 3rem;
  border-bottom: 4px dashed rgba(0, 121, 112, 0.51);

  & + & {
    margin-top: 3rem;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 3rem;
  }

  & + h1 {
    margin-top: 3rem;
  }
`;

export const Column = styled.div`
  position: relative;
  width: 45%;
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: 4rem;
  }

  h1,
  div,
  input,
  span,
  label,
  textarea {
    background: #b8faf3;
  }
`;

export const Field = styled.div<FieldProps>`
  width: ${(prosp) => prosp.width};
  position: relative;
  border-radius: 2px;
  background: transparent;
  border-bottom: 1px solid #007970;

  h1 {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    top: -1.3rem;
    left: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  p {
    width: 100%;
    color: #007970;
    font-size: 1.8rem;
    padding: 0.9rem;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    margin-top: 5px;

    a {
      text-decoration: underline;
    }
  }
`;

export const FieldArea = styled.div`
  width: 50%;
  position: relative;
  border-radius: 2px;
  background: transparent;
  border: 0px solid #007970;
  height: auto;
  min-height: 12rem;
  -webkit-box-shadow: inset 0px 0px 2px 2px rgba(0, 121, 112, 0.51);
  box-shadow: inset 0px 0px 2px 2px rgba(0, 121, 112, 0.51);

  h1 {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    top: -1.3rem;
    left: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  p {
    max-width: 100%;
    color: #007970;
    font-size: 1.8rem;
    padding: 0.9rem;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
`;

export const EditButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  background: #b8faf3;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;

  > img {
    width: 2.5rem;
    height: 2.5rem;
  }

  &:hover {
    background: #3cf0de;
  }
`;
