import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div`
  > form {
    width: 100%;
    > div {
      margin-top: 10px;

      > span {
        color: #007970;
        font-size: 2rem;
      }

      > textarea {
        width: 100%;
        resize: none;
        color: #007970;
        font-size: 1.8rem;
        border: 1px solid rgba(0, 121, 112, 0.8);
        border-radius: 5px;
      }

      > div {
        display: flex;
        align-items: center;

        > span {
          color: #007970;
          font-size: 2rem;
        }

        > div {
          margin-top: 5px;
          > input {
            color: #007970;
            font-size: 2rem;
            border-radius: 5px;
            width: 6rem;
            border: 1px solid rgba(0, 121, 112, 0.8);
            text-align: right;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            &::-webkit-input-placeholder {
              color: rgba(0, 121, 112, 0.8);
            }
          }
        }
      }
    }
    > p {
      color: #ff5050;
      font-size: 9px;
      left: 1rem;
    }
    select {
      background: transparent;
      padding: 5px;
      font-size: 1.8rem;
      border: 1px solid #007970;
      color: #007970;
      border-radius: 5px;
      margin-left: 10px;
    }
  }
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  width: 100%;
  padding: 0.7rem;
  font-size: 1.8rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  &:hover {
    background: ${(props) => props.bghover};
  }
`;
