/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

import chevron from '../../assets/chevron.svg';

type PropsUrl = {
  pathname: string;
  state: any;
};

type BackProps = {
  url?: string | PropsUrl;
};

const BackButton: React.FC<BackProps> = ({ url }) => {
  const history = useHistory();

  const handleBack = () => {
    if (url) {
      history.push(url);
    } else {
      history.goBack();
    }
  };

  return (
    <Container title="voltar" onClick={handleBack}>
      <img src={chevron} alt="Icon de seta back" />
    </Container>
  );
};

export default BackButton;
