import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div`
  margin-top: 100px;
  position: relative;
  background: #fff;
  width: 300px;
  max-width: 300px;
  height: auto;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 3rem;
  }
`;

export const Widget = styled.div`
  display: flex;

  @media (max-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const Form = styled.form`
  flex: 1;
`;

export const FormLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: 3rem;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 200px;
  padding: 1rem;
  font-size: 1.8rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const Icon = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > img {
    width: 200px;
    height: 200px;
    //border: 2px solid #007970;
  }

  p {
    font-size: 2.2rem;
    min-width: 200px;
    text-align: center;
    z-index: 5;
  }
`;

export const Close = styled.div`
  position: absolute;
  top: -8px;
  right: -7px;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;
