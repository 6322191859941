import styled from 'styled-components';

type LineProps = {
  bg: string;
};

export const Line = styled.tr<LineProps>`
  background-color: ${(props) => props.bg};
`;

export const Index = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    animation: alert 2s linear infinite;
  }

  @keyframes alert {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
