import styled from 'styled-components';

type FieldProps = {
  width: string;
  align?: string;
};

export const Container = styled.div`
  position: relative;
  background: #b8faf3;
  padding: 2rem;
  margin-bottom: 3rem;

  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 5px 5px 5px rgba(60, 240, 222, 0.75);
  -moz-box-shadow: 0px 5px 5px 5px rgba(60, 240, 222, 0.75);
  -webkit-box-shadow: 0px 5px 5px 5px rgba(60, 240, 222, 0.75);

  > h1 {
    width: 100%;
    font-size: 2.6rem;
    flex: 1;
    padding-bottom: 3rem;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & + & {
    margin-top: 3rem;
  }

  & + h1 {
    margin-top: 3rem;
  }
`;

export const Field = styled.div<FieldProps>`
  width: ${(prosp) => prosp.width};
  position: relative;
  border-radius: 2px;
  background: transparent;
  border: 0px solid #007970;

  h1 {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    top: -1.3rem;
    left: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  p {
    width: 100%;
    color: #007970;
    font-size: 1.8rem;
    padding: 0.9rem;
    text-align: ${(props) => (props.align ? props.align : 'left')};

    a {
      text-decoration: underline;
    }
  }
`;
