/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { Body, Close, MemberItem } from './styles';

import { Household } from '../../types/Household';

import Modal from '../Modal';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

type GroupMembersModalProps = {
  group: '1' | '2' | '3' | '4';
  closeAction: () => void;
};

const GroupMembersModal: React.FC<GroupMembersModalProps> = ({
  group,
  closeAction,
}) => {
  const [householdsData, setHouseholdsData] = useState(
    [] as { id: string; name: string }[],
  );

  const { addToast } = useToast();

  const history = useHistory();

  const getHouseholdData = useCallback(
    async (g: string) => {
      try {
        const response = await api.get('household');

        const householdsDataFiltered = response.data.filter(
          (household: Household) =>
            (household.market_session &&
              household.market_session.sessionType === g) ||
            (household.market_session &&
              g !== '3' &&
              household.market_session?.sessionType === '4'),
        );

        const householdsDataStrg = householdsDataFiltered.map(
          (household: Household) => {
            let typeGroup =
              household &&
              household.market_session &&
              household.market_session.sessionType === '1'
                ? 'ABC'
                : '';

            typeGroup =
              household &&
              household.market_session &&
              household.market_session.sessionType === '2'
                ? '123'
                : typeGroup;

            typeGroup =
              household &&
              household.market_session &&
              household.market_session.sessionType === '3'
                ? 'Porta Mensal'
                : typeGroup;

            typeGroup =
              household &&
              household.market_session &&
              household.market_session.sessionType === '4'
                ? 'ABC | 123'
                : typeGroup;

            return {
              id: household.id,
              name: `${household.name} (${household.int_num}) - ${typeGroup}`,
            };
          },
        );

        setHouseholdsData(householdsDataStrg);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter agregados',
          description: { code: 306 },
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    getHouseholdData(group);
  }, [group, getHouseholdData]);

  return (
    <Modal visible>
      <Body>
        <Close>
          <button type="button" onClick={closeAction}>
            &#x2715;
          </button>
        </Close>
        <h1>
          Membros Grupo {group === '1' ? 'Letras' : ''}
          {group === '2' ? 'Número' : ''}
          {group === '3' ? 'Porta Mensal' : ''}
        </h1>
        <div>
          {householdsData.map((h) => (
            <MemberItem
              onClick={() => {
                history.push({
                  pathname: 'agregados/ver',
                  state: {
                    id: h.id,
                  },
                });
              }}
              type="button"
            >
              {h.name}
            </MemberItem>
          ))}
        </div>
      </Body>
    </Modal>
  );
};

export default GroupMembersModal;
