import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Widget = styled.div`
  margin-top: 2rem;
  width: 140rem;
  max-width: 140rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const Top = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  > button {
    border: none;
    background: #007970;
    color: #3cf0de;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-size: 1.8rem;
    margin-left: 6rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);

    img {
      width: 2rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 3rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  row-gap: 2rem;
`;

export const ModalBox = styled.div`
  position: relative;
  background: #fff;
  max-height: 50rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 1rem;

  > img {
    width: 22.4rem;
    min-width: 22.4rem;
    max-width: 22.4rem;
    height: 28rem;
    min-height: 28rem;
    max-height: 28rem;
  }

  form {
    > div {
      margin-top: 2rem;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: -8px;
  right: -7px;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const Button = styled.button`
  color: #3cf0de;
  background: #007970;
  width: 100%;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: #00665f;
  }
`;
