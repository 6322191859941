import React, { forwardRef } from 'react';

import { Container } from './styles';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error: string | undefined;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, ...rest }, ref) => {
    return (
      <Container isError={!!error}>
        <input ref={ref} {...rest} />
        <p>{error}</p>
      </Container>
    );
  },
);

export default Input;
