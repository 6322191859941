/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../components/InputLogin';
import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import { Container, LogDiv, FormLogin, Submit } from './styles';

import logo from '../../assets/logo.svg';

type InputData = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().required('* nome de utilizador obrigatório'),
  password: yup.string().required('* palavra-passe obrigatória'),
});

const Login: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InputData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const { singIn } = useAuth();

  const handleOnSubmit = async (data: InputData) => {
    try {
      await singIn(data);
    } catch (e: any) {
      addToast({
        type: 'info',
        title: 'Aviso !',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  return (
    <Container>
      <LogDiv>
        <img src={logo} alt="Logo da SOUMA" />
        <FormLogin onSubmit={handleSubmit(handleOnSubmit)}>
          <Input
            type="text"
            placeholder="Utilizador"
            {...register('username')}
            error={errors.username?.message}
          />
          <Input
            type="password"
            placeholder="Palavra-passe"
            {...register('password')}
            error={errors.password?.message}
          />
          <Submit type="submit">Entrar</Submit>
        </FormLogin>
      </LogDiv>
    </Container>
  );
};

export default Login;
