import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Household } from '../../../types/Household';

import { styles } from './styles';

type FileProps = {
  household: Household[];
};

const File: React.FC<FileProps> = ({ household }) => {
  let count = 0;
  let break_line = false;

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.header}>
          <Image src="souma.png" style={styles.image} />
          <Text style={styles.text}>Mapa de Agregados</Text>
        </View>
        {household
          .sort((a: Household, b: Household) =>
            a.name.localeCompare(b.name, undefined, { numeric: true }),
          )
          .map((h) => {
            if (break_line) {
              break_line = false;
            }

            if (h.household_members) {
              const total = count + 2 + h.household_members.length;

              if (total > 18) {
                count = 0;
                break_line = true;
              }

              count += 2 + h.household_members.length;
            }

            return (
              h && (
                <View key={h.id} break={break_line}>
                  <View style={styles.route_table_th}>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '4%',
                        textAlign: 'center',
                      }}
                    >
                      #
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '70%' }}
                    >
                      Nome - ({h.int_num})
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '26%' }}
                    >
                      Parentesco
                    </Text>
                  </View>
                  <View style={styles.route_table_td}>
                    <Text
                      style={{
                        ...styles.route_table_td_text,
                        width: '4%',
                        textAlign: 'center',
                      }}
                    >
                      1
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_td_text,
                        width: '70%',
                      }}
                    >
                      {h.name}
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_td_text,
                        width: '26%',
                      }}
                    >
                      {h.kinship}
                    </Text>
                  </View>
                  {h.household_members &&
                    h.household_members.length > 0 &&
                    h.household_members.map((member, index) => {
                      return (
                        <View key={member.id} style={styles.route_table_td}>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '4%',
                              textAlign: 'center',
                            }}
                          >
                            {index + 2}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '70%',
                            }}
                          >
                            {member.user.name}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '26%',
                            }}
                          >
                            {member.kinship}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              )
            );
          })}
      </Page>
    </Document>
  );
};

export default File;
