/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { format, parseISO, differenceInYears } from 'date-fns';
import { pt } from 'date-fns/locale';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import { Container, Field, Line } from './styles';

import { User } from '../../types/Users';

type UserDataProps = {
  userId: string;
};

const UserData: React.FC<UserDataProps> = ({ userId }) => {
  const [user, setUser] = useState({} as User);

  const { addToast } = useToast();

  const getUserData = async (id: string) => {
    try {
      const response = await api.get(`user/${id}`);

      setUser({
        ...response.data,
        birth_date: `${format(
          parseISO(response.data.birth_date),
          "dd'/'MM'/'yyyy",
          {
            locale: pt,
          },
        )} -
       ${differenceInYears(
         new Date(),
         parseISO(response.data.birth_date),
       )} anos`,
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na obtenção dos dodos do utilizador',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };
  useEffect(() => {
    getUserData(userId);
  }, []);

  return (
    <Container>
      <h1>Dados do Utente</h1>
      <Line>
        <Field width="50%">
          <h1>Nome</h1>
          <p>{user && user.name}</p>
        </Field>
        <Field width="20%">
          <h1>Data de Nascimento</h1>
          <p>{user && user.birth_date}</p>
        </Field>
        <Field width="22%">
          <h1>Contacto</h1>
          <p>{user && user.contact}</p>
        </Field>
      </Line>
      <Line>
        <Field width="35%">
          <h1>Morada</h1>
          <p>{user && user.address}</p>
        </Field>
        <Field width="12%">
          <h1>Código Postal</h1>
          <p>{user && user.zip}</p>
        </Field>
        <Field width="20%">
          <h1>Localidade</h1>
          <p>{user && user.location}</p>
        </Field>
        <Field width="30%">
          <h1>E-mail</h1>
          <p>{user && user.email}</p>
        </Field>
      </Line>
    </Container>
  );
};

export default UserData;
