export interface CodesOpt {
  code:
    | 100
    | 101
    | 102
    | 103
    | 104
    | 105
    | 106
    | 107
    | 108
    | 109
    | 200
    | 201
    | 202
    | 203
    | 204
    | 205
    | 206
    | 207
    | 208
    | 209
    | 210
    | 211
    | 212
    | 213
    | 214
    | 215
    | 300
    | 301
    | 302
    | 303
    | 304
    | 305
    | 306
    | 307
    | 308
    | 309
    | 310
    | 311
    | 312
    | 313
    | 400
    | 401
    | 402
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 500
    | 501
    | 502
    | 503
    | 504
    | 505
    | 506
    | 507
    | 508
    | 509
    | 600
    | 601
    | 602
    | 603
    | 604
    | 605
    | 606
    | 607
    | 608
    | 609
    | 700
    | 701
    | 702
    | 703
    | 704
    | 705
    | 706
    | 707
    | 708
    | 709
    | 730
    | 731
    | 732
    | 733
    | 734
    | 750
    | 751
    | 752
    | 755
    | 756
    | 757
    | 758
    | 759
    | 770
    | 771
    | 781
    | 782
    | 783
    | 723
    | 725
    | 761
    | 762
    | 800
    | 801
    | 802
    | 803
    | 804
    | 805
    | 806
    | 807
    | 808
    | 809
    | 820
    | 821
    | 830
    | 831
    | 832
    | 1000;
}

export const codes = {
  // Codes - 100 Utilizadores
  100: 'Utilizador ou palavra-passe incorreta!',
  101: 'Conta não tem permissão!',
  102: 'Não tem informação válida!',
  103: 'Não tem informação válida!',
  104: 'Conta não existe!',
  105: 'E-mail já está a ser utilizado!',
  106: 'Conta criada com sucesso!',
  107: 'Conta atualizada com sucesso!',
  108: 'Falha a obter dados das contas!',
  109: '',
  // Codes - 200 Utentes
  200: 'Utente não existe!',
  201: 'Número de Cartão de Cidadão já está a ser utilizado!',
  202: 'Número da Segurança Social já está a ser utilizado!',
  203: 'Número de Contribuinte já está a ser utilizado!',
  204: 'Utente já tem imagem!',
  205: 'Utente não tem imagem!',
  206: 'Falha ao obter lista de utentes',
  207: 'Falha a obter dados de utente',
  208: 'Utente criado com sucesso!',
  209: 'Utente atualizados com sucesso!',
  210: 'Nota adicinada ao utente com sucesso!',
  211: 'Falha a adicionar nota ao utente!',
  212: 'Nota removida com sucesso!',
  213: 'Falha ao remover nota!',
  214: 'Nota não existe!',
  215: 'Não foi possivel apagar nota!',
  // Codes - 300 Agregados
  300: 'Falha a obter dados do agragado',
  301: 'Utente já é titular de um agregado!',
  302: 'Agregado não existe!',
  303: 'Utente já é membro de um agregado!',
  304: 'Utente não existe neste agregado!',
  305: 'Não foi possivel apagar membro deste agregado!',
  306: 'Falha ao obter lista de de agregados',
  307: '',
  308: 'Agregado criado com sucesso!',
  309: 'Agregado editado com sucesso!',
  310: 'Utente adicinado com sucesso ao agregado!',
  311: 'Utente removido com sucesso do agregado!',
  312: 'Data definida com sucesso!',
  313: 'Falha ao definir da data!',
  // Codes - 400 Apoios
  400: 'O utente já tem esse apoio!',
  401: 'Esse apoio não existe!',
  402: 'Apoio adicionado com sucesso!',
  403: 'Apoio editado com sucesso!',
  404: 'Documento já existe!',
  405: 'Falha a atualizar apoio!',
  406: '',
  407: '',
  408: 'Registo criado com Sucesso!',
  409: '',
  // Codes - 500 Produtos
  500: 'Produto não existe!',
  501: 'Stock adiconado com sucesso!',
  502: 'Estado do produto atualizado com sucesso!',
  503: '',
  504: '',
  505: 'Falha ao obter lista de produtos',
  506: 'Falha ao obter lista de categorias',
  507: '',
  508: 'Produto criado com sucesso!',
  509: 'Produto editado com sucesso!',
  // Codes - 600
  600: 'Falha na obtenção dos logs',
  601: '',
  602: '',
  603: '',
  604: '',
  605: '',
  606: '',
  607: '',
  608: '',
  609: '',
  //
  700: 'Acesso à mercearia criado com sucesso!',
  701: 'Acesso não existe!',
  702: 'Acesso já existe!',
  703: 'Alteração feita com sucesso!',
  704: 'Renovação de todos os saldos feita com sucesso!',
  705: 'Renovação feita com sucesso!',
  706: '',
  707: '',
  708: '',
  709: '',
  730: 'Encomenda tipo criada com sucesso!',
  731: 'Enconenda tipo atualizada com sucesso!',
  732: 'Erro na criação da encomenda tipo!',
  733: 'Erro na atualização da encomenda tipo',
  734: 'Encomendas tipo foram geradas com sucesso!',
  750: 'Erro a obter dados iniciais do plataforma!',
  751: 'Erro a obter dados das encomendas!',
  752: 'Ocorreu um erro no processamento da encomenda!',
  755: 'Encomenda cancelada com sucesso!',
  756: 'Encomenda aceite com sucesso!',
  757: 'Encomenda entregue com sucesso!',
  761: 'Encomendas aceites com sucesso!',
  762: 'Encomendas entregues com sucesso!',
  758: 'Encomenda não entregue!',
  759: 'Ocorreu um erro a obter a lista de transações!',
  770: 'Creditação feita com sucesso!',
  771: 'Ocorreu um erro na creditação!',
  781: 'Ocorreu um erro a obter o estado da mercearia!',
  782: 'Ocorreu um erro a atualizar o estado da mercearia!',
  783: 'Estado da mercearia atualizado com sucesso!',
  723: 'Ocorreu um erro na renovação de todos os Soumas!',
  725: 'Ocorreu um erro na renovação dos Soumas!',
  //
  800: 'Rota criada com sucesso!',
  801: 'Rota editada com sucesso!',
  802: 'Volta criada com sucesso!',
  803: 'Volta editada com sucesso!',
  804: 'Falha ao obter dados da rota!',
  805: 'Falha a obter rotas!',
  806: 'Volta removida com sucesso!',
  807: '',
  808: '',
  809: '',
  820: 'Falha a obter configurações da semana de rotas!',
  821: 'Configurações da semana de rota atualizadas com sucesso!',
  830: 'Semana criada com sucesso!',
  831: 'Semana editada com sucesso!',
  832: 'Semana terminada com sucesso!',
  // Codes - 1000
  1000: 'Erro desconhecido, tente novamente mais tarde!',
};
