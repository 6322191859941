import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html{
    font-size: 62.5%;
  }

  body {
    margin: 0;
    padding: 0;
    background: #FFF;
    color: #007970;
    //#3CF0DE;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #007970;
  }

  /* @media (max-width: 1000px){
    html{
    font-size: 50%;
    }
  }

  @media (max-width: 800px){
    html{
      font-size: 43.75%;
    }
  }

  @media (max-width: 700px){
    html{
      font-size: 62.5%;
    }
  } */

`;
