import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div`
  margin-top: 50px;
  position: relative;
  background: #fff;
  width: 700px;
  max-width: 700px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem;

  h1 {
    width: 100%;
    text-align: left;
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
`;

export const Widget = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const Form = styled.form`
  flex: 1;
  padding-left: 2rem;
`;

export const FormLine = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5rem;

  & + & {
    margin-top: 3rem;
  }
`;

export const ItemsBox = styled.div`
  width: 100%;
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

export const RowButton = styled.button`
  margin-left: 1.5rem;
  width: 3rem;
  height: 3rem;
  border: none;
  border-radius: 50%;
  background: #007970;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  color: #3cf0de;
  font-size: 20px;
`;

export const RowButtonsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonBox = styled.div`
  margin-top: 3rem;
  width: 140rem;
  max-width: 140rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 20rem;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;
