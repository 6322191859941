import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { Routes } from '../../../types/Routes';

import { styles } from './styles';

type FileProps = {
  routes: Routes[];
};

const File: React.FC<FileProps> = ({ routes }) => {
  let count = 0;
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="portrait">
        {routes
          .sort((a: Routes, b: Routes) =>
            a.routeNum.localeCompare(b.routeNum, undefined, { numeric: true }),
          )
          .map((route) =>
            route.turns.map((turn) => {
              let names: string[] = [];
              if (turn.household) {
                names = turn.household.name.toUpperCase().trim().split(' ');
              }
              if (count === 5) {
                count = 2;
              } else {
                count += 1;
              }
              return (
                <View style={styles.tag_box} key={turn.id} break={count === 5}>
                  <Text style={styles.tag_route_text}>
                    ROTA: {route.routeNum}
                  </Text>
                  <Text style={styles.tag_route_householder}>
                    {names[0]} {names.length > 1 && names[names.length - 1]}
                  </Text>
                  <View style={styles.tag_route_householder_num_box}>
                    <Text style={styles.tag_route_householder_num_text}>
                      {turn.household &&
                        turn.household.household_members &&
                        turn.household.household_members.length + 1}
                    </Text>
                  </View>
                  <View style={styles.tag_turn_tags_left}>
                    {turn.tags &&
                      turn.tags.length > 0 &&
                      turn.tags
                        .filter(
                          (t) =>
                            t.name.toLocaleLowerCase().includes('manteiga') ||
                            t.name.toLocaleLowerCase().includes('iogurte') ||
                            t.name.toLocaleLowerCase().includes('carne'),
                        )
                        .map((tag) => (
                          <Text style={styles.tag_turn_tags_text} key={tag.id}>
                            {tag.name}
                          </Text>
                        ))}
                  </View>
                  <View style={styles.tag_turn_tags}>
                    {turn.tags &&
                      turn.tags.length > 0 &&
                      turn.tags
                        .filter(
                          (t) =>
                            t.name.toLocaleLowerCase().includes('fruta') ||
                            t.name.toLocaleLowerCase().includes('legumes') ||
                            t.name.toLocaleLowerCase().includes('pão'),
                        )
                        .map((tag) => (
                          <Text style={styles.tag_turn_tags_text} key={tag.id}>
                            {tag.name}
                          </Text>
                        ))}
                  </View>
                  <View style={styles.tag_route_info}>
                    <Text style={styles.tag_route_info_text}>
                      {turn.canCook ? `COZINHA` : 'NÃO COZINHA'}
                    </Text>
                    <Text style={styles.tag_route_info_text}>
                      {turn.note && `NOTAS: ${turn.note.toUpperCase()}`}
                      {turn.onceMonth && '(1 vez mês)'}
                    </Text>
                  </View>
                </View>
              );
            }),
          )}
      </Page>
    </Document>
  );
};

export default File;
