/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';

import UserAvatar from '../UserAvatar';

import { Card, ViewButton } from './styles';

import star from '../../assets/star.svg';

import view from '../../assets/view.svg';

import { User } from '../../types/Users';

type CartProps = {
  user: User;
  kinship: string;
  owner?: boolean;
  handleModalConfirm?(): void;
};

const HouseHoldCard: React.FC<CartProps> = ({
  user,
  kinship,
  owner = false,
  handleModalConfirm,
}) => {
  const history = useHistory();

  return (
    <Card active={user.active}>
      {!owner && (
        <button type="button" onClick={handleModalConfirm}>
          &#x2715;
        </button>
      )}
      <UserAvatar path={user.avatar ? user.avatar.path : ''} />
      <div>
        <h1>{user.name}</h1>
        <p>{kinship}</p>
        {owner && <img src={star} alt="Imagem de uma estrela" />}
        <ViewButton
          title="ver utente"
          type="button"
          onClick={() =>
            history.push({
              pathname: '../utentes/ver',
              state: {
                id: user.id,
              },
            })
          }
        >
          <img src={view} alt="Imagem de um olho" />
        </ViewButton>
      </div>
    </Card>
  );
};

export default HouseHoldCard;
