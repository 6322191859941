/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import api from '../config/api';
import { useToast } from './ToastContext';
import { Account } from '../types/account';

type Request = {
  username: string;
  password: string;
};

interface AuthState {
  token: string;
  account: Account | null;
}

type AccountContextProps = {
  account: Account | null;
  updateAccountData(data: Account): void;
  singIn(data: Request): Promise<void | Error>;
  singOut(): void;
};

const AccountContext = createContext<AccountContextProps>(
  {} as AccountContextProps,
);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@SoumaApp:token');
    const account = localStorage.getItem('@SoumaApp:account');

    if (token && account) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { token, account: JSON.parse(account) };
    }
    return {} as AuthState;
  });

  const checkToken = useCallback(
    async (account: Account): Promise<boolean> => {
      if (account) {
        try {
          await api.get(`account/${account.id}`);

          return true;
        } catch (e: any) {
          localStorage.removeItem('@SoumaApp:token');
          localStorage.removeItem('@SoumaApp:account');
          setData({} as AuthState);
          addToast({
            type: 'info',
            title: 'Aviso',
            description:
              e.response && e.response.data.error
                ? e.response.data.error
                : '500',
          });
          return false;
        }
      }
      setData({} as AuthState);
      return false;
    },
    [addToast],
  );

  const updateAccountData = useCallback((dataAccount: Account) => {
    setData((prevState) => {
      return {
        token: prevState.token,
        account: dataAccount,
      };
    });
  }, []);

  const singIn = useCallback(
    async ({ username, password }: Request): Promise<void | Error> => {
      const response = await api.post('/login', { username, password });

      const { token, account } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem('@SoumaApp:token', token);
      localStorage.setItem('@SoumaApp:account', JSON.stringify(account));

      setData({ token, account });

      history.push('/dashboard');
    },
    [history],
  );

  const singOut = useCallback(() => {
    localStorage.removeItem('@SoumaApp:token');
    localStorage.removeItem('@SoumaApp:user');

    setData({} as AuthState);
  }, []);

  useEffect(() => {
    if (data.account) {
      checkToken(data.account);
    }
  }, [checkToken, data]);

  return (
    <AccountContext.Provider
      value={{
        updateAccountData,
        account: data.account,
        singIn,
        singOut,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

function useAuth(): AccountContextProps {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
