import React, { forwardRef } from 'react';

import { Container } from './styles';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  title: string;
  width: string;
  error: string | undefined;
  borderType?: boolean;
  textAlign?: string;
  borderSize?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { width, title, error, borderType, textAlign, borderSize, ...rest },
    ref,
  ) => {
    return (
      <Container
        borderType={borderType || false}
        width={width}
        isError={!!error}
        borderSize={borderSize}
        text_aling={textAlign || undefined}
      >
        <span>{title}</span>
        <input ref={ref} {...rest} />
        <p>{error}</p>
      </Container>
    );
  },
);

export default Input;
