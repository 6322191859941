import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Widget = styled.div`
  margin-top: 1.5rem;
  width: 140rem;
  max-width: 140rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const Chart = styled.div`
  width: 1000px;
  height: 400px;
`;

export const TooltipFinal = styled.div`
  width: 150px;
  height: 70px;
  background-color: #fff;
  text-align: center;

  font-size: 18px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px solid rgba(0, 121, 112, 0.4);
`;

export const Cards = styled.div`
  margin-top: 10px;
  width: 850px;
  height: 220px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
`;

export const Card = styled.div`
  display: flex;
  width: 200px;
  height: 100px;
  background: #b8faf3;
  border-radius: 20px;
  position: relative;

  padding: 1.5rem;

  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow: 5px 5px 5px rgba(60, 240, 222, 0.75);

  h3 {
    font-size: 20px;
  }

  h2 {
    margin-top: 5px;
    font-size: 26px;
  }
`;

export const ImageFront = styled.img`
  width: 100px;
  position: absolute;
  right: 0;
  opacity: 0.3;
  top: 0;
`;

export const Dates = styled.div`
  width: 800px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 18px;
      margin-right: 5px;
    }

    > input {
      padding: 0.9rem;
      color: #007970;
      font-size: 1.8rem;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      &::-webkit-input-placeholder {
        color: rgba(0, 121, 112, 0.8);
      }
    }
  }
`;

export const ErrorDate = styled.p`
  color: #ff5050;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

export const Total = styled.div`
  border-top: 1px solid rgba(60, 240, 222, 0.9);
  margin-top: 10px;
  display: flex;
  width: 850px;
  position: relative;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 22px;
  }
`;
