/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Input from '../../Input';

import productImageAlt from '../../../assets/productImageAlt.svg';

import {
  Container,
  Widget,
  Form,
  FormLine,
  ButtonBox,
  Button,
  Icon,
  Close,
} from './styles';

const schema = yup.object().shape({
  stock: yup
    .number()
    .moreThan(0, 'O stock tem de ser maior que 0')
    .required('* o stock é obrigatório'),
});

type ProductStockProps = {
  id: string;
  close(): void;
  handleUpdateProduct(id: string, status: 'update' | 'add'): void;
  iconUrl: string | null;
  productName: string;
};

const ProductStock: React.FC<ProductStockProps> = ({
  id,
  close,
  handleUpdateProduct,
  iconUrl,
  productName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [iconDisplay, setIconDisplay] = useState(productImageAlt);
  const [productNameToDisplay, setProductNameToDisplay] = useState('');

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<{ stock: number }>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const handleClose = useCallback(() => {
    reset({ stock: 0 });
    setIconDisplay(productImageAlt);
    setProductNameToDisplay('');
    close();
  }, []);

  const handleOnSubmit = useCallback(
    async (data: { stock: number }) => {
      setIsLoading(true);
      try {
        if (id) {
          const response = await api.put(`market/product/stock/${id}`, {
            ...data,
          });

          if (response.data) {
            addToast({
              type: 'success',
              title: 'Stock adicionado',
              description: { code: 501 },
            });
            reset({ stock: 0 });
            setIsLoading(false);
            handleUpdateProduct(response.data.id, 'update');
            handleClose();
          }
        }
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Erro na adição de stock',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
        setIsLoading(false);
      }
    },
    [id],
  );

  useEffect(() => {
    if (iconUrl) {
      setIconDisplay(iconUrl);
    } else {
      setIconDisplay(productImageAlt);
    }
    setProductNameToDisplay(productName);
  }, [productName, iconUrl]);

  return (
    <Container>
      <h1>Adicionar Stock</h1>
      <Widget>
        <Form id="stock_product" onSubmit={handleSubmit(handleOnSubmit)}>
          <Close>
            <button type="button" onClick={handleClose}>
              &#x2715;
            </button>
          </Close>
          <FormLine>
            <Icon>
              <img src={iconDisplay} alt="icon do produto" />
              <p>{productNameToDisplay}</p>
            </Icon>
          </FormLine>
          <FormLine>
            <Input
              width="200px"
              title="Stock (unidades)"
              type="number"
              textAlign="center"
              defaultValue="0"
              {...register('stock')}
              error={errors.stock?.message}
            />
          </FormLine>
        </Form>
      </Widget>
      <ButtonBox>
        {isLoading ? (
          <Button
            form="stock_product"
            color="#3cf0de"
            bgcolor={isLoading ? '#00665f' : '#007970'}
            bghover="#00665f"
            disabled={isLoading}
          >
            A carregar...
          </Button>
        ) : (
          <Button
            form="stock_product"
            type="submit"
            color="#3cf0de"
            bgcolor="#007970"
            bghover="#00665f"
          >
            Adicionar
          </Button>
        )}
      </ButtonBox>
    </Container>
  );
};

export default ProductStock;
