import styled from 'styled-components';

type ModalProps = {
  visible: boolean;
};

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div<ModalProps>`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 140rem;
  max-width: 140rem;
  height: 70rem;
  margin-top: 10rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  > h1 {
    font-size: 24px;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlignFormLines = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const FormLine = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    align-self: flex-start;
    margin-left: 10px;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  & + & {
    margin-top: -2rem;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 15rem;
  padding: 0.7rem;
  font-size: 2rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: #ff5050;
  font-size: 9px;
  bottom: 0;
  left: 1rem;
`;

export const RadioBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const RadioTypeRoute = styled.div`
  display: flex;

  img {
    width: 3rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const RoutesBox = styled.div`
  width: 100%;

  > h1 {
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  div {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
  }
`;

export const RouteCircle = styled.div`
  width: 6rem;
  height: 6rem;
  border: 2px solid #007970;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding: 0.5rem;
    border-radius: 5px;
  }

  label {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    position: relative;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background-color: #88f6eb;
    }
  }

  input:checked ~ span:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: #3cf0de;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
  }

  span::after {
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
