/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';

import { useLocation } from 'react-router-dom';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import OrderLine from '../../components/Order/OrderLine';
import loading from '../../assets/loading01.svg';
import add from '../../assets/add.svg';
import page_left from '../../assets/page_left.svg';
import page_right from '../../assets/page_right.svg';

import {
  Container,
  Widget,
  Loading,
  TableOrder,
  Title,
  Top,
  Search,
  InfoCount,
  PageBox,
} from './styles';
import { Order } from '../../types/Order';
import Th from '../../components/Table/Th';
import Modal from '../../components/Modal';
import OrderModal from '../../components/Order/OrderHistory';

let debounce: NodeJS.Timeout;

const Dashboard: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const location = useLocation<{ search: string }>();
  const [textS, setTextS] = useState(
    location.state && location.state.search ? location.state.search : '',
  );

  const { addToast } = useToast();

  const handleSelecOrder = useCallback(async (id: string) => {
    setSelectedOrderId(id);
    setOrderModalVisible(true);
  }, []);

  const handlePage = (data: number) => {
    if (page + data > 0 && page + data <= lastPage) {
      setPage((prevState) => prevState + data);
    }
  };

  const handleSeach = (e: string) => {
    clearTimeout(debounce);

    const searchText = e;
    searchText
      ? (debounce = setTimeout(() => {
          setTextS(searchText);
        }, 500))
      : (debounce = setTimeout(() => {
          setTextS('');
        }, 500));
  };

  const getOrders = useCallback(
    async (p: number, t: string) => {
      setIsLoading(true);
      try {
        const response = await api.get(`/orders?page=${p}&text=${t}`);
        setOrders(response.data.orders);

        setLastPage(response.data.lastPage);

        setIsLoading(false);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter encomendas',
          description: { code: 751 },
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    getOrders(page, textS);
  }, [page, textS]);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Encomendas</h1>
              <InfoCount />
            </Title>
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={(e) => handleSeach(e.currentTarget.value)}
                defaultValue={textS}
              />
              <button
                type="button"
                title="Pesquisar"
                onClick={() => setTextS('')}
              >
                <img
                  src={add}
                  alt="add"
                  style={{ transform: 'rotate(45deg)' }}
                />
              </button>
            </Search>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <TableOrder>
              <table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="center" width="15%">
                      Referência
                    </Th>
                    <Th talign="center" width="25%">
                      Agregado
                    </Th>
                    <Th talign="center" width="15%">
                      Estado
                    </Th>
                    <Th talign="center" width="10%">
                      Valor
                      <p>
                        <small>(Souma Créditos)</small>
                      </p>
                    </Th>
                    <Th talign="center" width="25%">
                      Data
                    </Th>
                    <Th talign="center" width="5%" />
                  </tr>
                </thead>
                <tbody>
                  {orders.length > 0 &&
                    orders.map((order, index) => (
                      <OrderLine
                        key={order.id}
                        order={order}
                        index={orders.length - index}
                        action={() => handleSelecOrder(order.id)}
                      />
                    ))}
                </tbody>
              </table>
            </TableOrder>
          )}
          <PageBox>
            <button
              type="button"
              title="anterior"
              onClick={() => handlePage(-1)}
            >
              <img src={page_left} alt="seta para a esquerda" />
            </button>
            <p>
              {page} - {lastPage}
            </p>
            <button type="button" title="próximo" onClick={() => handlePage(1)}>
              <img src={page_right} alt="seta para a direita" />
            </button>
          </PageBox>
        </Widget>
        {orderModalVisible && (
          <Modal visible={orderModalVisible}>
            <OrderModal
              close={() => setOrderModalVisible(false)}
              orderId={selectedOrderId}
              reload={() => getOrders(page, textS)}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
