/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';

import { useHistory } from 'react-router-dom';
import { Container, Squad, Box } from './styles';

import { MarketSession } from '../../types/Household';
import PasswordMarket from './Password';
import StateMarket from './State';
import CoinMarket from './Coin';
import CoinValueMarket from './CoinValue';

import transacoes from '../../assets/transacoes.svg';
import order from '../../assets/order.svg';
import crediting from '../../assets/crediting.svg';

import Modal from '../Modal';
import Transaction from '../Transactions';
import Crediting from './Crediting';
import SessionType from './SessionType';

type PanelProps = {
  marketSession: MarketSession;
  householdName: string;
};

const PanelMarkerSession: React.FC<PanelProps> = ({
  marketSession,
  householdName,
}) => {
  const [marketSessionData, setMarketSessionData] = useState(
    {} as MarketSession,
  );
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [creditingModal, setCreditingModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (marketSession) {
      setMarketSessionData(marketSession);
    }
  }, [marketSession]);

  return (
    <Container>
      <h1>Gestão Acesso Mercearia</h1>
      <div>
        <Squad>
          <h2>Utilizador</h2>
          <Box>
            <p>{marketSessionData.username}</p>
          </Box>
        </Squad>
        <Squad>
          <h2>Palavra-Passe</h2>
          {marketSessionData.password ? (
            <PasswordMarket
              setMarketSession={setMarketSessionData}
              household_id={marketSessionData.household_id}
              passwordProp={marketSessionData.password}
            />
          ) : (
            <PasswordMarket
              setMarketSession={setMarketSessionData}
              household_id={marketSessionData.household_id}
            />
          )}
        </Squad>
        <Squad>
          <h2>Ultimo Acesso</h2>
          <Box>
            <p>
              {marketSessionData.lastLogin
                ? format(
                    parseISO(marketSessionData.lastLogin),
                    "dd'/'MM'/'yyyy '-' kk':'mm':'ss",
                    {
                      locale: pt,
                    },
                  )
                : 'Sem Registo'}
            </p>
          </Box>
        </Squad>
        <Squad>
          <h2>Estado do Acesso</h2>
          <StateMarket
            available={marketSessionData.available}
            household_id={marketSessionData.household_id}
            setMarketSession={setMarketSessionData}
          />
        </Squad>
        {marketSessionData.coinInfo && (
          <>
            <Squad>
              <h2>Saldo Disponivel</h2>
              <CoinMarket
                coin={marketSessionData.coinInfo.coin}
                id_market_session={marketSessionData.id}
                setMarketSession={setMarketSessionData}
              />
            </Squad>
            <Squad>
              <h2>Valor de Renovação</h2>
              <CoinValueMarket
                coinValueReset={marketSessionData.coinInfo.coinResetValue}
                id_market_session={marketSessionData.id}
                setMarketSession={setMarketSessionData}
              />
            </Squad>
            <Squad>
              <h2>Grupo Mercearia</h2>
              <SessionType
                sessionType={marketSessionData.sessionType}
                id_market_session={marketSessionData.id}
                setMarketSession={setMarketSessionData}
              />
            </Squad>
            <Squad>
              <h2>Transações</h2>
              <Box>
                <button
                  type="button"
                  title="ver transações"
                  onClick={() => setTransactionsModal(true)}
                >
                  <img src={transacoes} alt="Transações" />
                </button>
              </Box>
            </Squad>

            <Squad>
              <h2>Encomendas</h2>
              <Box>
                <button
                  type="button"
                  title="ver encomendas"
                  onClick={() =>
                    history.push({
                      pathname: '/encomendas',
                      state: {
                        search: householdName,
                      },
                    })
                  }
                >
                  <img src={order} alt="Encomendas" />
                </button>
              </Box>
            </Squad>
            <Squad>
              <h2>Creditação</h2>
              <Box>
                <button
                  type="button"
                  title="Creditar na conta"
                  onClick={() => setCreditingModal(true)}
                >
                  <img src={crediting} alt="Creditação" />
                </button>
              </Box>
            </Squad>
          </>
        )}
      </div>
      {transactionsModal && (
        <Modal visible={transactionsModal}>
          <Transaction
            close={() => setTransactionsModal(false)}
            household_id={marketSession.household_id}
          />
        </Modal>
      )}
      {creditingModal && (
        <Modal visible={creditingModal}>
          <Crediting
            close={() => setCreditingModal(false)}
            household_id={marketSession.household_id}
            setMarketSession={setMarketSessionData}
          />
        </Modal>
      )}
    </Container>
  );
};

export default PanelMarkerSession;
