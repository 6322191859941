/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Modal from '../Modal';
import TextArea from '../TextArea';

import add from '../../assets/add.svg';

import { Note } from '../../types/Note';

import {
  Close,
  Button,
  Notes,
  ModalAddNote,
  ModalBoxConfirm,
  ButtonConfirm,
} from './styles';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

type AddNoteProps = {
  note: string;
};

type NotesPropsReq = {
  id_user: string;
  notes: Note[];
  active: boolean;
};

const NotesCards: React.FC<NotesPropsReq> = ({ id_user, notes, active }) => {
  const [notesData, setNotesData] = useState<Note[]>([]);
  const [addNoteModalVisible, setAddModalVisible] = useState(false);
  const [deleteNoteModalVisible, setDeleteModalVisible] = useState(false);
  const [idNoteToDelete, setIdNoteToDelete] = useState('');

  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    reset,
  } = useForm<AddNoteProps>();

  const handleAddNote = async (data: AddNoteProps) => {
    if (!data.note) {
      setError(
        'note',
        {
          type: 'focus',
          message: '* é obrigatório adicionar um texto à nota',
        },
        { shouldFocus: true },
      );
      return;
    }

    try {
      const response = await api.post('/user-note/', {
        note: data.note,
        userId: id_user,
      });

      setNotesData((prevState) => [...prevState, response.data]);
      addToast({
        type: 'success',
        title: 'Nota adicionada!',
        description: { code: 210 },
      });
      reset({ note: '' });
      setAddModalVisible(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar nota!',
        description: { code: 211 },
      });
    }
  };

  const handleDeleteModal = (id_note: string) => {
    setIdNoteToDelete(id_note);
    setDeleteModalVisible(true);
  };

  const handleDeleteNote = async (resp: boolean) => {
    if (resp) {
      try {
        const response = await api.delete(`user-note/${idNoteToDelete}`);
        if (response.data) {
          setNotesData((prevState) =>
            prevState.filter((n) => n.id !== idNoteToDelete),
          );
        }
        addToast({
          type: 'success',
          title: 'Nota removida!',
          description: { code: 212 },
        });
        setDeleteModalVisible(false);
        setIdNoteToDelete('');
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro ao remover nota!',
          description: { code: 213 },
        });
        setDeleteModalVisible(false);
        setIdNoteToDelete('');
      }
    } else {
      setDeleteModalVisible(false);
      setIdNoteToDelete('');
    }
  };

  useEffect(() => {
    if (notes && notes.length > 0) {
      setNotesData(notes);
    }
  }, [notes]);

  return (
    <>
      <Notes>
        <div>
          <h1>Notas do utente:</h1>
          {active && (
            <button type="button" onClick={() => setAddModalVisible(true)}>
              <img src={add} alt="Imagem de um mais" />
            </button>
          )}
        </div>
        <ul>
          {notesData.length > 0 &&
            notesData.map((note) => (
              <li key={note.id}>
                <div>
                  {active && (
                    <button
                      type="button"
                      onClick={() => handleDeleteModal(note.id)}
                    >
                      &#x2715;
                    </button>
                  )}
                  <p>{note.note}</p>
                </div>
              </li>
            ))}
        </ul>
      </Notes>

      {active && (
        <>
          <Modal visible={deleteNoteModalVisible}>
            <ModalBoxConfirm>
              <h1>Quer mesmo remover esta nota?</h1>
              <div>
                <ButtonConfirm
                  bgColor="#007970"
                  bgHoverColor="#00665f"
                  type="button"
                  onClick={() => handleDeleteNote(true)}
                >
                  Sim
                </ButtonConfirm>
                <ButtonConfirm
                  bgColor="#ff8080"
                  bgHoverColor="#ff4d4d"
                  type="button"
                  onClick={() => handleDeleteNote(false)}
                >
                  Não
                </ButtonConfirm>
              </div>
            </ModalBoxConfirm>
          </Modal>

          <Modal visible={addNoteModalVisible}>
            <ModalAddNote>
              <Close>
                <button type="button" onClick={() => setAddModalVisible(false)}>
                  &#x2715;
                </button>
              </Close>
              <form onSubmit={handleSubmit(handleAddNote)}>
                <TextArea
                  width="100%"
                  title=""
                  {...register('note')}
                  error={errors.note?.message}
                />

                <Button type="submit">Adicionar Nota</Button>
              </form>
            </ModalAddNote>
          </Modal>
        </>
      )}
    </>
  );
};

export default NotesCards;
