import styled from 'styled-components';

type Input = {
  width: string;
  isError: boolean;
  borderType: boolean;
  text_aling?: string;
  borderSize?: string;
};

export const Container = styled.div<Input>`
  position: relative;
  width: ${(props) => props.width};
  ${(props) =>
    props.borderType
      ? `border-bottom: 2px solid ${props.isError ? '#ff5050' : '#007970'};`
      : `border: 2px solid ${props.isError ? '#ff5050' : '#007970'};`}

  ${(props) => props.borderSize && `  border-width: ${props.borderSize};`}

  border-radius: 2px;
  background: #007970;

  span {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    top: -1.3rem;
    left: 0.2rem;
    background: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  input {
    text-align: ${(props) => (props.text_aling ? props.text_aling : 'left')};
    width: 100%;
    padding: 0.9rem;
    color: #007970;
    font-size: 1.8rem;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  p {
    position: absolute;
    color: #ff5050;
    font-size: 9px;
    bottom: 0;
    left: 1rem;
  }
`;
