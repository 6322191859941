import styled from 'styled-components';

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 450px;
  height: 350px;
  background-color: #fff;
  padding: 2rem;
  margin-top: 4rem;
  border-radius: 5px;

  > h1 {
    width: 100%;
    text-align: left;
    font-size: 25px;
    margin-bottom: 1rem;
  }

  > div {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;
      color: #007970;
      font-size: 1.6rem;
    }
  }

  > div {
    > div {
      > button {
        padding: 5px;
        width: 160px;
        background-color: #007970;
        color: #3cf0de;
        border: none;
        border-radius: 5px;
      }
    }
  }

  > div {
    > form {
      width: 100%;
      > div {
        > span {
          color: #007970;
          font-size: 2rem;
        }

        > textarea {
          width: 100%;
          resize: none;
          color: #007970;
          font-size: 1.8rem;
          border: 1px solid rgba(0, 121, 112, 0.8);
          border-radius: 5px;
        }

        > div {
          display: flex;
          align-items: center;

          > span {
            color: #007970;
            font-size: 2rem;
          }

          > div {
            margin-top: 5px;
            > input {
              color: #007970;
              font-size: 2rem;
              border-radius: 5px;
              width: 6rem;
              border: 1px solid rgba(0, 121, 112, 0.8);
              text-align: right;
              -webkit-appearance: none;
              -moz-appearance: textfield;
              &::-webkit-input-placeholder {
                color: rgba(0, 121, 112, 0.8);
              }
            }
          }
        }
      }
      > p {
        color: #ff5050;
        font-size: 9px;
        left: 1rem;
      }
    }
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }

  p {
    font-size: 3rem;
    color: #007970;
  }
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  width: 100%;
  padding: 0.7rem;
  font-size: 1.8rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  &:hover {
    background: ${(props) => props.bghover};
  }
`;
