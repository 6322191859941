/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import api from '../../../../config/api';
import { useToast } from '../../../../hooks/ToastContext';

import { Container, Modal, ButtonBox, Button, Title } from './styles';

import { RouteWeek } from '../../../../types/RouteWeek';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
  setRouteWeekNotFinished: React.Dispatch<React.SetStateAction<RouteWeek>>;
  routeWeek: RouteWeek;
};

const FinishRouteWeek: React.FC<ModalProps> = ({
  visible,
  closeModal,
  setRouteWeekNotFinished,
  routeWeek,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const handleOnSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await api.put(`route-week-finished/${routeWeek.id}`);

      addToast({
        type: 'success',
        title: 'Semana terminada',
        description: { code: 832 },
      });

      if (response.data) {
        setRouteWeekNotFinished({} as RouteWeek);
        setIsLoading(false);
        closeModal();
      }
    } catch (e: any) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro ao terminar semana',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  return (
    <Container visible={visible}>
      <Modal>
        <Title>
          Quer terminar a semana {routeWeek.week}{' '}
          {routeWeek.type === 'pt' ? 'portuguesa' : 'ucraniana'}?
        </Title>
        <ButtonBox>
          <Button
            type="button"
            color="#3cf0de"
            bgcolor={isLoading ? '#ff6666' : '#ff9999'}
            bghover="#ff6666"
            onClick={closeModal}
            disabled={isLoading}
          >
            Não
          </Button>
          {isLoading ? (
            <Button
              type="button"
              color="#3cf0de"
              bgcolor={isLoading ? '#00665f' : '#007970'}
              bghover="#00665f"
              disabled={isLoading}
            >
              A remover...
            </Button>
          ) : (
            <Button
              type="button"
              color="#3cf0de"
              bgcolor="#007970"
              bghover="#00665f"
              onClick={handleOnSubmit}
            >
              Sim
            </Button>
          )}
        </ButtonBox>
      </Modal>
    </Container>
  );
};

export default FinishRouteWeek;
