import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import PdfRouteTags from '../../components/PdfFiles/PdfRouteTags';
import { Routes } from '../../types/Routes';

const styles = StyleSheet.create({
  view: {
    width: '100vw',
    height: '100vh',
  },
});

type PDFRoutesProps = {
  routes: Routes[];
};

const PDFRoutes: React.FC = () => {
  const location = useLocation<PDFRoutesProps>();

  return (
    <PDFViewer style={styles.view}>
      <PdfRouteTags routes={location.state.routes} />
    </PDFViewer>
  );
};

export default PDFRoutes;
