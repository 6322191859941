/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { pt as localePT } from 'date-fns/locale';
import * as yup from 'yup';

import Input from '../../../Input';
import api from '../../../../config/api';

import CheckBox from '../../../CheckBox';

import {
  Container,
  Modal,
  Form,
  FormLine,
  ButtonBox,
  Button,
  Close,
  ErrorMessage,
  RadioBox,
  RadioTypeRoute,
  HistoryRouteWeekTable,
  HistoryNotFound,
  HistoryRouteWeekBox,
  HistoryHeaderInfo,
  HistoryRouteWeekRoute,
  HistoryRouteWeekRouteTurn,
  Th,
  Td,
  AlignFormLines,
  WeekButton,
} from './styles';

import search from '../../../../assets/search.svg';
import loading from '../../../../assets/loading03.svg';
import pt from '../../../../assets/pt.svg';
import uk from '../../../../assets/uk.svg';
import excel from '../../../../assets/excel.svg';

import { RouteWeek } from '../../../../types/RouteWeek';
import { useToast } from '../../../../hooks/ToastContext';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
};

type DataFormSubmit = {
  week: string;
  type: string;
};

const schema = yup.object().shape({
  week: yup.string().required('* a semana é obrigatório'),
  type: yup.string().nullable().required('* o tipo de rota é obrigatório'),
});

const HistoryRouteWeek: React.FC<ModalProps> = ({ visible, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [historyRouteWeek, setHistoryRouteWeek] = useState({} as RouteWeek);
  const [dataSearch, setDataSearch] = useState({
    type: '',
    week: '',
  });

  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DataFormSubmit>({
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (data: DataFormSubmit) => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `route-week-history?week=${data.week}&type=${data.type}`,
      );

      setHistoryRouteWeek(response.data);
      setDataSearch({ type: data.type, week: data.week });
      setIsLoading(false);
    } catch (e: any) {
      setHistoryRouteWeek({} as RouteWeek);
      setIsLoading(false);
    }
  };

  const handlePrintRoutesExcel = async () => {
    try {
      const response = await api.get(
        `route-week-history-excel?week=${dataSearch.week}&type=${dataSearch.type}`,
      );

      const buf = Buffer.from(response.data.dataExcel.data);

      const url = window.URL.createObjectURL(new Blob([buf]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `historico_rota_${dataSearch.week}.xlsx`;
      a.click();
    } catch {
      addToast({
        type: 'error',
        title: 'Erro a obter excel',
        description: { code: 805 },
      });
    }
  };

  return (
    <Container visible={visible}>
      <Modal>
        <Close>
          <button type="button" onClick={closeModal}>
            &#x2715;
          </button>
        </Close>
        <Form id="create_week" onSubmit={handleSubmit(handleOnSubmit)}>
          <AlignFormLines>
            <FormLine>
              <Input
                width="100%"
                title="Número da Semana"
                type="number"
                textAlign="center"
                borderType
                {...register('week')}
                error={errors.week?.message}
              />
            </FormLine>
            <FormLine>
              <h1>Tipo de Rota</h1>
              <RadioBox>
                <RadioTypeRoute>
                  <img src={pt} alt="Bandeira de Portugal" />
                  <CheckBox
                    width="auto"
                    title=""
                    type="radio"
                    value="pt"
                    {...register('type')}
                    error={errors.type?.message}
                  />
                </RadioTypeRoute>
                <RadioTypeRoute>
                  <img src={uk} alt="Bandeira da Ucrania" />
                  <CheckBox
                    width="auto"
                    title=""
                    type="radio"
                    value="uk"
                    {...register('type')}
                    error={errors.type?.message}
                  />
                </RadioTypeRoute>
              </RadioBox>
              <ErrorMessage>{errors.type?.message}</ErrorMessage>
            </FormLine>
            <FormLine>
              <ButtonBox>
                {isLoading ? (
                  <Button
                    form="create_week"
                    color="#3cf0de"
                    bgcolor={isLoading ? '#00665f' : '#007970'}
                    bghover="#00665f"
                    disabled={isLoading}
                  >
                    <img src={loading} alt="Icon de desquete save" />a
                    carregar...
                  </Button>
                ) : (
                  <Button
                    form="create_week"
                    type="submit"
                    color="#3cf0de"
                    bgcolor="#007970"
                    bghover="#00665f"
                  >
                    <img src={search} alt="Icon de desquete save" />
                    Pesquisar
                  </Button>
                )}
              </ButtonBox>
            </FormLine>
          </AlignFormLines>
        </Form>
        <HistoryRouteWeekTable>
          <h1>Histórico:</h1>
          {historyRouteWeek.id ? (
            <>
              <HistoryHeaderInfo>
                <h1>
                  Semana: {historyRouteWeek.week}
                  {historyRouteWeek.type === 'pt' ? (
                    <img src={pt} alt="Bandeira de Portugal" />
                  ) : (
                    <img src={uk} alt="Bandeira da Ucrania" />
                  )}
                </h1>
                <h1>
                  <WeekButton title="Excel" onClick={handlePrintRoutesExcel}>
                    <img src={excel} alt="Excel" />
                  </WeekButton>
                  Data:{' '}
                  {format(new Date(historyRouteWeek.date), `dd'-'MM'-'yyyy'`, {
                    locale: localePT,
                  })}
                </h1>
              </HistoryHeaderInfo>
              <HistoryRouteWeekBox>
                {historyRouteWeek.routeWeekRoutes &&
                  historyRouteWeek.routeWeekRoutes.length > 0 &&
                  historyRouteWeek.routeWeekRoutes.map((routeWeekRoute) => (
                    <HistoryRouteWeekRoute key={routeWeekRoute.id}>
                      <div>
                        <h1>Rota: {routeWeekRoute.routeNum}</h1>
                        <h1>Responsável: {routeWeekRoute.routeResp}</h1>
                        <h1>
                          Dias:{' '}
                          {routeWeekRoute.daysWeek
                            .split(',')
                            .map((day: string) => {
                              switch (day) {
                                case '0':
                                  return 'Domingo';
                                case '1':
                                  return 'Segunda';
                                case '2':
                                  return 'Terça';
                                case '3':
                                  return 'Quarta';
                                case '4':
                                  return 'Quinta';
                                case '5':
                                  return 'Sexta';
                                case '6':
                                  return 'Sábado';
                                default:
                                  return 'Nenhum';
                              }
                            })
                            .join(', ')}
                        </h1>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <Th talign="center" width="5%">
                              #
                            </Th>
                            <Th talign="left" width="15%">
                              Agregado
                            </Th>
                            <Th talign="left" width="20%">
                              Notas
                            </Th>
                            <Th talign="center" width="5%">
                              PAX
                            </Th>
                            <Th talign="center" width="8%">
                              Cozinha?
                            </Th>
                            <Th talign="center" width="9%">
                              Nº Refeições
                            </Th>
                            <Th talign="center" width="9%">
                              Contacto
                            </Th>
                            <Th talign="left" width="20%">
                              Morada
                            </Th>
                            <Th talign="center" width="9%">
                              Localidade
                            </Th>
                          </tr>
                        </thead>
                        <tbody>
                          {routeWeekRoute.routeWeekRouteTurn &&
                            routeWeekRoute.routeWeekRouteTurn.length > 0 &&
                            routeWeekRoute.routeWeekRouteTurn.map(
                              (routeWeekRouteTurn, index) => (
                                <HistoryRouteWeekRouteTurn
                                  key={routeWeekRouteTurn.id}
                                >
                                  <Td talign="center" width="5%">
                                    {index + 1}
                                  </Td>
                                  <Td talign="left" width="15%">
                                    {routeWeekRouteTurn.householdName}
                                  </Td>
                                  <Td talign="left" width="20%">
                                    {routeWeekRouteTurn.note}
                                  </Td>
                                  <Td talign="center" width="5%">
                                    {routeWeekRouteTurn.householdMembersLength}
                                  </Td>
                                  <Td talign="center" width="8%">
                                    {routeWeekRouteTurn.canCook ? 'Sim' : 'Não'}
                                  </Td>
                                  <Td talign="center" width="9%">
                                    {routeWeekRouteTurn.numMeal}
                                  </Td>
                                  <Td talign="center" width="9%">
                                    {routeWeekRouteTurn.householdContact}
                                  </Td>
                                  <Td talign="left" width="20%">
                                    {routeWeekRouteTurn.householdAddress}
                                  </Td>
                                  <Td talign="center" width="9%">
                                    {routeWeekRouteTurn.householdLocation}
                                  </Td>
                                </HistoryRouteWeekRouteTurn>
                              ),
                            )}
                        </tbody>
                      </table>
                    </HistoryRouteWeekRoute>
                  ))}
              </HistoryRouteWeekBox>
            </>
          ) : (
            <HistoryNotFound>
              <h1>Não foi encontrado histórico de rotas para essa pesquisa!</h1>
            </HistoryNotFound>
          )}
        </HistoryRouteWeekTable>
      </Modal>
    </Container>
  );
};

export default HistoryRouteWeek;
