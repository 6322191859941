/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';
import { useAuth } from '../../hooks/AuthContext';

import Menu from '../../components/Menu';
import Table from '../../components/Table';
import Th from '../../components/Table/Th';
import Td from '../../components/Table/Td';

import search from '../../assets/search.svg';
import loading from '../../assets/loading01.svg';
import page_left from '../../assets/page_left.svg';
import page_right from '../../assets/page_right.svg';

import {
  Container,
  Widget,
  Top,
  Title,
  Search,
  Loading,
  PageBox,
  BoxLogs,
} from './styles';

type LogProps = {
  status: string;
  log: string;
  user: string;
  data: string;
};
let debounce: NodeJS.Timeout;

const Logs: React.FC = () => {
  const ROWLIMIT = 10;
  const [logs, setLogs] = useState([] as LogProps[]);
  const [logsList, setLogsList] = useState([] as LogProps[]);
  const [displayLogs, setDisplayLogs] = useState([] as LogProps[]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [date, setDate] = useState(() => {
    const dt = new Date();

    return `${dt.getFullYear()}-${
      dt.getMonth() + 1 >= 10 ? dt.getMonth() + 1 : `0${dt.getMonth() + 1}`
    }-${dt.getDate() >= 10 ? dt.getDate() : `0${dt.getDate()}`}`;
  });
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { addToast } = useToast();
  const { account } = useAuth();

  const logsToDisplay = useCallback((pageData, array) => {
    const arrayTemp = [];

    for (
      let index = ROWLIMIT * (pageData - 1);
      index <= pageData * ROWLIMIT - 1;
      index++
    ) {
      if (array[index]) {
        arrayTemp.push(array[index]);
      }
    }

    setDisplayLogs(arrayTemp);
  }, []);

  const handlePage = (data: number) => {
    if (page + data > 0 && page + data <= pages) {
      setPage((prevState) => prevState + data);
      logsToDisplay(page + data, logsList);
    }
  };

  const getLogs = useCallback(async () => {
    setIsLoading(true);
    const d = date.replaceAll('-', '');
    try {
      const response = await api.get(`/logs/${d}`);
      setLogs(response.data);
      setLogsList(response.data);
      setPages(Math.ceil(response.data.length / ROWLIMIT));
      logsToDisplay(page, response.data);
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter Log',
        description: { code: 600 },
      });
    }
  }, [addToast, date, logsToDisplay]);

  useEffect(() => {
    if (account && !account.admin) {
      history.push('utentes');
    } else {
      getLogs();
    }
  }, [account, history, getLogs]);

  const handleSeach = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(debounce);
    e.preventDefault();

    const searchText = e.currentTarget.value.toLowerCase();
    searchText
      ? (debounce = setTimeout(() => {
          const arrayTemp = logs.filter((log) => {
            return (
              log.status.toLowerCase().includes(searchText) ||
              log.log.toLowerCase().includes(searchText) ||
              log.user.toLowerCase().includes(searchText)
            );
          });
          setLogsList(arrayTemp);
          logsToDisplay(1, arrayTemp);
          setPages(Math.ceil(arrayTemp.length / ROWLIMIT));
        }, 500))
      : (debounce = setTimeout(() => {
          setLogsList(logs);
          logsToDisplay(1, logs);
          setPages(Math.ceil(logs.length / ROWLIMIT));
        }, 500));
  };

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Logs {date}</h1>
            </Title>
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={handleSeach}
              />
              <div title="Pesquisar">
                <img src={search} alt="search" />
              </div>
            </Search>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <BoxLogs>
              <input
                type="date"
                value={date}
                onChange={(e) =>
                  setDate((prevState) =>
                    e.target.value ? e.target.value : prevState,
                  )
                }
              />
              <Table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="left" width="10%">
                      Estado
                    </Th>
                    <Th talign="left" width="55%">
                      Menssagem
                    </Th>
                    <Th talign="center" width="15%">
                      Utilizador
                    </Th>
                    <Th talign="center" width="15%">
                      Data
                    </Th>
                  </tr>
                </thead>
                <tbody>
                  {displayLogs.map((log, index) => (
                    <tr key={log.data}>
                      <Td talign="center" width="5%">
                        {index + 1}
                      </Td>
                      <Td talign="left" width="10%">
                        {log.status}
                      </Td>
                      <Td talign="left" width="55%">
                        {log.log}
                      </Td>
                      <Td talign="center" width="15%">
                        {log.user}
                      </Td>
                      <Td talign="center" width="15%">
                        {format(
                          new Date(log.data),
                          `dd'/'MM'/'yyyy 'às' HH':'mm'`,
                          {
                            locale: pt,
                          },
                        )}
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PageBox>
                <button
                  type="button"
                  title="anterior"
                  onClick={() => handlePage(-1)}
                >
                  <img src={page_left} alt="seta para a esquerda" />
                </button>
                <p>
                  {page} - {pages}
                </p>
                <button
                  type="button"
                  title="próximo"
                  onClick={() => handlePage(1)}
                >
                  <img src={page_right} alt="seta para a direita" />
                </button>
              </PageBox>
            </BoxLogs>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default Logs;
