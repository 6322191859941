import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: '10px',
  },
  order_title: {
    color: '#8c8c8c',
    fontSize: '20px',
  },
  order_title_canceled: {
    color: '#ff6666',
    fontSize: '20px',
  },
  order_text: {
    color: '#8c8c8c',
    marginTop: '5px',
    fontSize: '14px',
  },
  order_text_executed: {
    color: '#8c8c8c',
    marginTop: '5px',
    fontSize: '14px',
    position: 'absolute',
    right: '0',
    paddingBottom: '20px',
    borderBottom: '1px solid #8c8c8c',
    width: '150px',
  },

  order_line: {
    marginTop: '10px',
    marginBottom: '10px',
    backgroundColor: 'rgba(230, 230, 230, 1)',
    width: '100%',
    height: '1px',
  },

  order_product: {
    color: '#8c8c8c',
    fontSize: '18px',
  },

  order_total: {
    color: '#8c8c8c',
    fontSize: '18px',
    width: '100%',
    textAlign: 'right',
    marginTop: '10px',
  },

  order_product_line: {
    display: 'flex',
    padding: '5px',
    flexDirection: 'row',
    marginTop: '10px',
    width: '48%',
    minWidth: '48%',
    borderBottom: '1px dashed rgba(230, 230, 230, 0.8)',
    justifyContent: 'space-between',
  },

  order_grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  order_product_info: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  order_product_info_text: {
    marginLeft: '10px',
    fontSize: '12px',
    color: '#8c8c8c',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '160px',
  },

  order_product_info_multi: {
    marginLeft: '10px',
    fontSize: '12px',
    color: '#8c8c8c',
  },

  order_product_info_sc: {
    marginLeft: '0px',
    fontSize: '12px',
    color: '#8c8c8c',
  },
});
