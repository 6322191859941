import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.form`
  margin-top: 2rem;
  width: 140rem;
  max-width: 140rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 3rem;
    font-size: 2.8rem;
  }

  > div {
    width: 40%;
    margin-bottom: 3rem;
  }

  > hr {
    width: 40%;
    margin-top: 1rem;
    margin-bottom: 4rem;
    border: 1px solid #007970;
  }
`;

export const Confirm = styled.button`
  color: #3cf0de;
  background: #007970;
  width: 40%;
  padding: 1rem;
  font-size: 2.4rem;
  border-radius: 2px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: #00665f;
  }
`;
