import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Routes } from '../../../types/Routes';

import { styles } from './styles';

type FileProps = {
  routes: Routes[];
  week: number | null;
  date: string | null;
};

const File: React.FC<FileProps> = ({ routes, week, date }) => {
  let count = 0;
  let break_line = false;

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.header}>
          <Image src="souma.png" style={styles.image} />
          <Text style={styles.text}>Mapa de Rotas</Text>
          {week && date && (
            <View style={styles.date_week}>
              <Text style={styles.text_week_date}>Semana: {week}</Text>
              <Text style={styles.text_week_date}>Data: {date}</Text>
            </View>
          )}
        </View>
        {routes
          .sort((a: Routes, b: Routes) =>
            a.routeNum.localeCompare(b.routeNum, undefined, { numeric: true }),
          )
          .map((route) => {
            if (break_line) {
              break_line = false;
            }

            const total = count + 2 + route.turns.length;

            if (total > 17) {
              count = 0;
              break_line = true;
            }

            count += 2 + route.turns.length;

            return (
              route.status && (
                <View key={route.id} break={break_line}>
                  <View style={styles.route_title}>
                    <Text style={styles.route_title_text}>
                      Rota {route.routeNum}
                    </Text>
                  </View>
                  <View style={styles.route_table_th}>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '2%',
                        textAlign: 'center',
                      }}
                    >
                      #
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '15%' }}
                    >
                      Agregado
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '21%' }}
                    >
                      Notas
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '5%',
                        textAlign: 'center',
                      }}
                    >
                      PAX
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '5%',
                        textAlign: 'center',
                      }}
                    >
                      Cozinha?
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '8%',
                        textAlign: 'center',
                      }}
                    >
                      Nº Refeições
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '22%' }}
                    >
                      Morada
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '7%' }}
                    >
                      Localidade
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      Contacto
                    </Text>
                  </View>
                  {route.turns
                    .sort(
                      (a, b) =>
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime(),
                    )
                    .map((turn, index) => {
                      return (
                        <View key={turn.id} style={styles.route_table_td}>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '2%',
                              textAlign: 'center',
                            }}
                          >
                            {index + 1}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '15%',
                            }}
                          >
                            {turn.household && turn.household.name}
                            {turn.onceMonth && ' - (1 vez mês)'}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '21%',
                            }}
                          >
                            {turn.note}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '5%',
                              textAlign: 'center',
                            }}
                          >
                            {(turn.household &&
                              turn.household.household_members &&
                              turn.household.household_members.length + 1) ||
                              0}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '5%',
                              textAlign: 'center',
                            }}
                          >
                            {turn.canCook ? 'Sim' : 'Não'}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '8%',
                              textAlign: 'center',
                            }}
                          >
                            {turn.numMeal}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '22%',
                            }}
                          >
                            {turn.household && turn.household.user.address}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '7%',
                            }}
                          >
                            {turn.household && turn.household.user.location}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '15%',
                              textAlign: 'center',
                              maxWidth: '15%',
                              flex: 1,
                            }}
                          >
                            {turn.household && turn.household.user.contact}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              )
            );
          })}
      </Page>
    </Document>
  );
};

export default File;
