import styled from 'styled-components';

type ThProps = {
  width: string;
  talign: string;
  fontSize: string;
  pt: string;
  pb: string;
};

export const Container = styled.th<ThProps>`
  width: ${(props) => props.width};
  font-size: ${(props) => `${props.fontSize}rem`};
  font-weight: bold;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
  padding-top: ${(props) => `${props.pt}rem`};
  padding-bottom: ${(props) => `${props.pb}rem`};

  small {
    font-size: 16px;
  }
`;
