import styled from 'styled-components';

type TdProps = {
  width: string;
  talign: string;
};

export const Container = styled.td<TdProps>`
  width: ${(props) => props.width};
  font-size: 1.6rem;
  text-align: ${(props) => props.talign};
  padding-left: ${(props) => (props.talign === 'center' ? 0 : '0.5rem')};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #3cf0de;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;

      img {
        width: 24px;
        height: 24px;
      }

      & + button {
        margin-left: 2rem;
      }
    }
  }
`;
