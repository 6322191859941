/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useCallback, useEffect, useState } from 'react';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import { Transaction as TransactionType } from '../../types/Order';
import Td from '../Table/Td';

import { Container, Modal, Close, Th } from './styles';

type TransactionProps = {
  close(): void;
  household_id: string;
};

const Transaction: React.FC<TransactionProps> = ({ close, household_id }) => {
  const [transactions, setTransactionsTitle] = useState<TransactionType[]>([]);

  const { addToast } = useToast();

  const getTransactionss = useCallback(async () => {
    try {
      const response = await api.get(`/transaction/${household_id}`);
      setTransactionsTitle(response.data);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter transações',
        description: { code: 759 },
      });
    }
  }, [addToast, household_id]);

  useEffect(() => {
    getTransactionss();
  }, [getTransactionss]);

  return (
    <Container>
      <Modal>
        <Close title="Fechar" onClick={close}>
          <p>&#x2715;</p>
        </Close>
        <h1>Transações</h1>
        <div>
          <table>
            <thead>
              <tr>
                <Th talign="center" width="5%">
                  #
                </Th>
                <Th talign="left" width="45%">
                  DESCRIÇÃO
                </Th>
                <Th talign="center" width="10%">
                  SALDO INI.
                </Th>
                <Th talign="center" width="15%">
                  VALOR TRANS.
                </Th>
                <Th talign="center" width="10%">
                  SALDO FINAL
                </Th>
                <Th talign="left" width="15%">
                  DATA
                </Th>
              </tr>
            </thead>
            <tbody>
              {transactions.length > 0 &&
                transactions.map((transaction, index) => (
                  <tr key={transaction.id}>
                    <Td talign="center" width="5%">
                      {transactions.length - index}
                    </Td>
                    <Td talign="left" width="45%">
                      {transaction.description}
                    </Td>
                    <Td talign="center" width="10%">
                      {transaction.initialValue.toFixed(2)}
                    </Td>
                    <Td talign="center" width="15%">
                      {transaction.finalValue >= transaction.initialValue
                        ? `+${transaction.transactionValue.toFixed(2)}`
                        : `-${transaction.transactionValue.toFixed(2)}`}
                    </Td>
                    <Td talign="center" width="10%">
                      {transaction.finalValue.toFixed(2)}
                    </Td>
                    <Td talign="left" width="15%">
                      {format(
                        parseISO(transaction.createdAt),
                        `dd'/'MM'/'yyyy' - ' HH:mm:ss`,
                        { locale: pt },
                      )}
                    </Td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </Container>
  );
};

export default Transaction;
