/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { ModalBoxConfirm, ButtonConfirm } from './styles';

type ModalMessageProps = {
  message: string;
  handleYes(): Promise<void>;
  closeModal(): void;
};

const ModalMessage: React.FC<ModalMessageProps> = ({
  message,
  handleYes,
  closeModal,
}) => {
  return (
    <ModalBoxConfirm>
      <h1>{message}</h1>
      <div>
        <ButtonConfirm
          bgColor="#007970"
          bgHoverColor="#00665f"
          type="button"
          onClick={handleYes}
        >
          Sim
        </ButtonConfirm>
        <ButtonConfirm
          bgColor="#ff8080"
          bgHoverColor="#ff4d4d"
          type="button"
          onClick={closeModal}
        >
          Não
        </ButtonConfirm>
      </div>
    </ModalBoxConfirm>
  );
};

export default ModalMessage;
