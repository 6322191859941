import styled from 'styled-components';

type ButtonColors = {
  bgColor: string;
  bgHoverColor: string;
};

export const ModalBoxConfirm = styled.div`
  position: relative;
  width: 60rem;
  max-width: 60rem;
  max-height: 24rem;
  margin-top: -40rem;
  background-color: #fff;
  display: flex;
  padding: 5rem;
  flex-direction: column;

  h1 {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ButtonConfirm = styled.button<ButtonColors>`
  color: #3cf0de;
  margin-top: 50px;
  font-size: 2.2rem;
  padding: 0.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 5px;
  border: none;
  width: 200px;
  background: ${(props) => props.bgColor};

  &:hover {
    background: ${(props) => props.bgHoverColor};
  }
`;

export const LinkButton = styled.div`
  border: none;
  max-width: 200px;
  background: #007970;
  color: #3cf0de;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 1.4rem;
  box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 77, 71, 0.5);
  margin-top: 4rem;
  align-self: center;

  > div {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      margin-right: 10px;
    }

    p {
      width: auto;
      color: black;
      text-decoration: none;
      display: block;
      text-align: center;
      font-size: 1.4rem;
      color: #3cf0de;
    }
  }

  &:hover {
    cursor: pointer;
    background: #00665f;
  }
`;
