import styled from 'styled-components';

type Input = {
  isError: boolean;
};

export const Container = styled.div<Input>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    margin-top: 2.5rem;
    border: 1px solid ${(props) => (props.isError ? '#ff5050' : '#007970')};
    border-radius: 0.2rem;
    padding: 1rem;
    width: 80%;
    color: #007970;
    font-size: 1.8rem;
    font-weight: bold;
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  p {
    color: #ff5050;
    font-size: 1.4rem;
  }
`;
