import React, { useState, useEffect, useCallback } from 'react';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import { Product } from '../../types/Product';

import Menu from '../../components/Menu';
import Th from '../../components/Table/Th';

import add from '../../assets/add.svg';
import search from '../../assets/search.svg';
import loading from '../../assets/loading01.svg';

import {
  Container,
  Widget,
  Top,
  Title,
  Search,
  Loading,
  TableProducts,
} from './styles';

import ProductLine from '../../components/Product/ProductLine';
import ProductForm from '../../components/Product/ProductForm';
import Modal from '../../components/Modal';
import ProductStock from '../../components/Product/StockProduct';
import ProductStatus from '../../components/Product/StatusMarket';
// import Modal from '../../components/Modal';
// import ProductCreate from '../../components/Product/Create';

let debounce: NodeJS.Timeout;

const Products: React.FC = () => {
  const [products, setProducts] = useState([] as Product[]);
  const [productsToDisplay, setProductsToDisplay] = useState([] as Product[]);
  const [modalProductForm, setModalProductForm] = useState(false);
  const [modalStock, setModalStock] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [productData, setProductData] = useState<Product | null>(null);
  const [productIcon, setProductIcon] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const getProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/market/product');
      setProducts(response.data);
      setProductsToDisplay(response.data);
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter lista de produtos',
        description: { code: 505 },
      });
    }
  }, [addToast]);

  const handleSeach = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(debounce);
    e.preventDefault();

    const searchText = e.currentTarget.value.toLowerCase();
    searchText
      ? (debounce = setTimeout(() => {
          const arrayTemp = products.filter((product) => {
            const alert = !!(product.stock < product.stockAlert);
            return (
              product.name.toLowerCase().includes(searchText) ||
              product.category.name.toLowerCase().includes(searchText) ||
              product.description.toLowerCase().includes(searchText) ||
              ('alertas'.toLowerCase().includes(searchText) && alert)
            );
          });
          setProductsToDisplay(arrayTemp);
        }, 500))
      : (debounce = setTimeout(() => {
          setProductsToDisplay(products);
        }, 500));
  };

  const handleUpdateProduct = useCallback(
    async (id: string, status: 'update' | 'add') => {
      try {
        const response = await api.get(`/market/product/${id}`);
        if (status === 'update') {
          setProducts((prevState) =>
            prevState.map((p) =>
              p.id === response.data.id ? response.data : p,
            ),
          );
          setProductsToDisplay((prevState) =>
            prevState.map((p) =>
              p.id === response.data.id ? response.data : p,
            ),
          );
        } else if (status === 'add') {
          const tempArray = [...products, response.data].sort((a, b) =>
            a.name > b.name ? 1 : -1,
          );
          setProducts(tempArray);
          setProductsToDisplay(tempArray);
        }
      } catch {
        getProducts();
      }
    },
    [getProducts, products],
  );

  const handleProductForm = (product: Product | null, icon: string | null) => {
    setModalProductForm(true);
    setProductData(product);
    setProductIcon(icon);
  };

  const handleStock = (product: Product | null, icon: string | null) => {
    setProductData(product);
    setProductIcon(icon);
    setModalStock(true);
  };

  const handleStatus = (product: Product | null) => {
    setProductData(product);
    setModalStatus(true);
  };

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Produtos</h1>
              <button
                type="button"
                title="Adicionar Produto"
                onClick={() => handleProductForm(null, null)}
              >
                <img src={add} alt="plus" />
              </button>
            </Title>
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={handleSeach}
              />
              <div title="Pesquisar">
                <img src={search} alt="search" />
              </div>
            </Search>
          </Top>

          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <TableProducts>
              <table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="left" width="32%">
                      Produto
                    </Th>
                    <Th talign="left" width="13%">
                      Categoria
                    </Th>
                    <Th talign="center" width="10%">
                      Stock
                      <p>
                        <small>(unidade)</small>
                      </p>
                    </Th>
                    <Th talign="center" width="10%">
                      Stock Alerta
                      <p>
                        <small>(unidade)</small>
                      </p>
                    </Th>
                    <Th talign="center" width="10%">
                      Valor
                      <p>
                        <small>(Soumas)</small>
                      </p>
                    </Th>
                    <Th talign="center" width="10%" />
                  </tr>
                </thead>
                <tbody>
                  {productsToDisplay.length > 0 &&
                    productsToDisplay.map((product, index) => (
                      <ProductLine
                        key={product.id}
                        product={product}
                        index={index + 1}
                        handleEdit={handleProductForm}
                        handleStock={handleStock}
                        handleStatus={handleStatus}
                      />
                    ))}
                </tbody>
              </table>
            </TableProducts>
          )}
        </Widget>

        {modalProductForm && (
          <Modal visible={modalProductForm}>
            <ProductForm
              closeModal={() => setModalProductForm(false)}
              handleUpdateProduct={handleUpdateProduct}
              product={productData}
              iconUrl={productIcon}
              visible={modalProductForm}
            />
          </Modal>
        )}

        {modalStock && (
          <Modal visible={modalStock}>
            <ProductStock
              close={() => setModalStock(false)}
              handleUpdateProduct={handleUpdateProduct}
              iconUrl={productIcon}
              id={productData ? productData.id : ''}
              productName={productData ? productData.name : ''}
            />
          </Modal>
        )}

        {modalStatus && (
          <Modal visible={modalStatus}>
            <ProductStatus
              close={() => setModalStatus(false)}
              handleUpdateProduct={handleUpdateProduct}
              availableOnMarket={
                productData ? productData.availableOnMarket : false
              }
              id={productData ? productData.id : ''}
              productName={productData ? productData.name : ''}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default Products;
