/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { Container } from './styles';

import edit from '../../../assets/edit.svg';
import save from '../../../assets/save_dark.svg';

import { MarketSession } from '../../../types/Household';
import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

type SessionTypeProps = {
  setMarketSession: React.Dispatch<React.SetStateAction<MarketSession>>;
  sessionType: '1' | '2' | '3' | '4';
  id_market_session: string;
};

const SessionType: React.FC<SessionTypeProps> = ({
  setMarketSession,
  sessionType,
  id_market_session,
}) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState<'1' | '2' | '3' | '4'>(sessionType);

  const { addToast } = useToast();

  const handleSessionType = async () => {
    try {
      const response = await api.put('market/session/session-type', {
        id_market_session,
        value,
      });

      setMarketSession((PrevState) => ({
        ...PrevState,
        sessionType: response.data.sessionType,
      }));

      setEditable(false);
      addToast({
        type: 'success',
        title: 'Alteração!',
        description: { code: 703 },
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Error!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  if (editable) {
    return (
      <Container>
        <select
          name="type"
          onChange={(
            e: React.ChangeEvent<
              HTMLSelectElement & { value: '1' | '2' | '3' | '4' }
            >,
          ) => setValue(e.currentTarget.value)}
          defaultValue={value}
        >
          <option value="1">Letras</option>
          <option value="2">Números</option>
          <option value="3">Porta Mensal</option>
          <option value="4">Letras e Números</option>
        </select>
        <button
          type="button"
          title="Guardar Alteração"
          onClick={handleSessionType}
        >
          <img src={save} alt="caneta" />
        </button>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <span>
          {value === '1' ? 'Letras' : ''} {value === '2' ? 'Números' : ''}{' '}
          {value === '3' ? 'Porta Mensal' : ''}{' '}
          {value === '4' ? 'Letras e Números' : ''}
        </span>
        <button
          type="button"
          title="Alterar Grupo"
          onClick={() => setEditable(true)}
        >
          <img src={edit} alt="caneta" />
        </button>
      </Container>
    </>
  );
};

export default SessionType;
