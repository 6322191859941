import styled from 'styled-components';

type CardProps = {
  active: boolean;
};

export const Card = styled.div<CardProps>`
  position: relative;
  width: 22.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 7px 7px 5px rgba(60, 240, 222, 0.75);
  -moz-box-shadow: 0px 7px 7px 5px rgba(60, 240, 222, 0.75);
  -webkit-box-shadow: 0px 7px 7px 5px rgba(60, 240, 222, 0.75);
  ${(props) => !props.active && 'background-color: #ffdfdf;'}

  > button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0.5rem;
    margin-top: 1rem;
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }

  > img {
    width: 22.4rem;
    height: 28rem;
  }

  div {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      width: 90%;
      text-align: center;
      font-size: 1.8rem;
    }

    > img {
      position: absolute;
      width: 3rem;
      margin-left: 0.5rem;
      left: 0;
    }
  }
`;

export const ViewButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;

  > img {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
