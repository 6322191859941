/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useState } from 'react';

import { Container, Modal, Close } from './styles';
import { MarketSession } from '../../../types/Household';
import CreditingValue from './CreditingValue';
import CreditingProduct from './CreditingProduct/indes';

type CreditingProps = {
  close(): void;
  household_id: string;
  setMarketSession: React.Dispatch<React.SetStateAction<MarketSession>>;
};

const Crediting: React.FC<CreditingProps> = ({
  close,
  household_id,
  setMarketSession,
}) => {
  const [selectedTypeC, setSelectedTypeC] = useState('valor');

  const updateMartekSession = useCallback(
    (data: MarketSession) => {
      setMarketSession(data);
      close();
    },
    [setMarketSession, close],
  );

  return (
    <Container>
      <Modal>
        <Close title="Fechar" onClick={close}>
          <p>&#x2715;</p>
        </Close>
        <h1>Creditação</h1>
        <div>
          <div>
            <button
              type="button"
              style={{ opacity: selectedTypeC === 'valor' ? 1 : 0.6 }}
              onClick={() => setSelectedTypeC('valor')}
            >
              Valor
            </button>
            <button
              type="button"
              style={{ opacity: selectedTypeC === 'produto' ? 1 : 0.6 }}
              onClick={() => setSelectedTypeC('produto')}
            >
              Produto
            </button>
          </div>

          {selectedTypeC === 'valor' && (
            <CreditingValue
              household_id={household_id}
              updateMartekSession={updateMartekSession}
            />
          )}
          {selectedTypeC === 'produto' && (
            <CreditingProduct
              household_id={household_id}
              updateMartekSession={updateMartekSession}
            />
          )}
        </div>
      </Modal>
    </Container>
  );
};

export default Crediting;
