/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Modal, ButtonBox, Button, Title } from './styles';

type ModalProps = {
  close(): void;
  isLoading: boolean;
  handleOnSubmit: (data: { note: string }) => Promise<void>;
  title: string;
};

const schema = yup.object().shape({
  note: yup.string().required('* a razão é obrigatória'),
});

const OrderModalNotDelivered: React.FC<ModalProps> = ({
  close,
  isLoading,
  handleOnSubmit,
  title,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ note: string }>({
    resolver: yupResolver(schema),
  });

  return (
    <Modal>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <textarea
          rows={5}
          {...register('note')}
          placeholder="Escreva uma razão"
        />
        <p>{errors.note?.message}</p>
        <ButtonBox>
          <Button
            type="button"
            color="#3cf0de"
            bgcolor="#ff9999"
            bghover="#ff6666"
            onClick={close}
            disabled={isLoading}
          >
            Não
          </Button>
          <Button
            type="submit"
            color="#3cf0de"
            bgcolor="#007970"
            bghover="#00665f"
            disabled={isLoading}
          >
            Sim
          </Button>
        </ButtonBox>
      </form>
    </Modal>
  );
};

export default OrderModalNotDelivered;
