import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  height: 30px;

  input {
    width: 50%;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #007970;
    text-align: center;
    font-size: 1.6rem;
    color: #007970;
  }

  span {
    font-size: 1.6rem;
  }

  button {
    margin-left: 5px;
    background-color: #3cf0de;
    border: 0px;
    opacity: 0.6;
    transition: 0.2s opacity;

    &:hover {
      opacity: 1;
    }

    img {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
`;
