import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useCallback, useEffect, useState } from 'react';
import File from '../../components/PdfFiles/PdfHousehold';
import { Household } from '../../types/Household';
import api from '../../config/api';

const styles = StyleSheet.create({
  view: {
    width: '100vw',
    height: '100vh',
  },
});

const PDFHousehold: React.FC = () => {
  const [households, setHouseholds] = useState<Household[] | null>(null);

  const getHouseholdOptions = useCallback(async () => {
    const response = await api.get('household');

    setHouseholds(response.data);
  }, []);

  useEffect(() => {
    getHouseholdOptions();
  }, [getHouseholdOptions]);

  return (
    <PDFViewer style={styles.view}>
      <>{households && <File household={households} />}</>
    </PDFViewer>
  );
};

export default PDFHousehold;
