import styled from 'styled-components';

type FieldProps = {
  width: string;
  align?: string;
};

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Widget = styled.div`
  margin-top: 6rem;
  width: 140rem;
  max-width: 140rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const UserForm = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Photo = styled.div`
  position: relative;

  > img {
    width: 224px;
    height: 280px;
    max-width: 224px;
    max-height: 280px;
    min-width: 224px;
    min-height: 280px;
  }
`;

export const EditButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  background: #3cf0de;
  width: 5rem;
  height: 5rem;
  margin-right: 5px;
  margin-bottom: 8px;
  border-radius: 50%;

  > img {
    width: 3.5rem;
    height: 3.5rem;
  }

  &:hover {
    background: #10d5c2;
  }
`;

export const Form = styled.div`
  position: relative;
  flex: 1;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & + & {
    margin-top: 3rem;
  }
`;

export const Field = styled.div<FieldProps>`
  width: ${(prosp) => prosp.width};
  position: relative;
  border-radius: 2px;
  background: transparent;
  border: 2px solid #007970;
  min-height: 4.5rem;

  h1 {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: #007970;
    top: -1.3rem;
    left: 0.2rem;
    background: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  p {
    width: 100%;
    color: #007970;
    font-size: 1.8rem;
    padding: 0.9rem;
    text-align: ${(props) => (props.align ? props.align : 'left')};
  }
`;

export const ButtonPrivate = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -2.5rem;
  width: 224px;
  font-size: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #007970;
  color: #3cf0de;
  border: none;

  &:hover {
    background: #00665f;
  }
`;

export const ActiveLabel = styled.div`
  width: 99%;
  height: 30px;
  background: #ff5050;
  position: absolute;
  bottom: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
`;

export const Supports = styled.button`
  width: 224px;
  font-size: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #007970;
  color: #3cf0de;
  border: none;
  margin-top: 30px;

  div {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }

  &:hover {
    background: #00665f;
  }
`;
