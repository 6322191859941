import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import File from '../../components/PdfFiles/PdfRoutes';
import { Routes } from '../../types/Routes';

const styles = StyleSheet.create({
  view: {
    width: '100vw',
    height: '100vh',
  },
});

type PDFRoutesProps = {
  routes: Routes[];
  week?: number;
  date?: string;
};

const PDFRoutes: React.FC = () => {
  const location = useLocation<PDFRoutesProps>();

  return (
    <PDFViewer style={styles.view}>
      <File
        routes={location.state.routes}
        week={location.state.week || null}
        date={location.state.date || null}
      />
    </PDFViewer>
  );
};

export default PDFRoutes;
