/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useState } from 'react';

import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Rectangle,
  Legend,
  TooltipProps,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

import {
  endOfMonth,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import legalsupport from '../../assets/legalsupportDark.svg';
import jobsupport from '../../assets/jobsupportDark.svg';
import assetssupport from '../../assets/assetssupportDark.svg';
import healthsupport from '../../assets/healthsupportDark.svg';
import household from '../../assets/householdDark.svg';
import socialsupport from '../../assets/socialsupportDark.svg';
import directsupport from '../../assets/directsupportDark.svg';

import Menu from '../../components/Menu';

import {
  Card,
  Cards,
  Chart,
  Container,
  Dates,
  ErrorDate,
  ImageFront,
  TooltipFinal,
  Widget,
  Total,
} from './styles';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

const CountSupport: React.FC = () => {
  const [data, setData] = useState<
    {
      type: number;
      label: string;
      valor: number;
    }[]
  >([]);
  const [total, setTotal] = useState(0);

  const [dates, setDates] = useState({
    ds: startOfMonth(new Date()),
    de: endOfMonth(new Date()),
  });

  const [dateError, setDateError] = useState('');

  const { addToast } = useToast();

  const getData = async (ds: string, de: string) => {
    try {
      const response = await api.get(`support-values/get?ds=${ds}&de=${de}`);

      setData(response.data.data);
      setTotal(response.data.total);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  const renderLegendText = () => {
    return <span>Valor (€)</span>;
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <TooltipFinal>
          <p>{`${label}: ${Number(payload[0].value).toFixed(2)}€`}</p>
        </TooltipFinal>
      );
    }

    return null;
  };

  const getImage = useCallback((type: number) => {
    switch (type) {
      case 1:
        return legalsupport;
      case 2:
        return jobsupport;
      case 3:
        return assetssupport;
      case 4:
        return healthsupport;
      case 5:
        return household;
      case 6:
        return socialsupport;
      case 7:
        return directsupport;
      default:
        return legalsupport;
    }
  }, []);

  const handleDateChange = (type: 'ds' | 'de', d: string) => {
    if (type === 'ds') {
      const date = startOfDay(parseISO(d));

      if (isAfter(date, dates.de)) {
        setDateError('Data de inicio não pode ser maior de a data de fim');
        return;
      }

      setDates((prevState) => ({ ...prevState, ds: date }));
    } else if (type === 'de') {
      const date = startOfDay(parseISO(d));

      if (isBefore(date, dates.ds)) {
        setDateError('Data de fim não pode ser menor de a data de inicio');
        return;
      }

      setDates((prevState) => ({ ...prevState, de: date }));
    }

    setDateError('');
  };

  useEffect(() => {
    getData(format(dates.ds, 'yyyy-MM-dd'), format(dates.de, 'yyyy-MM-dd'));
  }, [dates]);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Dates>
            <div>
              <span> Inicio:</span>
              <input
                defaultValue={format(dates.ds, 'yyyy-MM-dd')}
                type="date"
                onChange={(e) =>
                  e.currentTarget.value &&
                  handleDateChange('ds', e.currentTarget.value)
                }
              />
            </div>
            <div>
              <span> Fim:</span>
              <input
                defaultValue={format(dates.de, 'yyyy-MM-dd')}
                type="date"
                onChange={(e) =>
                  e.currentTarget.value &&
                  handleDateChange('de', e.currentTarget.value)
                }
              />
            </div>
          </Dates>
          {dateError && <ErrorDate>{dateError}</ErrorDate>}
          <Chart>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={1000}
                height={300}
                data={data}
                margin={{ right: 50 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  tick={{ stroke: '#e5e7e9', strokeWidth: 1 }}
                  dataKey="label"
                />
                <YAxis tick={{ stroke: '#e5e7e9', strokeWidth: 1 }} />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="valor"
                  fill="#007970"
                  activeBar={<Rectangle fill="#007970" stroke="#007970" />}
                />
                <Legend iconType="circle" formatter={renderLegendText} />
              </BarChart>
            </ResponsiveContainer>
          </Chart>
          <Total>
            <p>Total de utentes apoiados</p>
            <p>{total}</p>
          </Total>
          <Cards>
            {data.map((d) => (
              <Card>
                <h3>{d.label}</h3>
                <h2>{d.valor.toFixed(2)}€</h2>
                <ImageFront src={getImage(d.type)} alt="SupportImage" />
              </Card>
            ))}
          </Cards>
        </Widget>
      </Container>
    </>
  );
};

export default CountSupport;
