import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: '10px',
  },
  route_title: {
    width: '100%',
    padding: '5px',
    backgroundColor: '#ccc',
    borderColor: '#ccc',
    borderWidth: '1px',
  },
  route_title_text: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  route_table_th: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ccc',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderColor: '#ccc',
    borderWidth: '1px',
    minHeight: '30px',
    hight: '30px',
    maxHeight: '30px',
  },
  route_table_th_text: {
    fontSize: '9px',
    fontWeight: 'bold',
  },

  route_table_td: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderColor: '#ddd',
    borderBottomWidth: '1px',
    backgroundColor: '#fff',
    minHeight: '30px',
  },
  route_table_td_text: {
    fontSize: '8px',
  },

  header: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    height: '30px',
    width: '90px',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  date_week: {
    position: 'absolute',
    height: '30px',
    width: '120px',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  text_week_date: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});
