/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useCallback } from 'react';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import loading from '../../assets/loading01.svg';
import accounts from '../../assets/accounts.svg';

import {
  Container,
  Widget,
  Loading,
  OrderButton,
  MarketStatusCard,
  MarketStatusCardFlex,
  MarketStatusTitle,
  MarketStatusCircle,
  ModalBox,
  Title,
  ButtonBox,
  Button,
  DistributionText,
  DistributionInput,
  DistributionInputText,
  ViewMembersGroupButton,
  OrderButtons,
  OrderButtonsTittle,
} from './styles';

import Modal from '../../components/Modal';
import ResetAllCoins from '../../components/ResetAllCoins';
import GroupMembersModal from '../../components/GroupMembersModal';
import ButtonAcceptAll from '../../components/Order/ButtonAcceptAll';
import ButtonDeliveredAll from '../../components/Order/ButtonDeliveredAll';

type MarketStatusType = {
  status1: boolean;
  status2: boolean;
  status3: boolean;
  status4: boolean;
  distributionDate1: string;
  distributionDate2: string;
  distributionDate3: string;
  distributionDate4: string;
};

const MarketStatus: React.FC = () => {
  const [marketStatus, setMarketStatus] = useState<MarketStatusType>({
    status1: false,
    status2: false,
    status3: false,
    status4: false,
    distributionDate1: '',
    distributionDate2: '',
    distributionDate3: '',
    distributionDate4: '',
  });
  const [marketStatusSelected, setMarketStatusSelected] =
    useState<'1' | '2' | '3' | '4'>('1');
  const [marketDistributionDate, setMarketDistributionDate] = useState('');
  const [open, setOpen] = useState(false);
  const [distributionDateError, setDistributionDateError] = useState(false);
  const [viewModalMembersGroup, setViewModalMembersGroup] = useState(false);
  const [viewModalResetCoinsGroup, setViewModalResetCoinsGroup] =
    useState(false);
  const [selectedGroupResetCoins, setSelectedGroupResetCoins] =
    useState<'1' | '2' | '3' | '4' | '100'>('100');

  const [modalSelectedGroup, setModalSelectedGroup] =
    useState<'1' | '2' | '3' | '4'>('1');

  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderModalVisible, setOrderModalVisible] = useState(false);

  const { addToast } = useToast();

  const getOrders = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await api.get('/market/status');

      setMarketStatus({
        status1: response.data.status1,
        status2: response.data.status2,
        status3: response.data.status3,
        status4: response.data.status4,
        distributionDate1: response.data.distributionDate1,
        distributionDate2: response.data.distributionDate2,
        distributionDate3: response.data.distributionDate3,
        distributionDate4: response.data.distributionDate4,
      });
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter estado do mercado',
        description: { code: 781 },
      });
    }
  }, [addToast]);

  const handleConfirm = async () => {
    setIsLoading(true);

    if (!marketDistributionDate) {
      setDistributionDateError(true);
      setIsLoading(false);
      return;
    }

    setOrderModalVisible(false);
    try {
      const response = await api.put('/market/status', {
        type: marketStatusSelected,
        date: marketDistributionDate,
      });

      setMarketStatus({
        status1: response.data.status1,
        status2: response.data.status2,
        status3: response.data.status3,
        status4: response.data.status4,
        distributionDate1: response.data.distributionDate1,
        distributionDate2: response.data.distributionDate2,
        distributionDate3: response.data.distributionDate3,
        distributionDate4: response.data.distributionDate4,
      });
      setIsLoading(false);
      setDistributionDateError(false);
      addToast({
        type: 'success',
        title: 'Estado da merceario atualizado',
        description: { code: 783 },
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter estado do mercado',
        description: { code: 782 },
      });
      setDistributionDateError(false);
    }
  };

  const handleSelectMarketStatus = (type: '1' | '2' | '3' | '4') => {
    setMarketStatusSelected(type);

    if (type === '1') {
      setMarketDistributionDate(
        format(parseISO(marketStatus.distributionDate1), `yyyy'-'MM'-'dd`, {
          locale: pt,
        }),
      );
      setModalTitle(
        marketStatus.status1
          ? 'Fechar Mercearia para Grupo Letras?'
          : 'Abrir Mercearia para Grupo Letras?',
      );
      setOpen(marketStatus.status1);
    } else if (type === '2') {
      setMarketDistributionDate(
        format(parseISO(marketStatus.distributionDate2), `yyyy'-'MM'-'dd`, {
          locale: pt,
        }),
      );

      setModalTitle(
        marketStatus.status2
          ? 'Fechar Mercearia para Grupo Números?'
          : 'Abrir Mercearia para Grupo Números?',
      );
      setOpen(marketStatus.status2);
    } else if (type === '3') {
      setMarketDistributionDate(
        format(parseISO(marketStatus.distributionDate3), `yyyy'-'MM'-'dd`, {
          locale: pt,
        }),
      );

      setModalTitle(
        marketStatus.status3
          ? 'Fechar Mercearia para Grupo Portal Mensal?'
          : 'Abrir Mercearia para Grupo  Portal Mensal?',
      );
      setOpen(marketStatus.status3);
    }

    setOrderModalVisible(true);
  };

  const handleSelectViewMembersGroup = (type: '1' | '2' | '3' | '4') => {
    setModalSelectedGroup(type);
    setViewModalMembersGroup(true);
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <MarketStatusCard>
              <h1>Gestão Mercearia</h1>

              <div>
                <MarketStatusCardFlex>
                  <MarketStatusTitle>Grupo Letras</MarketStatusTitle>
                  <MarketStatusCircle
                    color={marketStatus.status1 ? '#33cc33' : '#ff8080'}
                  >
                    <h4>Estado da Mercearia: </h4>
                    <p>{marketStatus.status1 ? 'Aberta' : 'Fechada'}</p>
                  </MarketStatusCircle>
                  <DistributionText>
                    <h3>Dia da distribuição:</h3>
                    <span>
                      {marketStatus.distributionDate1 &&
                        format(
                          parseISO(marketStatus.distributionDate1),
                          `dd 'de' MMMM 'de' yyyy '`,
                          { locale: pt },
                        )}
                    </span>
                  </DistributionText>
                  <OrderButton onClick={() => handleSelectMarketStatus('1')}>
                    {marketStatus.status1
                      ? 'Fechar Mercearia'
                      : 'Abrir Mercearia'}
                  </OrderButton>
                  <OrderButton
                    onClick={() => {
                      setSelectedGroupResetCoins('1');
                      setViewModalResetCoinsGroup(true);
                    }}
                  >
                    Renovar Soumas
                  </OrderButton>
                  <ViewMembersGroupButton
                    onClick={() => handleSelectViewMembersGroup('1')}
                  >
                    <img src={accounts} alt="membros" />
                    Ver membros
                  </ViewMembersGroupButton>
                  <OrderButtonsTittle>
                    <p>Operações de encomenda em massa</p>
                  </OrderButtonsTittle>
                  <OrderButtons>
                    <ButtonAcceptAll sessionType={1} />
                    <ButtonDeliveredAll sessionType={1} />
                  </OrderButtons>
                </MarketStatusCardFlex>

                <MarketStatusCardFlex>
                  <MarketStatusTitle>Grupo Números</MarketStatusTitle>
                  <MarketStatusCircle
                    color={marketStatus.status2 ? '#33cc33' : '#ff8080'}
                  >
                    <h4>Estado da Mercearia: </h4>
                    <p>{marketStatus.status2 ? 'Aberta' : 'Fechada'}</p>
                  </MarketStatusCircle>
                  <DistributionText>
                    <h3>Dia da distribuição:</h3>
                    <span>
                      {marketStatus.distributionDate2 &&
                        format(
                          parseISO(marketStatus.distributionDate2),
                          `dd 'de' MMMM 'de' yyyy '`,
                          { locale: pt },
                        )}
                    </span>
                  </DistributionText>
                  <OrderButton onClick={() => handleSelectMarketStatus('2')}>
                    {marketStatus.status2
                      ? 'Fechar Mercearia'
                      : 'Abrir Mercearia'}
                  </OrderButton>
                  <OrderButton
                    onClick={() => {
                      setSelectedGroupResetCoins('2');
                      setViewModalResetCoinsGroup(true);
                    }}
                  >
                    Renovar Soumas
                  </OrderButton>
                  <ViewMembersGroupButton
                    onClick={() => handleSelectViewMembersGroup('2')}
                  >
                    <img src={accounts} alt="membros" />
                    Ver membros
                  </ViewMembersGroupButton>
                  <OrderButtonsTittle>
                    <p>Operações de encomenda em massa</p>
                  </OrderButtonsTittle>
                  <OrderButtons>
                    <ButtonAcceptAll sessionType={2} />
                    <ButtonDeliveredAll sessionType={2} />
                  </OrderButtons>
                </MarketStatusCardFlex>

                <MarketStatusCardFlex>
                  <MarketStatusTitle>Grupo Porta Mensal</MarketStatusTitle>
                  <MarketStatusCircle
                    color={marketStatus.status3 ? '#33cc33' : '#ff8080'}
                  >
                    <h4>Estado da Mercearia: </h4>
                    <p>{marketStatus.status3 ? 'Aberta' : 'Fechada'}</p>
                  </MarketStatusCircle>
                  <DistributionText>
                    <h3>Dia da distribuição:</h3>
                    <span>
                      {marketStatus.distributionDate3 &&
                        format(
                          parseISO(marketStatus.distributionDate3),
                          `dd 'de' MMMM 'de' yyyy '`,
                          { locale: pt },
                        )}
                    </span>
                  </DistributionText>
                  <OrderButton onClick={() => handleSelectMarketStatus('3')}>
                    {marketStatus.status3
                      ? 'Fechar Mercearia'
                      : 'Abrir Mercearia'}
                  </OrderButton>
                  <OrderButton
                    onClick={() => {
                      setSelectedGroupResetCoins('3');
                      setViewModalResetCoinsGroup(true);
                    }}
                  >
                    Renovar Soumas
                  </OrderButton>
                  <ViewMembersGroupButton
                    onClick={() => handleSelectViewMembersGroup('3')}
                  >
                    <img src={accounts} alt="membros" />
                    Ver membros
                  </ViewMembersGroupButton>
                  <OrderButtonsTittle>
                    <p>Operações de encomenda em massa</p>
                  </OrderButtonsTittle>
                  <OrderButtons>
                    <ButtonAcceptAll sessionType={3} />
                    <ButtonDeliveredAll sessionType={3} />
                  </OrderButtons>
                </MarketStatusCardFlex>
              </div>
              {viewModalResetCoinsGroup && (
                <ResetAllCoins
                  group={selectedGroupResetCoins}
                  onClose={() => {
                    setViewModalResetCoinsGroup(false);
                    setSelectedGroupResetCoins('100');
                  }}
                  isOpen={viewModalResetCoinsGroup}
                />
              )}
            </MarketStatusCard>
          )}
        </Widget>
        {orderModalVisible && (
          <Modal visible={orderModalVisible}>
            <ModalBox>
              <Title>{modalTitle}</Title>

              {!open && (
                <DistributionInputText>
                  <span>Dia da distribuição</span>
                  <DistributionInput
                    type="date"
                    defaultValue={marketDistributionDate}
                    onChange={(e) =>
                      setMarketDistributionDate(e.currentTarget.value)
                    }
                    min={format(new Date(), `yyyy'-'MM'-'dd`)}
                  />
                  {distributionDateError && (
                    <p>* é obrigatório definir o dia da distribuição</p>
                  )}
                </DistributionInputText>
              )}
              <ButtonBox>
                <Button
                  type="button"
                  color="#3cf0de"
                  bgcolor="#ff9999"
                  bghover="#ff6666"
                  onClick={() => setOrderModalVisible(false)}
                  disabled={isLoading}
                >
                  Não
                </Button>
                <Button
                  type="button"
                  color="#3cf0de"
                  bgcolor="#007970"
                  bghover="#00665f"
                  onClick={handleConfirm}
                  disabled={isLoading}
                >
                  Sim
                </Button>
              </ButtonBox>
            </ModalBox>
          </Modal>
        )}
        {viewModalMembersGroup && (
          <GroupMembersModal
            group={modalSelectedGroup}
            closeAction={() => setViewModalMembersGroup(false)}
          />
        )}
      </Container>
    </>
  );
};

export default MarketStatus;
