/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import api from '../../../../config/api';
import { useToast } from '../../../../hooks/ToastContext';

import { Container, Modal, ButtonBox, Button, Title } from './styles';

import { Routes, Turn } from '../../../../types/Routes';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
  idRoute: string;
  turn: Turn;
  refreshData: () => Promise<void>;
  setRoutes: React.Dispatch<React.SetStateAction<Routes[]>>;
};

const DeleteTurnModal: React.FC<ModalProps> = ({
  visible,
  closeModal,
  idRoute,
  turn,
  refreshData,
  setRoutes,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const handleOnSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await api.delete(`turns/${turn.id}`);

      addToast({
        type: 'success',
        title: 'Volta removida',
        description: { code: 806 },
      });

      if (response.data) {
        setRoutes((prevState) =>
          prevState.map((route) =>
            route.id === idRoute
              ? {
                  ...route,
                  turns: route.turns.filter((t) => t.id !== turn.id),
                }
              : route,
          ),
        );

        refreshData();
        setIsLoading(false);
        closeModal();
      }
    } catch (e: any) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na edição da volta',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  return (
    <Container visible={visible}>
      <Modal>
        <Title>
          {' '}
          Quer remover o agregado {turn.household?.name} desta rota?
        </Title>
        <ButtonBox>
          <Button
            type="button"
            color="#3cf0de"
            bgcolor={isLoading ? '#ff6666' : '#ff9999'}
            bghover="#ff6666"
            onClick={closeModal}
            disabled={isLoading}
          >
            Não
          </Button>
          {isLoading ? (
            <Button
              type="button"
              color="#3cf0de"
              bgcolor={isLoading ? '#00665f' : '#007970'}
              bghover="#00665f"
              disabled={isLoading}
            >
              A remover...
            </Button>
          ) : (
            <Button
              type="button"
              color="#3cf0de"
              bgcolor="#007970"
              bghover="#00665f"
              onClick={handleOnSubmit}
            >
              Sim
            </Button>
          )}
        </ButtonBox>
      </Modal>
    </Container>
  );
};

export default DeleteTurnModal;
