/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';
import { Routes, Turn } from '../../../types/Routes';
import AddTurnModal from './AddTurnModal';

import add from '../../../assets/add.svg';
import edit from '../../../assets/edit.svg';
import del from '../../../assets/delete.svg';
import pt from '../../../assets/pt.svg';
import uk from '../../../assets/uk.svg';
import letras from '../../../assets/letras.svg';
import numeros from '../../../assets/numeros.svg';

import {
  Container,
  Modal,
  Top,
  TopTitle,
  TopInfo,
  TopInfoBox,
  TopInfoBoxTitle,
  TopInfoBoxText,
  TableTurns,
  TableTurnsTitle,
  TableTurnsBody,
  TableBodyTh,
  TableBodyTd,
  ImgGroup,
  Close,
} from './styles';
import EditTurnModal from './EditTurnModal';
import DeleteTurnModal from './DeleteTurnModal';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
  idRoute: string;
  setRoutes: React.Dispatch<React.SetStateAction<Routes[]>>;
};

const ViewRouteModal: React.FC<ModalProps> = ({
  visible,
  closeModal,
  idRoute,
  setRoutes,
}) => {
  const [routeData, setRouteData] = useState({} as Routes);
  const [addTurnModalVisible, setAddTurnModalVisible] = useState(false);
  const [editTurnModalVisible, setEditTurnModalVisible] = useState(false);
  const [deleteTurnModalVisible, setDeleteTurnModalVisible] = useState(false);

  const [turnDataToEdit, setTurnDataToEdit] = useState({} as Turn);

  const { addToast } = useToast();

  const getRouteData = useCallback(async () => {
    try {
      const response = await api.get(`routes/${idRoute}`);

      if (response.data) {
        const daysToDisplay = response.data.daysWeek
          .split(',')
          .map((day: string) => {
            switch (day) {
              case '0':
                return 'Domingo';
              case '1':
                return 'Segunda';
              case '2':
                return 'Terça';
              case '3':
                return 'Quarta';
              case '4':
                return 'Quinta';
              case '5':
                return 'Sexta';
              case '6':
                return 'Sábado';
              default:
                return 'Nenhum';
            }
          })
          .join(', ');

        setRouteData({ ...response.data, daysWeek: daysToDisplay });
      }
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter rota',
        description: { code: 804 },
      });
    }
  }, [addToast, idRoute]);

  useEffect(() => {
    getRouteData();
  }, [getRouteData, idRoute]);

  return (
    <Container visible={visible}>
      <Modal>
        <Close>
          <button type="button" onClick={closeModal}>
            &#x2715;
          </button>
        </Close>
        <Top>
          <TopTitle>
            Rota: {routeData.routeNum}
            {routeData.routeType === 'pt' ? (
              <img src={pt} alt="Bandeira de Portugal" />
            ) : (
              <img src={uk} alt="Bandeira da Ucrania" />
            )}
          </TopTitle>
          <TopInfo>
            <TopInfoBox>
              <TopInfoBoxTitle>Responsável</TopInfoBoxTitle>
              <TopInfoBoxText>{routeData.respRoute}</TopInfoBoxText>
            </TopInfoBox>
            <TopInfoBox>
              <TopInfoBoxTitle>Dias da Semana</TopInfoBoxTitle>
              <TopInfoBoxText>{routeData.daysWeek}</TopInfoBoxText>
            </TopInfoBox>
            <TopInfoBox>
              <TopInfoBoxTitle>Estado</TopInfoBoxTitle>
              <TopInfoBoxText>
                {routeData.status ? 'Ativa' : 'Inativa'}
              </TopInfoBoxText>
            </TopInfoBox>
          </TopInfo>
        </Top>
        <TableTurns>
          <TableTurnsTitle>
            Voltas
            {routeData.status && (
              <button
                type="button"
                title="Adicionar volta"
                onClick={() => setAddTurnModalVisible(true)}
              >
                <img src={add} alt="plus" />
              </button>
            )}
          </TableTurnsTitle>
          <TableTurnsBody>
            <table>
              <thead>
                <tr>
                  <TableBodyTh talign="center" width="3%">
                    #
                  </TableBodyTh>
                  <TableBodyTh talign="left" width="14%">
                    Agregado
                  </TableBodyTh>
                  <TableBodyTh talign="left" width="20%">
                    Notas
                  </TableBodyTh>
                  <TableBodyTh talign="center" width="5%">
                    PAX
                  </TableBodyTh>
                  <TableBodyTh talign="center" width="5%">
                    Cozinha?
                  </TableBodyTh>
                  <TableBodyTh talign="center" width="8%">
                    Nº Refeições
                  </TableBodyTh>
                  <TableBodyTh talign="center" width="8%">
                    Contacto
                  </TableBodyTh>
                  <TableBodyTh talign="left" width="24%">
                    Morada
                  </TableBodyTh>
                  <TableBodyTh talign="center" width="8%">
                    Localidade
                  </TableBodyTh>
                  <TableBodyTh talign="center" width="10%">
                    Ações
                  </TableBodyTh>
                </tr>
              </thead>
              <tbody>
                {routeData.turns &&
                  routeData.turns
                    .sort(
                      (a, b) =>
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime(),
                    )
                    .map((turn, index) => (
                      <tr key={turn.id}>
                        <TableBodyTd talign="center" width="3%">
                          {index + 1}
                          <ImgGroup>
                            {turn.household &&
                            turn.household.market_session &&
                            turn.household.market_session.sessionType ===
                              '1' ? (
                              <img src={letras} alt="letras" />
                            ) : (
                              ''
                            )}
                            {turn.household &&
                            turn.household.market_session &&
                            turn.household.market_session.sessionType ===
                              '2' ? (
                              <img src={numeros} alt="números" />
                            ) : (
                              ''
                            )}
                            {turn.household &&
                            turn.household.market_session &&
                            turn.household.market_session.sessionType ===
                              '4' ? (
                              <>
                                <img src={letras} alt="letras" /> |
                                <img src={numeros} alt="números" />
                              </>
                            ) : (
                              ''
                            )}
                          </ImgGroup>
                        </TableBodyTd>
                        <TableBodyTd talign="left" width="14%">
                          {turn.household && turn.household.name}
                          {turn.onceMonth && ' - (1 vez mês)'}
                        </TableBodyTd>
                        <TableBodyTd talign="left" width="20%">
                          {turn.note}
                        </TableBodyTd>
                        <TableBodyTd talign="center" width="5%">
                          {turn.household &&
                            turn.household.household_members &&
                            turn.household?.household_members.length + 1}
                        </TableBodyTd>
                        <TableBodyTd talign="center" width="5%">
                          {turn.canCook ? 'Sim' : 'Não'}
                        </TableBodyTd>
                        <TableBodyTd talign="center" width="8%">
                          {turn.numMeal}
                        </TableBodyTd>
                        <TableBodyTd talign="center" width="8%">
                          {turn.household && turn.household.user.contact}
                        </TableBodyTd>
                        <TableBodyTd talign="left" width="24%">
                          {turn.household &&
                            `${turn.household.user.address} ${turn.household.user.zip}`}
                        </TableBodyTd>
                        <TableBodyTd talign="center" width="8%">
                          {turn.household && turn.household.user.location}
                        </TableBodyTd>
                        <TableBodyTd talign="center" width="10%">
                          {routeData.status && (
                            <div>
                              <button
                                type="button"
                                title="editar"
                                onClick={() => {
                                  setEditTurnModalVisible(true);
                                  setTurnDataToEdit(turn);
                                }}
                              >
                                <img src={edit} alt="edit" />
                              </button>
                              <button
                                type="button"
                                title="remover"
                                onClick={() => {
                                  setDeleteTurnModalVisible(true);
                                  setTurnDataToEdit(turn);
                                }}
                              >
                                <img src={del} alt="caixote do lixo" />
                              </button>
                            </div>
                          )}
                        </TableBodyTd>
                      </tr>
                    ))}
              </tbody>
            </table>
          </TableTurnsBody>
        </TableTurns>
      </Modal>
      {addTurnModalVisible && (
        <AddTurnModal
          visible={addTurnModalVisible}
          closeModal={() => setAddTurnModalVisible(false)}
          idRoute={idRoute}
          refreshData={() => getRouteData()}
          setRoutes={setRoutes}
        />
      )}

      {editTurnModalVisible && (
        <EditTurnModal
          visible={editTurnModalVisible}
          closeModal={() => setEditTurnModalVisible(false)}
          idRoute={idRoute}
          turn={turnDataToEdit}
          refreshData={() => getRouteData()}
          setRoutes={setRoutes}
        />
      )}

      {deleteTurnModalVisible && (
        <DeleteTurnModal
          visible={deleteTurnModalVisible}
          closeModal={() => setDeleteTurnModalVisible(false)}
          idRoute={idRoute}
          turn={turnDataToEdit}
          refreshData={() => getRouteData()}
          setRoutes={setRoutes}
        />
      )}
    </Container>
  );
};

export default ViewRouteModal;
