import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/AuthContext';

import {
  Container,
  MenuBox,
  List,
  MenuList,
  RightMenu,
  Logout,
} from './styles';

import icon from '../../assets/icon.svg';
import logout from '../../assets/logout.svg';
import account_icon from '../../assets/account.svg';
import accounts_icon from '../../assets/accounts.svg';
import logs_icon from '../../assets/log.svg';

const Menu: React.FC = () => {
  const { account, singOut } = useAuth();
  const history = useHistory();

  return (
    <Container>
      <MenuBox>
        <List>
          <Link to="/dashboard">
            <img src={icon} alt="icon da Souma" />
          </Link>
          <MenuList>
            <button type="button">Gestão</button>
            <div>
              <Link to="/utentes">Utentes</Link>
              <Link to="/agregados">Agregados</Link>
              <Link to="/rotas">Rotas</Link>
              <Link to="/count-support">Estatística de Apoios</Link>
            </div>
          </MenuList>
          <MenuList>
            <button type="button">Mercearia</button>
            <div>
              <Link to="/produtos">Produtos</Link>
              <Link to="/encomendas">Encomendas</Link>
              <Link to="/encomendas-tipo">Encomendas Tipo</Link>
              <Link to="/estado-mercearia">Gerir Mercearia</Link>
            </div>
          </MenuList>
          <MenuList>
            <button type="button">Ateliers</button>
            {/* <div>
              <Link to="/dashboard">Produtos</Link>
              <Link to="/dashboard">Encomendas</Link>
              <Link to="/dashboard">Apoios</Link>
            </div> */}
          </MenuList>
        </List>
        <RightMenu>
          {account?.admin && (
            <button
              className="button-accounts"
              type="button"
              title="Gerir Contas"
              onClick={() => history.push('/contas')}
            >
              <img
                className="img-accounts"
                src={accounts_icon}
                alt="icon de accounts"
              />
            </button>
          )}
          <button
            className="button-account"
            type="button"
            title="Gerir Conta"
            onClick={() => history.push('/conta')}
          >
            <img
              className="img-account"
              src={account_icon}
              alt="icon de account"
            />
          </button>
          {account?.admin && (
            <button
              className="button-log"
              type="button"
              title="Ver Logs da Aplicação"
              onClick={() => history.push('/logs')}
            >
              <img className="img-log" src={logs_icon} alt="icon de logs" />
            </button>
          )}
          <Logout type="button" onClick={singOut} title="Sair">
            <img src={logout} alt="icon de logout" />
          </Logout>
        </RightMenu>
      </MenuBox>
    </Container>
  );
};

export default Menu;
